import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import pollFragment from "../pollFragment";

const query = gql`
  query Polls(
    $pagination: PaginationArgumentDTO!
    $campaignId: ID
    $topicId: ID
  ) {
    polls(pagination: $pagination, campaignId: $campaignId, topicId: $topicId) {
      hasNext
      objects {
        ...PollFragment
      }
    }
  }
  ${pollFragment}
`;

export default function usePolls({
  campaignId,
  topicId,
}: {
  campaignId?: string;
  topicId?: string;
}) {
  const perPage = 25;

  const { data, error, loading, fetchMore, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "polls">,
    FrankBackendTypes.QueryPollsArgs
  >(query, {
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: {
        page: 0,
        perPage,
      },
      campaignId,
      topicId,
    },
  });

  const nextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page: Math.round(data?.polls.objects.length / perPage),
          perPage,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          ...fetchMoreResult,
          polls: {
            ...fetchMoreResult.polls,
            objects: [...prev.polls.objects, ...fetchMoreResult.polls.objects],
          },
        };
      },
    });
  }, [fetchMore, data]);

  return {
    loading,
    refetch,
    error,
    nextPage,
    polls: data?.polls,
  };
}
