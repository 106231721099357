import * as React from "react";
import { useForm } from "react-hook-form";
import { DateIntervals } from "../utils/Date";
import InviteLinkInput from "./components/InviteLinkInput";
import SecurityFeatureRow from "./components/SecurityFeatureRow";
import UpdateInviteSettings from "./UpdateInvite";
import {
  FormGroup,
  Input,
  Intent,
  ScreenSizes,
  Select,
  useBreakpoint,
  useToaster,
} from "@get-frank-eng/design-system";
import { useCreateInviteLink } from "./dataAccess/queries/mutations/useCreateInviteLink";
import copyText from "../utils/copyText";

export interface InviteForm {
  maxSeats: number;
  passphrase: string;
  expiration: string;
}

declare global {
  interface Navigator {
    // @ts-ignore
    share?: (data?: ShareData) => Promise<void>;
    canShare?: (data?: ShareData) => boolean;
  }
}

type ShareData = {
  title?: string;
  text?: string;
  url?: string;
  files?: File[];
};

export const linkGen = (id) =>
  `${process.env.REACT_APP_FRONTEND_URL}${process.env.REACT_APP_ONBOARDING_URL}?token=${id}`;

const CreateLinkInvite = ({
  campaignId,
  refetchStats,
  showOptions = true,
}: {
  campaignId?: string;
  refetchStats?: () => any;
  showOptions?: boolean;
}) => {
  const isMobile = useBreakpoint() <= ScreenSizes.SM;
  const { register, handleSubmit, errors } = useForm<InviteForm>();
  const toaster = useToaster();

  const { inviteData, loading, token, createInviteLink } = useCreateInviteLink({
    afterCreate: refetchStats,
    campaignId,
  });

  const supportsShare = !!navigator.share;

  return (
    <>
      {!inviteData && (
        <>
          <InviteLinkInput
            loading={loading}
            type={inviteData ? "button" : "submit"}
            onClickHandler={async () => {
              await copyText(linkGen(token));
              try {
                await handleSubmit(async (data) => createInviteLink(data))();
              } catch (e) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children: "There was an error saving the link",
                });
                return;
              }
              if (supportsShare && isMobile) {
                try {
                  await navigator.share({
                    title: "Join me on Frank",
                    url: linkGen(token),
                  });
                  toaster.addToast({
                    intent: Intent.SUCCESS,
                    children: "Success",
                  });
                  return;
                } catch (e) {
                  console.error("Error using native share", e);
                }
              }
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: "Copied to your clipboard",
              });
            }}
            url={linkGen(token)}
            showCopyButton
          />
          {showOptions && (
            <>
              <div className="mb-2 mt-6 block">
                <div className="mt-4">
                  <SecurityFeatureRow
                    id="limit-seats"
                    title="Limit Number of Uses"
                    contentToShowWhenChecked={() => (
                      <div className="w-64">
                        <Select
                          name="maxSeats"
                          defaultValue="10"
                          register={register}
                        >
                          <option value="1">1</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </Select>
                      </div>
                    )}
                  >
                    By default, invite links can only be used once. If you'd
                    like to share a link that can be used more than once, you
                    can select a number of uses here (up to 50).
                  </SecurityFeatureRow>

                  <SecurityFeatureRow
                    id="require-passphrase"
                    title="Require Passcode"
                    contentToShowWhenChecked={() => (
                      <div className="flex flex-row w-64">
                        <FormGroup
                          label="Pass Phrase"
                          name="passphrase"
                          id="passphrase"
                        >
                          <Input
                            errorText={errors.passphrase && `Required field`}
                            register={register}
                            registerArgs={{ required: true }}
                            autoFocus
                          />
                        </FormGroup>
                      </div>
                    )}
                  >
                    Add a secret passcode to this invite link. Share the
                    passcode with trusted coworkers via a secure channel or in
                    person. We recommend that you do not share the link and the
                    passcode in the same message or in the same channel.
                  </SecurityFeatureRow>

                  <SecurityFeatureRow
                    id="expiration"
                    title="Set Expiration"
                    contentToShowWhenChecked={() => (
                      <div className="w-64">
                        <Select
                          name="expiration"
                          defaultValue={DateIntervals.ONE_HOUR}
                          register={register}
                        >
                          <option value={DateIntervals.ONE_HOUR}>1 Hour</option>
                          <option value={DateIntervals.ONE_DAY}>
                            24 Hours
                          </option>
                          <option value={DateIntervals.THREE_DAYS}>
                            3 Days
                          </option>
                          <option value={DateIntervals.ONE_WEEK}>1 Week</option>
                        </Select>
                      </div>
                    )}
                  >
                    Set this invite link to expire after a specified amount of
                    time. You can choose 1 hour, 1 day, 3 days, or a week.
                  </SecurityFeatureRow>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {inviteData && (
        <>
          <InviteLinkInput
            url={linkGen(token)}
            loading={false}
            type="button"
            showCopyButton
            onClickHandler={async () => {
              await copyText(linkGen(token));
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: "Copied to your clipboard",
              });
            }}
          />
          {showOptions && <UpdateInviteSettings invite={inviteData} />}
        </>
      )}
    </>
  );
};

export default CreateLinkInvite;
