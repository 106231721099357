import * as React from "react";
import { useParams } from "react-router";
import uploadAvatar from "../Auth/UploadAvatar";
import { CardGrid } from "../Campaigns/CampaignCardWrapper";
import DetailLayout from "../components/layouts/Detail";
import Loading from "../components/Loading";
import useProfileDataQuery from "./dataAccess/queries/useProfileData";
import {
  ScreenSizes,
  ActivityFeed,
  useResponsive,
  TopBar,
  IconButton,
  useLayout,
} from "@get-frank-eng/design-system";
import ProfileSide from "./components/ProfileSide";
import Crossroads from "../components/Crossroads";
import { CampaignCard } from "@get-frank-eng/design-system";
import CampaignMenu from "../Campaigns/CampaignMenu";
import { FrankBackendTypes } from "frank-types";

const Profile = () => {
  const { userId } = useParams<{ userId: string }>();
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;
  const activityPerPage = 20;
  const campaignPerPage = 8;

  const {
    loading,
    error,
    data,
    refetch,
    loadNextPageActivity,
    loadNextSignedCampaignsPage,
  } = useProfileDataQuery({
    userId,
    activityPerPage,
    campaignPerPage,
  });

  const uploadAvatarCallback = React.useCallback(async () => {
    uploadAvatar(() => {
      refetch();
    });
  }, [refetch]);
  const { setMobilePanel } = useLayout();

  if (error) {
    throw new Error(error.message);
  }

  if (loading) {
    return <Loading />;
  }

  const activity = data?.profile?.activity;

  const showOrganizedCampaigns = !!data?.profile?.organizedCampaigns?.length;

  const supportedCampaignsToDisplay = data?.profile?.signedCampaigns.objects.filter(
    (c) =>
      c.relationshipToMe !==
      FrankBackendTypes.CampaignUserRelationship.Organizer
  );

  return (
    <>
      <div className="sm:hidden fixed z-50 top-0 w-screen">
        <TopBar
          classNames="bg-canvas-800"
          left={
            <IconButton
              onClick={() => setMobilePanel("left")}
              buttonStyle="minimal"
              icon="menu"
            />
          }
          title={data?.profile?.name}
        />
      </div>
      <DetailLayout
        title={`Frank - ${data?.profile?.name}`}
        direction="left"
        side={
          <div className="mt-12 sm:mt-0">
            <ProfileSide
              uploadAvatarCallback={uploadAvatarCallback}
              profile={data.profile}
            />
          </div>
        }
      >
        <div>
          {showOrganizedCampaigns && (
            <div className="mb-12 pb-12 border-b" data-cy="organized-campaigns">
              <div className="t-regular plus-plus mt-12 mb-8">
                Campaigns I Organize
              </div>
              <CardGrid size={isMobile ? "small" : "large"}>
                {data?.profile?.organizedCampaigns.map((organizedCampaign) => (
                  <CampaignCard
                    stepsToPublish={
                      organizedCampaign.publishingValidationIssues?.length
                    }
                    workflowState={organizedCampaign.workflowState}
                    key={organizedCampaign.id}
                    title={organizedCampaign.title}
                    id={organizedCampaign.id}
                    campaignOrganizers={organizedCampaign.campaignOrganizers}
                    signatureCount={organizedCampaign.signatureCount}
                    signatureGoal={organizedCampaign.signatureGoal}
                    relationshipToMe={organizedCampaign.relationshipToMe}
                    campaignMenu={
                      <CampaignMenu
                        campaignId={organizedCampaign.id}
                        workflowState={organizedCampaign.workflowState}
                        relationshipToMe={organizedCampaign.relationshipToMe}
                      />
                    }
                  />
                ))}
              </CardGrid>
            </div>
          )}
          {!!supportedCampaignsToDisplay.length && (
            <div className="mb-12 pb-12 border-b" data-cy="supported-campaigns">
              <div className="t-regular plus-plus mt-16 mb-8">
                Campaigns I Support
              </div>
              <CardGrid size={isMobile ? "small" : "large"}>
                {supportedCampaignsToDisplay.map((signedCampaign) => (
                  <CampaignCard
                    workflowState={signedCampaign.workflowState}
                    key={signedCampaign.id}
                    title={signedCampaign.title}
                    id={signedCampaign.id}
                    campaignOrganizers={signedCampaign.campaignOrganizers}
                    signatureCount={signedCampaign.signatureCount}
                    signatureGoal={signedCampaign.signatureGoal}
                    relationshipToMe={signedCampaign.relationshipToMe}
                    campaignMenu={
                      <CampaignMenu
                        campaignId={signedCampaign.id}
                        workflowState={signedCampaign.workflowState}
                        relationshipToMe={signedCampaign.relationshipToMe}
                      />
                    }
                  />
                ))}
                {data?.profile.signedCampaigns.hasNext && (
                  <Crossroads
                    onEntering={loadNextSignedCampaignsPage}
                    id="signed-campaigns"
                  />
                )}
              </CardGrid>
            </div>
          )}
          <h2 className="t-regular plus-plus mt-16">Recent Activity</h2>
          <ActivityFeed activities={activity.objects} />
          {activity.hasNext && (
            <Crossroads
              id="profile-activity"
              onEntering={loadNextPageActivity}
            />
          )}
        </div>
      </DetailLayout>
    </>
  );
};

export default Profile;
