import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { useModals } from "../../Modals";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const REMOVE_COLLABORATOR = gql`
  mutation RemoveCollaborator($input: RemoveCollaboratorDTO!) {
    removeCollaborator(input: $input) {
      nothing
    }
  }
`;

export default function useRemoveCollaborator({
  campaignId,
  onRemove,
}: {
  campaignId: string;
  onRemove: () => any;
}) {
  const { openConfirmationModal } = useModals();
  const toaster = useToaster();
  const [invokeRemoveCollaborator, { loading, error }] = useMutation<
    any,
    { input: FrankBackendTypes.RemoveCollaboratorDto }
  >(REMOVE_COLLABORATOR);

  const removeCollaborator = React.useCallback(
    async (coworkerId: FrankBackendTypes.Coworker["id"]) => {
      const confirmRemove = await openConfirmationModal({
        title: "Are you sure you want to remove this organizer?",
        bodyText:
          "If you remove them, they will no longer be able to collaborate on this campaign. You can re-invite them later if you change your mind.",
        actionText: "Confirm",
      });
      if (confirmRemove) {
        try {
          await invokeRemoveCollaborator({
            variables: {
              input: {
                campaignId,
                collaboratorCoworkerId: coworkerId,
              },
            },
          });

          toaster.addToast({
            intent: Intent.SUCCESS,
            children: "Organizer successfully removed",
          });

          onRemove();
        } catch (e) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: "Something went wrong removing the organizer",
          });
          throw e;
        }
      }
    },
    [
      openConfirmationModal,
      invokeRemoveCollaborator,
      campaignId,
      onRemove,
      toaster,
    ]
  );

  return {
    removeCollaborator,
    loadingRemoveCollaborator: loading,
    errorRemoveCollaborator: error,
  };
}
