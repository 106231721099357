"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkCard = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Card_1 = require("../components/Card");
const top = ({ source, author }) => {
    if (!source && !author) {
        return React.createElement(React.Fragment, null);
    }
    if (source && !author) {
        return (React.createElement("div", { className: "text-canvas-400 t-mini plus mb-1" },
            React.createElement("span", null, source)));
    }
    if (!source && author) {
        return (React.createElement("div", { className: "text-canvas-400 t-mini plus mb-1" },
            React.createElement("span", null, author)));
    }
    return (React.createElement("div", { className: "text-canvas-400 t-mini plus mb-1" },
        React.createElement("span", null, source),
        React.createElement("span", null, ` • `),
        React.createElement("span", null, author)));
};
const LinkCard = ({ richObject }) => {
    const { title, author, previewImageUrl, source, url } = richObject;
    return (React.createElement(Card_1.Card, { to: url || "", target: "_blank", size: constants_1.Sizes.SM, pad: true },
        React.createElement("div", { className: "flex flex-row justify-between" },
            React.createElement("div", { className: "w-full mr-2" },
                top({ source, author }),
                React.createElement("div", { className: "t-small plus text-white text-clamping-2 overflow-hidden" }, title || url)),
            previewImageUrl && (React.createElement("div", { className: "flex-shrink-0 bg-cover bg-center bg-canvas-600 rounded-sm", style: {
                    height: "56px",
                    width: "56px",
                    backgroundImage: `url(${previewImageUrl})`
                } })))));
};
exports.LinkCard = LinkCard;
