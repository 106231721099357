import {
  useMessages,
  chatEmitter,
  useChannels,
} from "@get-frank-eng/chat-client";
import {
  Delay,
  FullCenter,
  IconButton,
  IconLinkButton,
  PollCard,
  PulseCard,
  Sizes,
  TopBar,
} from "@get-frank-eng/design-system";
import { ChannelType, ChatTypes, FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useMemo } from "react";
import Pager, { PaginationStrategy } from "../components/Pager";
import { useModals } from "../Modals";
import PollEmptyState from "./components/PollsEmptyState";
import useClosePoll from "./dataAccess/mutations/useClosePoll";
import useRespondToPoll from "./dataAccess/mutations/useRespondToPoll";
import usePolls from "./dataAccess/queries/usePolls";

const Polls = ({
  campaignId,
  topicId,
  channelId,
  topicSlug,
}: {
  campaignId?: string;
  topicId?: string;
  channelId: string;
  topicSlug?: string;
}) => {
  const { polls, loading, nextPage, refetch } = usePolls({
    campaignId,
    topicId,
  });
  const { closePoll } = useClosePoll();
  const { respondToPoll } = useRespondToPoll();
  const { setModal } = useModals();

  const { sendMessage } = useMessages({
    channelId,
  });

  const { channels } = useChannels();
  const thisChannel = useMemo(
    () => channels.find((ch) => ch.id === channelId),
    [channels, channelId]
  );

  React.useEffect(() => {
    chatEmitter.on("message-created", (emittedChannelId) => {
      if (emittedChannelId === channelId) {
        refetch();
      }
    });
    return () => {
      chatEmitter.removeAllListeners();
    };
  }, [channelId, refetch]);

  if (loading && !polls) {
    return (
      <Delay delay={500}>
        <div className="p-6 bg-canvas-700">
          <PulseCard />
          <PulseCard />
          <PulseCard />
          <PulseCard />
        </div>
      </Delay>
    );
  }

  return (
    <div>
      <TopBar
        title="Polls"
        left={
          <IconLinkButton
            to={campaignId ? `../${campaignId}` : `../${topicSlug}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        right={
          <IconButton
            buttonStyle="minimal"
            icon="add"
            size={Sizes.XL}
            loading={loading}
            onClick={() =>
              setModal({
                type: "createPollModal",
                props: {
                  isAnonymous: thisChannel.type === "anonymous",
                  afterSubmit: async (pollId) => {
                    await sendMessage({
                      sentAt: new Date(),
                      body: "",
                      richObjects: [
                        { type: "poll", foreignIdentifier: pollId },
                      ],
                    });
                    await refetch();
                  },
                },
                afterClose: refetch,
              })
            }
          />
        }
      />
      {!!polls.objects.length ? (
        <Pager<FrankBackendTypes.Poll>
          pagination={polls}
          paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
          onShowMore={nextPage}
        >
          <div className="space-y-2 p-6 bg-canvas-700 flex-grow">
            {polls.objects.map((poll) => (
              <PollCard
                key={poll.id}
                closePoll={closePoll}
                loading={loading}
                poll={poll}
                respondToPoll={respondToPoll}
              />
            ))}
          </div>
        </Pager>
      ) : (
        <div className="pt-12">
          <FullCenter>
            <PollEmptyState
              isAnonymous={thisChannel.type === ChannelType.ANONYMOUS}
              sendMessage={sendMessage}
              refetch={refetch}
            />
          </FullCenter>
        </div>
      )}
    </div>
  );
};

export default Polls;
