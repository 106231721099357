import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const IMPORT_TEMPLATE = gql`
  mutation import($input: ImportTemplateDTO!) {
    importTemplate(input: $input) {
      id
    }
  }
`;

export default function useImportTemplate() {
  const [
    invokeImportTemplate,
    {
      data: importTemplateData,
      called: importTemplateCalled,
      loading: importTemplateLoading,
      error: importTemplateError,
    },
  ] = useMutation<
    { importTemplate: FrankBackendTypes.Campaign },
    FrankBackendTypes.MutationImportTemplateArgs
  >(IMPORT_TEMPLATE);

  const { addToast } = useToaster();

  const importTemplate = React.useCallback(
    async (templateId) => {
      try {
        await invokeImportTemplate({ variables: { input: { templateId } } });
      } catch (e) {
        addToast({
          intent: Intent.FAILURE,
          children: "Something went wrong",
        });
        throw e;
      }
    },
    [invokeImportTemplate, addToast]
  );

  return {
    importTemplate,
    importTemplateCalled,
    importTemplateLoading,
    importTemplateError,
    importTemplateData,
  };
}
