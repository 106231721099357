import {
  Avatar,
  Button,
  FormGroup,
  Sizes,
  Textarea,
  useResponsive,
  ScreenSizes,
} from "@get-frank-eng/design-system";
import classNames from "classnames";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import usePostComment from "./usePostComment";
import useResolveComment from "./useResolveComment";

const CommentBody = ({
  comment,
  showReplyForm,
  setShowReplyForm,
  handleSubmit,
  submit,
  errors,
  register,
  loading,
  resolveComment,
}: {
  comment: FrankBackendTypes.DocumentComment;
  showReplyForm: boolean;
  setShowReplyForm: (bool: boolean) => void;
  handleSubmit: any;
  submit: any;
  errors: any;
  register: any;
  loading: boolean;
  resolveComment: () => any;
}) => (
  <>
    <div className="py-2 font-normal">
      <div className="flex flex-row items-center mb-3">
        <Avatar
          size={Sizes.SM}
          src={comment.profilePicUrl}
          altText={comment.commenterName}
          link={`/users/${comment.userId}`}
        />
        <div className="ml-2 flex-grow">
          <div className="t-mini plus">{comment.commenterName}</div>
          <div className="t-mini text-canvas-400">
            {comment.authorType === "template-creator"
              ? "Template creator"
              : comment.timeAgo}
          </div>
        </div>
        <div>
          <Button
            buttonStyle="minimal"
            iconLeft="check"
            size={Sizes.SM}
            onClick={resolveComment}
          />
        </div>
      </div>
      <div className="t-small">{comment.comment}</div>
    </div>
    {comment.replies.map((reply) => (
      <div key={reply.id} className="border-t py-2">
        <div className="flex flex-row items-center mb-3">
          <Avatar
            size={Sizes.SM}
            src={reply.profilePicUrl}
            altText={reply.commenterName}
            link={`/users/${reply.userId}`}
          />
          <div className="ml-2">
            <div className=" t-mini plus">{reply.commenterName}</div>
            <div className="t-mini text-canvas-400">{reply.timeAgo}</div>
          </div>
        </div>
        <div className="t-small">{reply.comment}</div>
      </div>
    ))}
    {comment.isReplyable && (
      <>
        {!showReplyForm && (
          <button
            className="t-mini plus text-canvas-400"
            type="button"
            onClick={() => setShowReplyForm(true)}
          >
            Reply
          </button>
        )}
        {showReplyForm && (
          <form onSubmit={handleSubmit(submit)} className="mt-2">
            <FormGroup name="comment" id="comment" label="Reply">
              <Textarea
                errorText={errors.comment && "Comment Required"}
                rows={2}
                register={register}
                registerArgs={{ required: true }}
                data-comment-reply-form={comment.id}
              />
            </FormGroup>
            <Button loading={loading} size={Sizes.SM} type="submit">
              Reply
            </Button>
          </form>
        )}
      </>
    )}
  </>
);

const renderSet = new Set();

const SlateComment = ({
  comment,
  onClick,
  emphasized,
  onResolve,
  onReply,
}: {
  comment?: FrankBackendTypes.DocumentComment;
  onReply: () => void;
  emphasized: boolean;
  onClick: () => void;
  onResolve: () => void;
}) => {
  const { screenSize } = useResponsive();
  const [shouldShow, setShouldShow] = React.useState(true);
  const [showReplyForm, setShowReplyForm] = React.useState(false);
  React.useEffect(() => {
    if (renderSet.has(comment.id)) {
      setShouldShow(false);
    } else {
      renderSet.add(comment.id);
    }
    return () => {
      renderSet.delete(comment.id);
    };
  }, [comment.id]);

  const { handleSubmit, register, errors } = useForm<{ comment: string }>();
  const { invokePostComment, loading } = usePostComment();
  const { invokeResolveComment, loading: resolveLoading } = useResolveComment();

  const resolveComment = React.useCallback(async () => {
    await invokeResolveComment({
      variables: { campaignId: comment.documentId, commentId: comment.id },
    });
    onResolve();
  }, [invokeResolveComment, onResolve, comment]);

  const submit = React.useCallback(
    async ({ comment: newComment }: { comment: string }) => {
      const commentId = uuid();

      await invokePostComment({
        variables: {
          input: {
            comment: newComment,
            commentId,
            documentId: comment.documentId,
            parentId: comment.id,
          },
        },
      });
      setShowReplyForm(false);
      onReply();
    },
    [invokePostComment, comment, onReply]
  );

  if (!shouldShow) {
    return null;
  }

  const desktopContainerClasses =
    "float-right clear-right w-0 overflow-visible";

  const mobileContainerClasses =
    "fixed bottom-0 left-0 shadow-xl max-h-48 overflow-auto z-30";

  const shouldEmphasize = emphasized || showReplyForm;

  const desktopCardClasses = classNames([
    {
      "shadow-sm scale-90 select-none": !shouldEmphasize,
      "shadow-sm scale-100 -translate-y-10": shouldEmphasize,
    },
    "border rounded-sm bg-canvas-700 w-64 overflow-hidden px-3 py-1 border  ml-12 transform my-2",
    "duration-200 transition-transform",
    "-translate-y-8",
  ]);

  const mobileCardClasses = classNames([
    "w-screen",
    "bg-canvas-700",
    "z-50",
    "p-6",
    "border-t",
    { hidden: !shouldEmphasize },
  ]);

  const cardClasses =
    screenSize > ScreenSizes.SM ? desktopCardClasses : mobileCardClasses;

  const containerClasses =
    screenSize > ScreenSizes.SM
      ? desktopContainerClasses
      : mobileContainerClasses;

  const body = (
    <CommentBody
      handleSubmit={handleSubmit}
      errors={errors}
      loading={loading}
      showReplyForm={showReplyForm}
      comment={comment}
      setShowReplyForm={setShowReplyForm}
      submit={submit}
      register={register}
      resolveComment={resolveComment}
    />
  );

  if (screenSize <= ScreenSizes.SM) {
    return body;
  }

  return (
    <div className={containerClasses} onClick={onClick} contentEditable={false}>
      <div
        className={cardClasses}
        // style={{ right: 40 }}
        // ref={portaledRef}
      >
        {body}
      </div>
    </div>
  );
};

export default SlateComment;
