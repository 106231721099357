import { ProgressBar } from "@get-frank-eng/design-system";
import { LinkButton } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useBuilderNavLogic from "../hooks/useBuilderNavLogic";

const MobileBottom = ({
  workflowState,
  campaign,
}: {
  workflowState: FrankBackendTypes.CampaignWorkflowState;
  campaign: FrankBackendTypes.Campaign;
}) => {
  const {
    lastStepUrl,
    nextStepUrl,
    currentStepIndex,
    totalSteps,
  } = useBuilderNavLogic({ workflowState });

  const { setModal } = useModals();

  return (
    <div className="fixed border-t bottom-0 left-0 w-screen">
      <ProgressBar numerator={currentStepIndex + 1} denominator={totalSteps} />
      <div className="p-3 flex flex-row items-center justify-between">
        <LinkButton
          disabled={currentStepIndex === 0}
          to={currentStepIndex > 0 && `./${lastStepUrl}`}
          buttonStyle="minimal"
          iconLeft="arrow_back"
        />
        <div className="text-center">
          <div className="t-label-3 text-brand-400">
            {currentStepIndex + 1} / {totalSteps}
          </div>
          <button
            type="button"
            onClick={() =>
              setModal({
                type: "campaignBuilderMobileNavModal",
                props: { campaign },
              })
            }
            className="t-small plus"
          >
            Show Menu
          </button>
        </div>
        <LinkButton
          disabled={currentStepIndex === totalSteps - 1}
          to={currentStepIndex < totalSteps - 1 && `./${nextStepUrl}`}
          buttonStyle="brand"
          iconLeft="arrow_forward"
        />
      </div>
    </div>
  );
};

export default MobileBottom;
