import { Intent, useToaster } from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";

const useConfirmUnsignCampaignModal = ({
  unsign,
  refetch,
  afterUnsign,
}: {
  unsign: () => void;
  refetch: () => void;
  afterUnsign?: () => void;
}) => {
  const { openConfirmationModal } = useModals();
  const toaster = useToaster();
  const useConfirmUnsign = async () => {
    const confirm = await openConfirmationModal({
      bodyText:
        "This campaign needs your support and your voice. If you decide to remove your support, you can always sign the campaign again later.",
      actionText: `Confirm`,
      title: "Are you sure you want to remove your signature",
    });
    if (confirm) {
      await unsign();
      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "You have removed your signature",
      });
    }
    if (afterUnsign) {
      afterUnsign();
    }
  };

  return useConfirmUnsign;
};

export default useConfirmUnsignCampaignModal;
