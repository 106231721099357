import {
  FormGroup,
  IconButton,
  Input,
  Intent,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import useCampaignLinks from "../dataAccess/mutations/useCampaignLinks";

const LittleButton = ({ onClick, icon }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="ml-2 shadow-sm flex items-center justify-center text-canvas-100 bg-canvas-500 h-6 w-6"
    >
      <i style={{ fontSize: "16px" }} className="material-icons-outlined">
        {icon}
      </i>
    </button>
  );
};

export const LinkCard = ({
  link,
  deleteLink,
  clickable = false,
  allowRemove = true,
}: {
  link: FrankBackendTypes.Link;
  deleteLink?: (linkId: string) => Promise<void>;
  clickable?: boolean;
  allowRemove?: boolean;
}) => {
  const [
    isLinkOptimisticallyDeleted,
    setLinkOptimisticallyDeleted,
  ] = React.useState(false);

  const deleteLinkAndSetOptimisticallyDeleted = React.useCallback(async () => {
    setLinkOptimisticallyDeleted(true);
    try {
      await deleteLink(link.id);
    } catch (e) {
      setLinkOptimisticallyDeleted(false);
    }
  }, [deleteLink, setLinkOptimisticallyDeleted, link]);

  if (isLinkOptimisticallyDeleted) {
    return null;
  }
  return (
    <a
      href={clickable && link.url}
      target="_blank"
      rel="noreferrer"
      className="bg-canvas-700 rounded group h-48 border overflow-hidden relative"
    >
      {allowRemove && (
        <div className="transition-opacity duration-200 opacity-0 group-hover:opacity-100 absolute right-0 top-0 transform translate-y-2 -translate-x-2 flex flex-row ">
          <LittleButton
            onClick={deleteLinkAndSetOptimisticallyDeleted}
            icon="close"
          />
        </div>
      )}
      <div
        className="bg-cover bg-center h-32"
        style={{ backgroundImage: `url(${link.preview.imageUrl})` }}
      />
      <div className="mt-3 px-3 t-small plus truncate">
        {link.preview.title || (
          <a target="_blank" rel="noreferrer" href="link.url">
            {link.preview.url}
          </a>
        )}
      </div>
      {link.preview.title && (
        <a
          href={link.url}
          target="_blank"
          rel="noreferrer"
          className="px-3 t-small text-canvas-400 truncate block"
        >
          {link.preview.url}
        </a>
      )}
    </a>
  );
};

const CampaignLinksEditList = ({
  links,
  refetch,
  campaignId,
}: {
  links: FrankBackendTypes.Link[];
  refetch: () => any;
  campaignId: string;
}) => {
  const { addLink, deleteLink, loadingAddLink } = useCampaignLinks({
    campaignId,
  });
  const [isAddLinkShowing, setAddLinkShowing] = React.useState(false);

  const toaster = useToaster();
  const addLinkAndRefetch = React.useCallback(
    async ({ url }: { url: string }) => {
      await addLink(url);
      refetch();
      toaster.addToast({ intent: Intent.SUCCESS, children: "Link added" });
      setAddLinkShowing(false);
    },
    [addLink, refetch, toaster, setAddLinkShowing]
  );

  const { errors, handleSubmit, register } = useForm<{ url: string }>();

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 gap-2">
        {links.map((link) => (
          <LinkCard deleteLink={deleteLink} link={link} key={link.id} />
        ))}
      </div>
      {!isAddLinkShowing && (
        <button
          type="button"
          className="block my-4 cursor-pointer t-regular plus text-canvas-400"
          onClick={() => setAddLinkShowing(true)}
        >
          + Add Link
        </button>
      )}
      {isAddLinkShowing && (
        <form
          className="flex flex-row items-center"
          onSubmit={handleSubmit(addLinkAndRefetch)}
        >
          <FormGroup label="Link URL" name="url" id="url">
            <Input
              errorText={errors.url && "URL Required"}
              disabled={loadingAddLink}
              type="url"
              register={register}
              registerArgs={{ required: true }}
              autoFocus
            />
          </FormGroup>
          <IconButton
            className="ml-2"
            type="submit"
            icon="check"
            size={Sizes.LG}
          />
        </form>
      )}
    </>
  );
};

export default CampaignLinksEditList;
