import {
  Button,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";

type FormProps = {
  href: string;
  value: string;
};

export default function MessageLinkModal({
  onSubmit,
  defaultValue,
}: {
  onSubmit: (args: { href: string; value: string }) => void;
  defaultValue?: string;
}) {
  const { register, handleSubmit } = useForm<FormProps>();
  const { closeModal } = useModals();

  return (
    <>
      <ModalHeader title="Add Link" />
      <ModalBody>
        <form
          onSubmit={handleSubmit((a) => {
            onSubmit(a);
            closeModal();
          })}
          id="link-modal-form"
        >
          <FormGroup
            id="value"
            name="value"
            label="Text (what gets clicked on)"
          >
            <Input
              defaultValue={defaultValue}
              register={register}
              registerArgs={{ required: true }}
            />
          </FormGroup>
          <FormGroup label="URL" name="href" id="href">
            <Input register={register} registerArgs={{ required: true }} />
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          buttonStyle="minimal"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button form="link-modal-form" data-cy="send-report" type="submit">
          Add link
        </Button>
      </ModalFooter>
    </>
  );
}
