"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachedBadge = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Nudge_1 = require("./Nudge");
const sizeMap = {
    [constants_1.Sizes.XS]: "1",
    [constants_1.Sizes.SM]: "2",
    [constants_1.Sizes.MD]: "4",
    [constants_1.Sizes.LG]: "8",
    [constants_1.Sizes.XL]: "16"
};
const AttachedBadge = ({ children, color, size, position, ping = false, nudgeX, nudgeY, show = true }) => {
    const tailwindSize = sizeMap[size];
    const positionClasses = {
        "upper-right": `top-0 right-0`,
        "lower-right": `bottom-0 right-0`,
        "lower-left": `bottom-0 left-0`,
        "upper-left": `top-0 left-0`
    }[position];
    return (React.createElement("div", { className: "relative" },
        children,
        show && (React.createElement(Nudge_1.Nudge, { x: nudgeX, y: nudgeY, className: `-m-1 shadow rounded-full bg-${color} w-${tailwindSize} h-${tailwindSize} absolute ${positionClasses}` }, ping && (React.createElement("div", { className: `rounded-full bg-${color} w-full h-full absolute opacity-75 animate-ping` }))))));
};
exports.AttachedBadge = AttachedBadge;
