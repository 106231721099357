import { ResourceCard } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import EmptyState from "../../components/EmptyState";

export const TopicResources = ({
  topic,
}: {
  topic: FrankBackendTypes.Topic;
}) => {
  if (!topic.resources.length) {
    return <EmptyState text="None yet" />;
  }
  return (
    <div className="space-y-3">
      {topic.resources.map((resource) => (
        <ResourceCard
          topic={topic.name}
          resourceLabel={resource.label}
          title={resource.title}
          url={resource.url}
          imageUrl={resource.imageUrl}
        />
      ))}
    </div>
  );
};
