import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import AuthN from "./Auth/AuthN";
import generateAuthHeader from "./Auth/generateAuthHeader";
import apolloLogger from "apollo-link-logger";
import { GraphQLError } from "graphql";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  await AuthN.waitForValidSession();
  const authHeader = generateAuthHeader();
  return {
    headers: {
      ...headers,
      authorization: authHeader || "",
    },
  };
});

const hasUnauthorizedGraphQLError = (errors: readonly GraphQLError[]) => {
  return errors.filter((error) => {
    if (error?.extensions?.exception?.response?.statusCode === 401) {
      return true;
    }
    return false;
  });
};

const logoutLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors && hasUnauthorizedGraphQLError(graphQLErrors)) {
    AuthN.hasValidSession().then((validSession) => {
      if (!validSession) {
        AuthN.logout();
      }
    });
  }
});

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
});

const linkArr = [authLink, logoutLink, sentryLink, httpLink];

if (process.env.REACT_APP_ENABLE_APOLLO_LOGGER) {
  linkArr.unshift(apolloLogger);
}

const client = new ApolloClient({
  link: ApolloLink.from(linkArr),
  cache: new InMemoryCache(),
});

export default client;
