import { useMutation, useQuery, gql } from "@apollo/client";
import {
  InlineAlert,
  Intent,
  PulseMessage,
  ActivityFeed,
  Delay,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { flatten } from "lodash";
import * as React from "react";
import Crossroads from "../components/Crossroads";
import AlertEmptyState from "../components/EmptyState";
import client from "../gqlClient";

const LOAD_NOTIFICATIONS = gql`
  query Profile($page: Int!) {
    myProfile {
      id
      notifications(pagination: { page: $page, perPage: 10 }) {
        hasNext
        unread
        unseen
        objects {
          id
          verb
          activities {
            id
            link
            htmlDescription
            time
            object {
              type
              id
            }
            campaign {
              id
              title
            }
            user {
              shortName
              isYou
              id
              profilePic {
                id
                url
              }
            }
          }
          actorCount
          activityCount
          isRead
          createdTimeAgo
          isSeen
          verb
          group
        }
      }
    }
  }
`;

const MARK_AS_SEEN = gql`
  mutation markAsSeen {
    markAllNotificationsAsSeen {
      nothing
    }
  }
`;

async function invokeMarkAsRead(id: string) {
  return client.mutate({
    mutation: gql`
      mutation {
        markNotificationsAsRead(ids: ["${id}"]) {
          nothing
        }
      }
    `,
  });
}

const Alerts = () => {
  const [invokeMarkAsSeen] = useMutation<any>(MARK_AS_SEEN);

  React.useEffect(() => {
    invokeMarkAsSeen();
  }, [invokeMarkAsSeen]);

  const markAsRead = React.useCallback((id: string) => {
    return invokeMarkAsRead(id);
  }, []);

  let page = 0;

  const { loading, error, data, fetchMore } = useQuery<
    {
      myProfile: FrankBackendTypes.MyProfile;
    },
    { page: number }
  >(LOAD_NOTIFICATIONS, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  const loadNextPage = React.useCallback(() => {
    fetchMore({
      variables: { page: ++page },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        const toReturn = {
          ...prev,
          myProfile: {
            ...prev.myProfile,
            notifications: {
              ...fetchMoreResult.myProfile.notifications,
              objects: [
                ...prev.myProfile.notifications.objects,
                ...fetchMoreResult.myProfile.notifications.objects,
              ],
            },
          },
        };
        return toReturn;
      },
    });
  }, [page, fetchMore]);

  if (loading) {
    return (
      <Delay delay={500}>
        <div className="space-y-6 mt-16">
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
        </div>
      </Delay>
    );
  }

  if (error) {
    return (
      <InlineAlert title="Server Error" intent={Intent.FAILURE}>
        Unable to load alerts
      </InlineAlert>
    );
  }

  const notifications = data?.myProfile?.notifications.objects.map(
    (notification) => ({
      ...notification,
      activities: [
        ...notification.activities.map((activity) => ({
          ...activity,
          verb: notification.verb,
          isSeen: notification.isSeen,
        })),
      ],
    })
  );

  const activities = flatten(
    notifications.map((notification) => notification.activities)
  );

  const toRender = activities.length ? (
    <ActivityFeed activities={activities} />
  ) : (
    <AlertEmptyState text={"You don't have any notifications."} />
  );

  return (
    <div>
      <div className="t-title-4 plus pt-4 pb-8">Notifications</div>
      {toRender}
      {data?.myProfile.notifications.hasNext && (
        <Crossroads onEntering={loadNextPage} />
      )}
    </div>
  );
};

export default Alerts;
