import {
  ActivityFeed,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import Loading from "../../components/Loading";
import Pager, { PaginationStrategy } from "../../components/Pager";
import useActivityQuery from "../dataAccess/queries/useActivityQuery";

export default function ActivityFeedModal({ id, type }) {
  const { data, loading, error, fetchMoreFeed } = useActivityQuery({
    perPage: 10,
    id,
    type,
  });
  return (
    <>
      <ModalHeader title="Feed" />
      <ModalBody>
        {loading && <Loading />}
        {!loading && (
          <Pager<FrankBackendTypes.Activity>
            pagination={data}
            onShowMore={fetchMoreFeed}
            paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
          >
            <ActivityFeed activities={data.objects} />
          </Pager>
        )}
      </ModalBody>
    </>
  );
}
