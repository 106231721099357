import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import uniqBy from "lodash/uniqBy";
import * as React from "react";

export const activityFeedItemFragment = gql`
  fragment FeedFragment on PaginatedActivity {
    hasNext
    total
    objects {
      time
      id
      quoteText
      verb
      timeAgo
      htmlDescription
      campaign {
        id
        title
      }
      user {
        id
        shortName
        isYou
        profilePic {
          id
          url
        }
      }
    }
  }
`;

const query = gql`
  query Feed($pagination: PaginationArgumentDTO!, $id: ID!, $type: String!) {
    activity(pagination: $pagination, id: $id, type: $type) {
      ...FeedFragment
    }
  }
  ${activityFeedItemFragment}
`;

export default function useActivityQuery({
  perPage,
  type,
  id,
}: {
  perPage: number;
  type: string;
  id: string;
}) {
  const { data, loading, error, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "activity">,
    FrankBackendTypes.QueryActivityArgs
  >(query, {
    variables: {
      pagination: { page: 0, perPage },
      id,
      type,
    },
  });

  const fetchMoreFeed = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.ceil(data?.activity.objects.length / perPage),
        },
      },
      updateQuery(
        prev: Pick<FrankBackendTypes.Query, "activity">,
        { fetchMoreResult }
      ) {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          activity: {
            ...prev.activity,
            ...fetchMoreResult.activity,
            objects: uniqBy(
              [...prev.activity.objects, ...fetchMoreResult.activity.objects],
              "id"
            ),
          },
        };
      },
    });
  }, [fetchMore, perPage, data]);

  return { data: data?.activity, loading, error, fetchMoreFeed };
}
