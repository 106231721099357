import { SidebarItem } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

export const NavItem = ({
  to,
  selected,
  incomplete,
  children,
}: {
  to: string;
  selected: boolean;
  incomplete: boolean;
  children: any;
}) => {
  return (
    <SidebarItem
      to={to}
      active={selected}
      badge={incomplete}
      icon={incomplete ? "Circle" : "CheckCircle"}
      iconColor={incomplete ? "white" : "frank-green-400"}
    >
      {children}
    </SidebarItem>
  );
};

const CampaignBuilderNav = ({
  campaign,
}: {
  campaign: FrankBackendTypes.Campaign;
}) => {
  const url = window.location.href;

  const isIncomplete = (item: string) =>
    !!campaign.publishingValidationIssues.find(
      (issue) => issue.category === item
    );

  return (
    <nav className="space-y-2">
      {/* <ol> */}
      {/* <NavItem
          incomplete={isIncomplete("category")}
          to="./category"
          selected={url.includes("category")}
        >
          1. Category
        </NavItem> */}

      <NavItem
        incomplete={isIncomplete("title")}
        to="./title"
        selected={url.includes("title")}
      >
        1. Title
      </NavItem>

      <NavItem
        incomplete={isIncomplete("petition")}
        to="./demand"
        selected={url.includes("demand")}
      >
        2. Demand Letter
      </NavItem>

      <NavItem
        incomplete={isIncomplete("recipients")}
        to="./recipients"
        selected={url.includes("recipients")}
      >
        3. Recipients
      </NavItem>

      <NavItem
        incomplete={false}
        to="./attachments"
        selected={url.includes("attachments")}
      >
        4. Attachments
      </NavItem>

      <NavItem
        incomplete={false}
        to="./organizers"
        selected={url.includes("organizers")}
      >
        5. Organizers
      </NavItem>

      <NavItem
        incomplete={isIncomplete("settings")}
        to="./settings"
        selected={url.includes("settings")}
      >
        6. Settings
      </NavItem>

      {campaign.workflowState ===
        FrankBackendTypes.CampaignWorkflowState.Draft && (
        <NavItem
          incomplete={false}
          to="./publish"
          selected={url.includes("publish")}
        >
          7. Review and publish
        </NavItem>
      )}
      {/* </ol> */}
    </nav>
  );
};

export default CampaignBuilderNav;
