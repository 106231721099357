import * as React from "react";
import classNames from "classnames";
import { Icon, SegmentedControl, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CoworkerStatus } from "frank-types/dist/frank-backend-types";

export default function TrustButtons({
  id,
  yourTrustLevel,
  totalTrustDownvotes,
  totalTrustUpvotes,
  coworkerTrustVote,
  disabled,
  status,
  loading,
}: Pick<
  FrankBackendTypes.Coworker,
  "id" | "yourTrustLevel" | "totalTrustUpvotes" | "totalTrustDownvotes"
> & {
  loading: boolean;
  status: CoworkerStatus;
  disabled: boolean;
  coworkerTrustVote: (args: {
    coworkerId: FrankBackendTypes.Coworker["id"];
    trust: number;
  }) => any;
}) {
  const radioOptions = React.useMemo(
    () => [
      {
        id: `${id}-trust-coworker`,
        value: 1,
        label: (
          <div className="flex flex-row justify-center space-x-1 items-center">
            <Icon icon="arrow_upward" size={Sizes.SM} />
            <div>
              Trusted (
              {status === CoworkerStatus.Member ? "-" : totalTrustUpvotes || 0})
            </div>
          </div>
        ),
        defaultChecked:
          status !== CoworkerStatus.Member && yourTrustLevel === 1,
      },
      {
        id: `${id}-distrust-coworker`,
        value: -1,
        label: (
          <div className="flex flex-row justify-center space-x-1 items-center">
            <Icon icon="arrow_downward" size={Sizes.SM} />
            <div>
              Not trusted (
              {status === CoworkerStatus.Member
                ? "-"
                : totalTrustDownvotes || 0}
              )
            </div>
          </div>
        ),
        defaultChecked:
          status !== CoworkerStatus.Member && yourTrustLevel === -1,
      },
    ],
    [id, status, totalTrustUpvotes, yourTrustLevel, totalTrustDownvotes]
  );

  return (
    <div
      className={classNames([
        {
          "cursor-wait": loading,
        },
      ])}
    >
      <SegmentedControl
        legendTitle="Trust coworkers"
        name={`${id}-coworker-trust`}
        options={radioOptions}
        labelStyles={classNames([
          "t-micro",
          {
            "cursor-pointer hoverable": !disabled && !loading,
            "cursor-wait": loading,
            "cursor-not-allowed": disabled,
          },
        ])}
        disabled={disabled}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          coworkerTrustVote({ coworkerId: id, trust: +target.value });
        }}
      />
    </div>
  );
}
