import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import client from "../../gqlClient";
import { pendingMemberDataFragment } from "../../PendingMembers/dataAccess/queries/usePendingMembersListData";

const COWORKER_TABLE_MUTATION = gql`
  mutation ChangeCoworker($change: CoworkerChangeDTO!) {
    changeCoworker(change: $change) {
      isNew
      error {
        coworkerId
        column
        type
        shortMessage
        message
      }
      coworker {
        id
        name
        notes
        noteCount
        email
        title
        employmentType
        joinedAtFormatted
        canInvite
        canInviteToCampaign
        canArchive
        canUnArchive
        canVoteOnTrust
        canFlag
        status
        editable
        shouldWarnBeforeInvite
        customData {
          key
          value
          id
        }
      }
    }
  }
`;

const VOUCHING_COWORKER_MUTATION = gql`
  mutation ChangeCoworker($change: CoworkerChangeDTO!) {
    changeCoworker(change: $change) {
      isNew
      coworker {
        ...PendingMemberData
      }
    }
  }
  ${pendingMemberDataFragment}
`;

export function makeOptimisticCoworker(
  chg: FrankBackendTypes.MutationChangeCoworkerArgs["change"]
): FrankBackendTypes.Coworker {
  // @ts-ignore
  return {
    id: chg.id,
    __typename: "Coworker",
    ...chg,
    status: FrankBackendTypes.CoworkerStatus.Lead,
    user: null,
    editable: true,
  };
}

export type ChangeFunc = (
  chg: FrankBackendTypes.MutationChangeCoworkerArgs["change"]
) => Promise<FrankBackendTypes.CoworkerChangeResponseDto>;

const useDataTableChanger = ({ view }: { view: "vouching" | "table" }) => {
  const [
    invokeChangeCoworker,
    { loading: changeLoading, error: changeError },
  ] = useMutation<
    { changeCoworker: FrankBackendTypes.CoworkerChangeResponseDto },
    FrankBackendTypes.MutationChangeCoworkerArgs
  >(view === "vouching" ? VOUCHING_COWORKER_MUTATION : COWORKER_TABLE_MUTATION);

  const change: ChangeFunc = React.useCallback(
    async (chg: FrankBackendTypes.MutationChangeCoworkerArgs["change"]) => {
      const { data } = await invokeChangeCoworker({
        optimisticResponse: {
          // @ts-ignore
          changeCoworker: {
            __typename: "CoworkerChangeResponseDTO",
            id: chg.id,
            isNew: true,
            coworker: makeOptimisticCoworker(chg),
          },
        },
        variables: {
          change: {
            ...chg,
          },
        },
      });
      client.writeFragment({
        id: chg.id,
        fragment: gql`
          fragment updatedCoworker on Coworker {
            id
            name
            notes
            noteCount
            email
            title
            joinedAtFormatted
            canInvite
            canInviteToCampaign
            canArchive
            canUnArchive
            canVoteOnTrust
            canFlag
            status
            editable
            shouldWarnBeforeInvite
          }
        `,
        data: data.changeCoworker.coworker,
      });
      return data.changeCoworker;
    },
    [invokeChangeCoworker]
  );

  return { change, changeLoading, changeError };
};

export default useDataTableChanger;
