import { FormGroup, Input, Switch } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";
import useUpdateUpdateCampaign from "../dataAccess/mutations/useUpdateCampaign";

const Settings = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  const { register, handleSubmit, errors } = useForm<{
    signatureGoal: string;
  }>();
  const { updateCampaign, loadingUpdateCampaign } = useUpdateUpdateCampaign({
    campaignId: campaign.id,
  });
  const submitForm = React.useCallback(
    async ({ signatureGoal }: { signatureGoal: string }) => {
      await updateCampaign({
        signatureGoal: Math.round(+signatureGoal),
      });
    },
    [updateCampaign]
  );

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Customize your settings</h2>
      <p className="t-regular text-canvas-400 mb-8">
        Choose custom settings that are right for your workplace. Please note,
        you are not able to change these settings after you publish.
      </p>

      <h3 className="t-regular plus my-3">Signatures</h3>

      <form onSubmit={handleSubmit(submitForm)}>
        <div className="mb-4 flex flex-col sm:flex-row sm:items-center justify-between">
          <div className="mr-6">
            <div className="t-small plus mb-1">Signature goal</div>
            <p className="t-small text-canvas-400">
              Choose the number of signatures required to deliver this campaign.
              This must be at least 51% of your group size. Campaigns with at
              least 75% support are more likely to be successful.
            </p>
          </div>
          <FormGroup label="Goal" id="signatureGoal" name="signatureGoal">
            <Input
              errorText={errors.signatureGoal && `Signature goal is required`}
              type="number"
              onBlur={handleSubmit(submitForm)}
              register={register}
              defaultValue={campaign.signatureGoal}
              disabled={loadingUpdateCampaign}
              registerArgs={{
                required: true,
                min: 1,
              }}
            />
          </FormGroup>
        </div>
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="mr-6 sm:mr-16">
            <div className="t-small plus mb-1">Anonymous signatures</div>
            <p className="t-small text-canvas-400">
              By default, individual names will be listed on Demand Letters.
              Sharing names generally sends a stronger message to management,
              but we know this isn&apos;t safe for all situations. If a campaign
              is set to hide signatures, management will receive a letter that
              says the total amount of employees who signed, but not individual
              names.
            </p>
          </div>
          <Switch
            loading={loadingUpdateCampaign}
            label="signaturesVisible"
            id="signaturesVisible"
            checked={!campaign.signaturesVisible}
            onChange={async () => {
              await updateCampaign({
                signaturesVisible: !campaign.signaturesVisible,
              });
            }}
          />
        </div>
      </form>

      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Settings;
