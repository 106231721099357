import {
  Accordion,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";

const ActionFAQModal = () => {
  return (
    <>
      <ModalHeader title="Help" />
      <ModalBody>
        <>
          <h2 className="t-large plus">Collective Action</h2>
          <p className="t-regular text-canvas-400">
            Collective action in the workplace happens when a group of people
            act as one to improve conditions for all. Supporting this campaign
            is an example of collective action. The more supporters a campaign
            has, the stronger your collective action is. This is why we
            recommend that at least 75% of your coworkers sign a campaign.
          </p>
          <h2 className="t-large plus pt-6 pb-3">FAQs</h2>
          <Accordion title="What are actions?">
            <p className="mb-3 text-canvas-400">
              When your campaign is delivered to management, you may not receive
              a response you're happy with — or one at all. This is when workers
              take collective action to show management they're serious about
              demanding change. These Actions are broad and varied: some are
              symbolic, some are more severe than others, and some involve more
              serious risk than others.{" "}
            </p>

            <p className="text-canvas-400">
              The Actions listed here are a few common ways workers escalate
              their organizing efforts, but are not the only ways you and your
              coworkers can put pressure on your bosses.{" "}
            </p>
          </Accordion>
          <Accordion title="What does upvoting mean?">
            <p className="mb-3 text-canvas-400">
              Upvoting an Action means you are willing to take this step if you
              need to escalate your organizing effort. It does not commit you to
              taking any action. It simply expresses your willingness to
              consider this action if necessary.{" "}
            </p>

            <p className="text-canvas-400">
              Downvoting means you are not willing to consider this Action. You
              can also choose not to upvote or downvote Actions.{" "}
            </p>
          </Accordion>
          <Accordion title="Do I have to participate in Actions?">
            <div className="text-canvas-400">
              Upvoting or downvoting Actions does not commit you to taking any
              action. It helps your group gauge which actions will garner the
              most participation. In general, the more participation an Action
              has, the more successful the Action will be. We recommend having
              at minimum 75% solidarity.
            </div>
          </Accordion>

          <Accordion title="What are the risks of taking action?">
            <div className="text-canvas-400">
              These actions have varying degrees of risk. Organizing is
              inherently risky for workers and we will never pretend otherwise.
              There are ways to mitigate the risk you face (100% solidarity is a
              great way) but, to speak frankly, it cannot be entirely removed
              from the process. It is important for you to weigh this when
              considering taking action.
            </div>
          </Accordion>

          <Accordion title="Are there other actions we can take?">
            <div className="text-canvas-400">
              Yes, as a group of workers you are entirely in control of your
              collective action journey. You can create custom Actions, check
              Frank's Resource Center for ideas, search the web, or discuss with
              your coworkers. We strongly suggest you get familiar with your
              legal rights around collective action in your workplace.
            </div>
          </Accordion>
        </>
      </ModalBody>
    </>
  );
};

export default ActionFAQModal;
