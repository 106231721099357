import * as React from "react";
import { CardGrid } from "./CampaignCardWrapper";
import CampaignMenu from "./CampaignMenu";
import { CampaignCard } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const CampaignList = ({
  campaigns,
  size,
}: {
  campaigns: FrankBackendTypes.Campaign[];
  size?: "large" | "small";
}) => {
  return (
    <>
      <CardGrid size={size}>
        {campaigns.map((campaign) => (
          <CampaignCard
            workflowState={campaign.workflowState}
            id={campaign.id}
            key={campaign.id}
            signatureCount={campaign.signatureCount}
            signatureGoal={campaign.signatureGoal}
            title={campaign.title}
            campaignOrganizers={campaign.campaignOrganizers}
            stepsToPublish={campaign.publishingValidationIssueCount}
            relationshipToMe={campaign.relationshipToMe}
            campaignMenu={
              <CampaignMenu
                campaignId={campaign.id}
                workflowState={campaign.workflowState}
                relationshipToMe={campaign.relationshipToMe}
              />
            }
          />
        ))}
      </CardGrid>
    </>
  );
};

export default CampaignList;
