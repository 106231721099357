"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileResourceCard = exports.LargeResourceCard = void 0;
const React = __importStar(require("react"));
const Card_1 = require("./Card");
function LargeResourceCard({ url, imageUrl, label, title, logoUrl, creator }) {
    return (React.createElement(Card_1.Card, { to: url, target: "_blank" },
        React.createElement("div", { className: "bg-cover bg-center h-32 bg-canvas-300", style: { backgroundImage: `url(${imageUrl})` } }),
        React.createElement("div", { className: "pt-3 px-4 pb-4 flex flex-col justify-between h-32" },
            React.createElement("div", null,
                React.createElement("div", { className: "t-label-4 text-canvas-400" }, label),
                React.createElement("div", { className: "t-small plus text-clamping-3" }, title)),
            React.createElement("div", { className: "flex flex-row justify-start" },
                React.createElement("img", { src: logoUrl, alt: "logo", className: "w-4 h-4 border-transparent rounded-full" }),
                React.createElement("div", { className: "t-mini pl-2 truncate" }, creator)))));
}
exports.LargeResourceCard = LargeResourceCard;
const MobileResourceCard = ({ url, imageUrl, label, title, logoUrl, creator }) => {
    return (React.createElement("a", { href: url, rel: "noreferrer", target: "_blank", className: "flex flex-row justify-between p-4 bg-canvas-700 rounded group border overflow-hidden shadow-sm" },
        React.createElement("div", { className: "pr-12 flex flex-col justify-between" },
            React.createElement("div", null,
                React.createElement("div", { className: "t-mini text-canvas-400 t-label-4" }, label),
                React.createElement("div", { className: "t-small plus text-clamping-2 mb-1" }, title)),
            React.createElement("div", { className: "flex flex-row justify-start items-center" },
                React.createElement("img", { src: logoUrl, alt: "logo", className: "w-4 h-4 border rounded-full" }),
                React.createElement("div", { className: "t-mini pl-2" }, creator))),
        React.createElement("div", { className: "flex-shrink-0 bg-cover bg-center bg-canvas-300 rounded-sm", style: {
                height: "72px",
                width: "72px",
                backgroundImage: imageUrl ? `url(${imageUrl})` : undefined
            } })));
};
exports.MobileResourceCard = MobileResourceCard;
