"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoworkerStatus = void 0;
const React = __importStar(require("react"));
const frank_types_1 = require("frank-types");
const constants_1 = require("../constants");
const colorMap = {
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Lead]: {
        text: "canvas-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Archived]: {
        text: "canvas-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Admin]: {
        text: "frank-green-400"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Member]: {
        text: "frank-green-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Invited]: {
        text: "frank-gold-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Onboarding]: {
        text: "frank-green-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.InGroupReview]: {
        text: "frank-gold-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Flagged]: {
        text: "frank-red-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.NotAllowed]: {
        text: "frank-red-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Rejected]: {
        text: "frank-red-300"
    },
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Deleted]: {
        text: "frank-red-300"
    }
};
const labelMap = {
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Lead]: "Not invited yet",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Invited]: "Invited",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Onboarding]: "Onboarding",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Flagged]: "Flagged",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Member]: "Member",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Rejected]: "Rejected",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.NotAllowed]: "Blocked",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.InGroupReview]: "In review"
};
const iconMap = {
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Lead]: "block",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.NotAllowed]: "cancel",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Invited]: "mail_outline",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Onboarding]: "access_time",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Flagged]: "flag",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Member]: "verified_user",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.Rejected]: "cancel",
    [frank_types_1.FrankBackendTypes.CoworkerStatus.InGroupReview]: "access_time"
};
const CoworkerStatus = ({ status, iconSize = constants_1.Sizes.XS, size = constants_1.Sizes.XS }) => {
    if (!status) {
        return (React.createElement("span", { className: `font-normal ${size === constants_1.Sizes.SM ? "t-mini" : "t-micro"} text-canvas-300` },
            React.createElement("span", null, "Not invited")));
    }
    const colors = colorMap[status];
    if (!colors) {
        console.error("no colors for ", status);
    }
    return (React.createElement("span", { className: `font-normal ${size === constants_1.Sizes.SM ? "t-mini" : "t-micro"} text-${colorMap[status].text}` },
        React.createElement("span", null, labelMap[status] || status)));
};
exports.CoworkerStatus = CoworkerStatus;
