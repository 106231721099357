import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import {
  FormGroup,
  Icon,
  Input,
  Intent,
  Sizes,
  useToaster
} from "@get-frank-eng/design-system";

export default ({
  currentPassphrase,
  update,
  token,
  loading,
  idModifier = ""
}) => {
  const passphraseToShow = currentPassphrase || "";
  const [passphrase, setPassphrase] = React.useState(passphraseToShow);
  const toaster = useToaster();

  const updatePassphrase = async passphrase => {
    try {
      await update({ passphrase }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Update successful"
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong"
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`require-passphrase${idModifier}`}
      defaultChecked={!!passphrase}
      title="Require Passcode"
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updatePassphrase(null);
          return;
        }
        if (!isChecked && currentPassphrase) {
          updatePassphrase(null);
        }
      }}
      contentToShowWhenChecked={setDirty => (
        <div className="flex flex-row w-64">
          <FormGroup label="Pass Phrase" name="passphrase" id="passphrase">
            <Input
              value={passphrase}
              autoFocus
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  updatePassphrase(passphrase);
                }
              }}
              onBlur={e => {
                if (currentPassphrase !== e.currentTarget.value) {
                  updatePassphrase(passphrase);
                }
              }}
              onChange={(e: any) => {
                setDirty(true);
                setPassphrase(e.currentTarget.value);
              }}
            />
          </FormGroup>
          <button
            disabled={loading}
            onClick={e => {
              e.preventDefault();
              updatePassphrase(passphrase);
            }}
            type="button"
            className="self-center mt-4 text-canvas-400 hover:text-canvas-600"
          >
            <Icon icon="chevron_right" size={Sizes.XL} />
          </button>
        </div>
      )}
    >
      Add a secret passcode to this invite link. Share the passcode with trusted
      coworkers via a secure channel or in person. We recommend that you do not
      share the link and the passcode in the same message or in the same
      channel.
    </SecurityFeatureRow>
  );
};
