"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FoldedCard = exports.Card = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const useClickOutside_1 = require("../hooks/useClickOutside");
const Button_1 = require("./Button");
const MaybeExternalLink_1 = require("./MaybeExternalLink");
const menus_1 = require("./menus");
const Card = ({ children, onClick, menuItems, className, minHeight, to, target, pad = false, size = constants_1.Sizes.MD, id }) => {
    const [isMenuShowing, setMenuShowing] = React.useState(false);
    const menuRef = React.useRef(null);
    useClickOutside_1.useClickOutside(menuRef, () => {
        setMenuShowing(false);
    });
    const menu = (React.createElement("div", { style: { paddingTop: size >= constants_1.Sizes.LG ? "12px" : "8px" }, className: "transition-opacity opacity-0 group-focus:opacity-100 group-hover:opacity-100 focus:opacity-100 absolute right-0 top-0 pr-2 flex flex-col items-end" },
        React.createElement(menus_1.MenuTrigger, { placement: "bottom-end", nudgeY: 10, trigger: "click", menu: React.createElement(menus_1.Menu, null, menuItems) },
            React.createElement(Button_1.Button, { size: constants_1.Sizes.XS, buttonStyle: "minimal", iconNudge: 1, onClick: e => {
                    e.preventDefault();
                    setMenuShowing(!isMenuShowing);
                }, iconLeft: "more_horiz" }))));
    const hoverable = onClick || to;
    const classes = `${pad ? (size < constants_1.Sizes.MD ? "p-4" : "p-5") : ""} flex group flex-col flex-start relative w-full text-left align-top rounded border bg-canvas-700 shadow-sm transition-shadow transition duration-500 transition-colors ${hoverable ? "hoverable" : ""} ${className}`;
    if (to) {
        return (React.createElement(MaybeExternalLink_1.MaybeExternalLink, { id: id, style: {
                minHeight: minHeight ? `${minHeight}px` : undefined
            }, target: target, to: to, className: classes, onClick: onClick },
            children,
            menuItems && menu));
    }
    if (onClick) {
        return (React.createElement("button", { id: id, style: { minHeight: minHeight ? `${minHeight}px` : undefined }, className: classes, type: "button", onClick: onClick },
            children,
            menuItems && menu));
    }
    return (React.createElement("div", { className: classes, id: id }, children));
};
exports.Card = Card;
const FoldedCard = (props) => {
    return (React.createElement("div", { className: "relative" },
        React.createElement(exports.Card, Object.assign({}, props)),
        React.createElement("div", { className: "h-8 w-8 absolute top-0 right-0 border-transparent border-l border-b z-10 rounded-bl bg-canvas-500 pointer-events-none" },
            React.createElement("div", { style: {
                    borderWidth: "0 3.5rem 3.5rem 0",
                    borderColor: "transparent var(--canvas-800) transparent transparent",
                    transform: "translate(-10px, -10px)"
                }, className: "absolute w-0 h-0 border-solid" }))));
};
exports.FoldedCard = FoldedCard;
