import * as React from "react";
import { useForm } from "react-hook-form";
import ContentContainer from "../components/ContentContainer";
import BackAndContinue from "../components/BackAndContinue";
import usePetitionRecipients from "../dataAccess/mutations/usePetitionRecipients";
import {
  Button,
  FormGroup,
  Icon,
  Input,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const PetitionRecipientCard = ({ recipient, onRemove }) => {
  return (
    <div className="flex flex-row w-full items-center pb-8">
      <div className="w-full">
        <div className="border-b bg-transparent py-3 mb-3">
          <div>
            <div className="t-regular">{recipient.fullName}</div>
          </div>
        </div>
        <div className="border-b bg-transparent py-3">
          <div>
            <div className="t-regular">{recipient.email}</div>
          </div>
        </div>
      </div>
      <button className="self-start ml-4" type="button" onClick={onRemove}>
        <Icon icon="close" size={Sizes.MD} />
      </button>
    </div>
  );
};

export default ({
  campaign,
  refetch,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => any;
}) => {
  const [nameFieldDirty, setNameFieldDirty] = React.useState(false);
  const [emailFieldDirty, setEmailFieldDirty] = React.useState(false);
  const {
    addRecipient,
    loadingAddRecipient: loading,
    removeRecipient,
  } = usePetitionRecipients({ campaignId: campaign.id });

  const { errors, handleSubmit, register, reset } = useForm<{
    fullName: string;
    email: string;
  }>();

  const submit = async ({ email, fullName }) => {
    await addRecipient({ fullName, email });
    await refetch();
    reset({ email: "", fullName: "" });
  };
  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Add recipients for your petition</h2>

      <p className="t-regular text-canvas-400 mb-6">
        Whom would you like to send the petition to? We recommend decision
        makers, like the CEO. The demand letter will not be sent until enough
        signatures are reached and you decide to send it.
      </p>
      <section className="pb-8">
        {!!campaign?.petitionRecipients.length &&
          campaign.petitionRecipients.map((recipient) => (
            <PetitionRecipientCard
              recipient={recipient}
              key={recipient.id}
              onRemove={async () => {
                await removeRecipient(recipient.id);
                await refetch();
              }}
            />
          ))}
      </section>

      <form
        onSubmit={handleSubmit(submit)}
        noValidate
        onBlur={handleSubmit(submit)}
        className="pb-6"
      >
        <FormGroup label="Name" name="fullName" id="fullName">
          <Input
            errorText={
              errors.fullName && nameFieldDirty && "Please enter a name"
            }
            disabled={!campaign}
            type="text"
            register={register}
            registerArgs={{ required: true }}
            onChange={() => setNameFieldDirty(true)}
          />
        </FormGroup>
        <FormGroup label="Email" name="email" id="email">
          <Input
            errorText={
              errors.email && emailFieldDirty && "Please enter a valid email"
            }
            disabled={!campaign}
            type="email"
            register={register}
            registerArgs={{ required: true, pattern: /^\S+@\S+$/i }}
            onChange={() => setEmailFieldDirty(true)}
          />
        </FormGroup>
        <Button
          buttonStyle="outline"
          type="submit"
          iconLeft="add"
          className="flex flex-row items-center"
          loading={loading}
        >
          Add another recipient
        </Button>
      </form>

      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};
