"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityFeed = exports.ActivityIcon = exports.ActivityItem = void 0;
const React = __importStar(require("react"));
const frank_types_1 = require("frank-types");
const Icon_1 = require("./Icon");
const constants_1 = require("../constants");
const FeatherIcons_1 = require("./FeatherIcons");
const TimestampText_1 = require("./TimestampText");
const Nudge_1 = require("./Nudge");
const Badge_1 = require("./Badge");
const ActivityItem = ({ title, link, children, createdAt, icon, isSeen = true }) => {
    const guts = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mr-3 relative self-start" },
            icon,
            !isSeen && (React.createElement("div", { className: "absolute top-0 right-0" },
                React.createElement(Nudge_1.Nudge, { y: -8, x: 15 },
                    React.createElement(Badge_1.StatusBadge, { size: constants_1.Sizes.LG, color: "brand-300", border: true }))))),
        React.createElement("div", { className: "-mb-1 flex-col space-y-1" },
            React.createElement("div", { className: "flex flex-col sm:flex-row sm:items-center sm:space-x-2 w-full" },
                React.createElement("div", { className: "t-small plus text-white whitespace-nowrap" }, title),
                React.createElement("div", { className: "t-mini text-canvas-400 truncate" },
                    React.createElement(TimestampText_1.TimestampText, { date: new Date(createdAt) }))),
            React.createElement("div", { className: "t-small" }, children))));
    if (!link) {
        return (React.createElement("div", { className: "flex flex-row items-center py-6 border-b" }, guts));
    }
    else {
        return (React.createElement("a", { className: "flex flex-row items-center py-6 border-b", href: link }, guts));
    }
};
exports.ActivityItem = ActivityItem;
const ActivityIcon = ({ icon, backgroundColor = "bg-canvas-600" }) => {
    return (React.createElement("div", { className: `h-8 w-8 flex flex-row items-center justify-center rounded ${backgroundColor}` }, icon));
};
exports.ActivityIcon = ActivityIcon;
const getActivityPresentation = (activity) => {
    const coworkerUpdateAction = {
        quoteText: React.createElement("span", null, "TODO"),
        title: "Worker row edited",
        icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "edit" }) }))
    };
    const inviteLinkUpdatedAction = {
        quoteText: React.createElement("span", null, "TODO"),
        title: "Invite link updated",
        icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "link" }) }))
    };
    switch (activity.verb) {
        case frank_types_1.FrankBackendTypes.ActivityVerb.ActionUpvote:
            return {
                title: "Action upvoted",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "arrow_upward" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.ActionDownvote:
            return {
                title: "Action downvoted",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "arrow_downward" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.AcceptNoMgmtInvite:
            return {
                title: "Agreed to never invite management",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "check" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.AddedRecipientForPetition:
            return {
                title: "Petition recipient added",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "settings" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.RemovedRecipientForPetition:
            return {
                title: "Petition recipient removed",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "settings" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdatedRecipientForPetition:
            return {
                title: "Petition recipient updated",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "settings" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CampaignComment:
            return {
                title: "Petition commented on",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "comment" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CampaignCommentReply:
            return {
                title: "Petition comment replied to",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "reply" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.ReplyToPost:
            return {
                title: "Post replied to",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "reply" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.SignCampaign:
            return {
                title: "Signature added",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(FeatherIcons_1.FeatherIcon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "PenTool" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.UnsignCampaign:
            return {
                title: "Signature removed",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "remove_circle_outline" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdateCampaign: // this is supposed to be "campaign information edited"
            return {
                title: "Campaign information edited",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "settings" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.AddCollaborator:
            return {
                title: "Invited to co-organize",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "people" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CampaignPageview:
            return {
                title: "Campaign page viewed",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "remove_red_eye" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.ProfilePageview:
            return {
                title: "Worker profile viewed",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "remove_red_eye" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerArchive:
            return {
                title: "Worker row archived",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "archive" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerUnarchive:
            return {
                title: "Worker row unarchived",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "unarchive" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetTitle:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetCustomColumn:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetEmail:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetEmploymentType:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetName:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerSetNotes:
            return coworkerUpdateAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.Mention:
            return {
                title: "New mention",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "alternate_email" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CampaignPublish:
            return {
                title: "New campaign published",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-green-800", icon: React.createElement(FeatherIcons_1.FeatherIcon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "FileText" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CampaignArchive:
            return {
                title: "Campaign archived",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(FeatherIcons_1.FeatherIcon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "FileText" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.TopicPublish:
            return {
                title: "New topic published",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-green-800", icon: React.createElement(FeatherIcons_1.FeatherIcon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "Hash" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.TopicArchive:
            return {
                title: "Topic archived",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(FeatherIcons_1.FeatherIcon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "Hash" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.EnteredWaitingRoom:
            return {
                title: "New member applied",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-gold-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-gold-300", icon: "person_add" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.JoinGroup:
            return {
                title: "New member joined",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-green-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "person_add" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CoworkerFlag:
            return {
                title: "Worker flagged",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "flag" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CreateInviteLink:
            return {
                title: "Invite link created",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "insert_link" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.CreateGroup:
            return {
                title: "Group created",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-green-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "grade" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdateGroup:
            return {
                title: "Group settings updated",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "settings" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdatedExpirationOnInvite:
            return inviteLinkUpdatedAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdatedMaxSeatsOnInvite:
            return inviteLinkUpdatedAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.UpdatedPassphraseOnInvite:
            return inviteLinkUpdatedAction;
        case frank_types_1.FrankBackendTypes.ActivityVerb.DisableInvite:
            return {
                title: "Invite link disabled",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "link_off" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.EnableInvite:
            return {
                title: "Link enabled",
                icon: (React.createElement(exports.ActivityIcon, { icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-canvas-300", icon: "link" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.PollPublish:
            return {
                title: "New poll published",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-green-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-green-300", icon: "insert_chart" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.PollExpiring:
            return {
                title: "Poll expiring soon",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-gold-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-gold-300", icon: "insert_chart" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.PollExpired:
            return {
                title: "Poll expired",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "insert_chart" }) }))
            };
        case frank_types_1.FrankBackendTypes.ActivityVerb.BulkInvite:
            return {
                title: "Invite sent",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "mail_outline" }) }))
            };
        default:
            console.log("unrecognized type", activity.verb);
            return {
                title: "Unrecognized event",
                icon: (React.createElement(exports.ActivityIcon, { backgroundColor: "bg-frank-red-800", icon: React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, classNames: "text-frank-red-300", icon: "error" }) }))
            };
    }
};
const ActivityFeed = ({ activities }) => {
    return (React.createElement("div", { className: "activity" }, activities.map(activity => {
        const presentation = getActivityPresentation(activity);
        const keys = Object.keys(activity);
        // @ts-expect-error
        const isSeen = keys.includes("isSeen") ? activity.isSeen : true;
        return (React.createElement(exports.ActivityItem, { key: activity.id, title: presentation.title, createdAt: activity.time, icon: presentation.icon, link: activity.link, isSeen: isSeen },
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: activity.htmlDescription || ""
                } })));
    })));
};
exports.ActivityFeed = ActivityFeed;
