"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimestampText = exports.isToday = void 0;
const React = __importStar(require("react"));
const dayOptions = {
    weekday: "long",
    month: "long",
    day: "numeric"
};
const timeOptions = {
    hour: "numeric",
    minute: "numeric"
};
const dayDateFormatter = new Intl.DateTimeFormat("en-us", dayOptions);
const timeDateFormatter = new Intl.DateTimeFormat("en-us", timeOptions);
const isToday = (timeStamp) => {
    const compareDate = new Date(timeStamp);
    const now = new Date();
    if (compareDate.getDate() === now.getDate()) {
        if (compareDate.getMonth() === now.getMonth()) {
            if (compareDate.getFullYear() === now.getFullYear()) {
                return true;
            }
        }
    }
    return false;
};
exports.isToday = isToday;
function TimestampText({ date, format = "stamp" }) {
    if (format === "human") {
        return (React.createElement("span", { className: "whitespace-nowrap" },
            "on ",
            dayDateFormatter.format(new Date(date)),
            " at",
            " ",
            timeDateFormatter.format(new Date(date))));
    }
    return (React.createElement("span", { className: "whitespace-nowrap" },
        exports.isToday(date) ? "Today" : dayDateFormatter.format(new Date(date)),
        " \u2022",
        " ",
        timeDateFormatter.format(new Date(date))));
}
exports.TimestampText = TimestampText;
