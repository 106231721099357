"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = exports.Tab = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const Select_1 = require("./forms/Select");
const Tab = ({ active, to, label }) => {
    return (React.createElement(react_router_dom_1.Link, { to: to, className: `transition-colors border-transparent ${active ? "text-white" : "text-canvas-400 hover:text-canvas-300"}  whitespace-nowrap py-4 px-1 ${active ? "border-white" : "hover:border-b-2 hover:border-canvas-600"}  border-b-2 font-medium text-sm` }, label));
};
exports.Tab = Tab;
const Tabs = ({ children }) => {
    const { push } = react_router_dom_1.useHistory();
    return (React.createElement("div", null,
        React.createElement("div", { className: "sm:hidden" },
            React.createElement("label", { htmlFor: "tabs", className: "sr-only" }, "Select a tab"),
            React.createElement(Select_1.Select, { onChange: event => push(event.currentTarget.value) }, React.Children.map(children, tab => (React.createElement("option", { selected: tab.props.active, value: tab.props.to }, tab.props.label))))),
        React.createElement("div", { className: "hidden sm:block" },
            React.createElement("div", { className: "border-b" },
                React.createElement("nav", { className: "-mb-px flex space-x-8", "aria-label": "Tabs" }, children)))));
};
exports.Tabs = Tabs;
