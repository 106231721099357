import * as React from "react";
import ExpandablePendingMemberCard from "../components/ExpandablePendingMemberCard";
import usePendingMembersListData from "../dataAccess/queries/usePendingMembersListData";
import useCoworkerTrust from "../../Coworkers/dataAccess/mutations/useCoworkerTrust";
import useQuery from "../../hooks/useQuery";
import { Delay, PulseMessage } from "@get-frank-eng/design-system";
import useRecentlyReviewedMembersListData from "../dataAccess/queries/useRecentlyReviewedMemberData";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { FrankBackendTypes } from "frank-types";

export default function PendingMemberList() {
  const {
    pendingMemberData,
    loadingPendingMembers,
    errorPendingMembers,
    fetchMorePendingMembers,
    refetchPendingMembers,
  } = usePendingMembersListData({ perPage: 50 });

  const {
    recentlyReviewedData,
    recentlyReviewedLoading,
    recentlyReviewedError,
    fetchMoreRecentlyReviewedMembers,
    refetchRecentlyReviewed,
  } = useRecentlyReviewedMembersListData({ perPage: 50 });

  const qs = useQuery();

  const [openMemberId, setOpenMemberId] = React.useState<
    FrankBackendTypes.Coworker["id"]
  >(null);
  React.useEffect(() => {
    const pendingMemberId = qs.get("id");

    if (openMemberId && openMemberId !== pendingMemberId) {
      return;
    }
    if (pendingMemberId) {
      setOpenMemberId(pendingMemberId);
    }
  }, [qs, setOpenMemberId, openMemberId]);

  const {
    coworkerTrustVote,
    loadingCoworkerTrustVote,
    removeCoworkerTrustVote,
  } = useCoworkerTrust();

  const refetchAll = async () => {
    await Promise.all([refetchPendingMembers(), refetchRecentlyReviewed()]);
  };

  if (errorPendingMembers || recentlyReviewedError) {
    throw new Error(errorPendingMembers.message);
  }

  if (loadingPendingMembers || recentlyReviewedLoading) {
    return (
      <div className="space-y-3">
        <div className="t-large plus">Membership requests</div>
        <Delay delay={500}>
          <div className="space-y-6">
            <PulseMessage />
            <PulseMessage />
            <PulseMessage />
            <PulseMessage />
          </div>
        </Delay>
      </div>
    );
  }

  return (
    <div className="flex-grow">
      <div className="pb-12 border-b sm:border-0">
        <div className="t-large plus">Membership requests</div>
        {pendingMemberData?.objects.length ? (
          <div className="grid grid-cols-1 gap-2 place-items-start">
            <Pager<FrankBackendTypes.Coworker>
              paginationStrategy={PaginationStrategy.SHOW_MORE}
              onShowMore={fetchMorePendingMembers}
              pagination={pendingMemberData}
              showAllText="Show more"
            >
              {pendingMemberData.objects.map((pendingMember) => (
                <ExpandablePendingMemberCard
                  refetch={refetchAll}
                  isExpanded={
                    openMemberId === pendingMember.id ||
                    openMemberId === pendingMember.user?.id
                  }
                  setOpenMemberId={setOpenMemberId}
                  coworkerTrustVote={coworkerTrustVote}
                  removeCoworkerTrustVote={removeCoworkerTrustVote}
                  loadingCoworkerTrustVote={loadingCoworkerTrustVote}
                  key={pendingMember.id}
                  pendingMember={pendingMember}
                  canExpand
                />
              ))}
            </Pager>
          </div>
        ) : (
          <p className="t-regular text-canvas-400">
            There are no pending membership requests at this time
          </p>
        )}
      </div>
      <div className="py-12 sm:pb-24 sm:pt-0 border-b sm:border-0">
        <div className="t-large plus">Recently reviewed</div>
        {recentlyReviewedData.objects.length ? (
          <div className="grid grid-cols-1 gap-2 place-items-start">
            <Pager<FrankBackendTypes.Coworker>
              paginationStrategy={PaginationStrategy.SHOW_MORE}
              onShowMore={fetchMoreRecentlyReviewedMembers}
              pagination={recentlyReviewedData}
              showAllText="Show more"
            >
              {recentlyReviewedData.objects.map((recentlyReviewedMember) => {
                const isMember =
                  recentlyReviewedMember.status ===
                  FrankBackendTypes.CoworkerStatus.Member;
                return (
                  <ExpandablePendingMemberCard
                    isExpanded={
                      openMemberId === recentlyReviewedMember.id ||
                      openMemberId === recentlyReviewedMember.user?.id
                    }
                    setOpenMemberId={setOpenMemberId}
                    coworkerTrustVote={coworkerTrustVote}
                    removeCoworkerTrustVote={removeCoworkerTrustVote}
                    loadingCoworkerTrustVote={loadingCoworkerTrustVote}
                    key={recentlyReviewedMember.id}
                    pendingMember={recentlyReviewedMember}
                    refetch={refetchAll}
                    canExpand={!isMember}
                    to={
                      isMember
                        ? `/users/${recentlyReviewedMember.user.id}`
                        : null
                    }
                  />
                );
              })}
            </Pager>
          </div>
        ) : (
          <p className="t-regular text-canvas-400">
            You haven't reviewed any workers in the last week
          </p>
        )}
      </div>
    </div>
  );
}
