import {
  Icon,
  MessageCard,
  ScreenSizes,
  Sizes,
} from "@get-frank-eng/design-system";
import { ChatTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";

export const useConfirmAndDeleteMessage = ({
  screenSize,
  getMentionHref,
  currentUser,
  deleteMessage,
}: {
  screenSize: ScreenSizes;
  getMentionHref: (mention: any) => string;
  currentUser: ChatTypes.User;
  deleteMessage: (messageId: string) => void;
}) => {
  const { openConfirmationModal } = useModals();

  return React.useCallback(
    async (message: ChatTypes.Message) => {
      const confirmed = await openConfirmationModal({
        actionText: "Delete",
        title: "Delete Message",
        bodyText: (
          <>
            <p>
              Are you sure you want to delete this message? This cannot be
              undone. Your message will be deleted forever, and replaced with a
              "message deleted" notice to the group.
            </p>

            <div className=" mt-3 space-y-3">
              <div className="border rounded">
                <MessageCard
                  screenSize={screenSize}
                  getMentionHref={getMentionHref}
                  deleteMessage={() => Promise.resolve()}
                  baseUrl=""
                  lastMessage={{ author: {} } as ChatTypes.Message}
                  nonInteractive
                  setMessageEditing={() => {}}
                  message={message}
                  loadingReaction={false}
                  reactToMessage={null}
                  renderRichObjects={() => <></>}
                  currentUser={currentUser}
                  showReplies={false}
                />
              </div>

              <Icon icon="swap_vert" size={Sizes.LG} />

              <div className="border rounded">
                <MessageCard
                  screenSize={screenSize}
                  getMentionHref={getMentionHref}
                  deleteMessage={() => Promise.resolve()}
                  lastMessage={{ author: {} } as ChatTypes.Message}
                  baseUrl=""
                  nonInteractive
                  setMessageEditing={null}
                  message={{ ...message, type: ChatTypes.MessageType.Deleted }}
                  loadingReaction={null}
                  reactToMessage={null}
                  renderRichObjects={() => <></>}
                  currentUser={currentUser}
                  showReplies={false}
                />
              </div>
            </div>
          </>
        ),
      });

      if (!confirmed) {
        return;
      }
      await deleteMessage(message.id);
    },
    [
      currentUser,
      deleteMessage,
      getMentionHref,
      openConfirmationModal,
      screenSize,
    ]
  );
};
