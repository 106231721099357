import * as React from "react";
import { useForm } from "react-hook-form";
import useEnterPassphrase from "../dataAccess/mutations/useEnterPassphrase";
import { useModals } from "../../Modals";
import {
  Button,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

interface FormData {
  passphrase: string;
}

export default function EnterPassphraseModal({
  onboardingId,
  afterSubmit,
}: {
  onboardingId: FrankBackendTypes.Onboarding["id"];
  afterSubmit: () => any;
}) {
  const { enterPassphrase, loading } = useEnterPassphrase({ onboardingId });

  const { closeModal } = useModals();

  const { handleSubmit, register, errors } = useForm<FormData>();
  const [passphraseIncorrect, setPassphraseIncorrect] = React.useState(false);

  const submit = React.useCallback(
    async (fd: FormData) => {
      const isCorrect = await enterPassphrase(fd.passphrase);
      if (isCorrect) {
        await afterSubmit();
        closeModal();
      } else {
        setPassphraseIncorrect(true);
      }
    },
    [enterPassphrase, afterSubmit, setPassphraseIncorrect, closeModal]
  );

  const hasError = errors.passphrase || passphraseIncorrect;

  return (
    <>
      <ModalHeader title="Enter group passcode" />
      <ModalBody>
        <form id="passphrase-form" noValidate onSubmit={handleSubmit(submit)}>
          <p>
            A member from the group is requiring invitees to enter a passcode to
            be admitted into the group. Type the passcode below.
          </p>
          <FormGroup label="Group passcode" name="passphrase" id="passphrase">
            <Input
              errorText={
                hasError && (
                  <>
                    {errors.passphrase && "Passphrase is required"}
                    {passphraseIncorrect && "Incorrect Passphrase"}
                  </>
                )
              }
              register={register}
              registerArgs={{ required: true }}
              type="text"
              name="passphrase"
              dataCy="passphrase"
            />
          </FormGroup>
          <ModalFooter>
            <Button form="passphrase-form" type="submit" loading={loading}>
              Enter
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
}
