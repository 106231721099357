import { useMutation, gql } from "@apollo/client";
import { Button, FormGroup, Input, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import createPersistedState from "use-persisted-state";
import AddPhoto from "./components/AddPhoto";
import { DispatcherAPI } from "./state/dispatcher";
import { CacheAPI, CachedFormState } from "./state/onboardingCache";
import { OnboardingStep } from "./state/state";

const useGroupsLocalStorageState = createPersistedState("groupId");

const TELL_US_ABOUT_YOURSELF = gql`
  mutation TellUsAboutYourself($input: TellUsMoreAboutYourselfDTO!) {
    tellUsMoreAboutYourself(input: $input) {
      nothing
    }
  }
`;

interface FormData {
  profileName: string;
  money: string;
}

function useSubmit({
  onSubmit,
  setServerError,
  setLoading,
}: {
  onSubmit: any;
  setServerError: DispatcherAPI["setServerError"];
  setLoading: DispatcherAPI["setLoading"];
}) {
  const [groupId] = useGroupsLocalStorageState<string | null>(null);
  const [invokeTellUs, { error: saveError }] = useMutation<
    any,
    FrankBackendTypes.MutationTellUsMoreAboutYourselfArgs
  >(TELL_US_ABOUT_YOURSELF);

  const submit = React.useCallback(
    async (formdata: FormData) => {
      setLoading(OnboardingStep.TELL_US_ABOUT_YOURSELF);

      try {
        await invokeTellUs({
          variables: {
            input: {
              profileName: formdata.profileName,
              groupId,
            },
          },
        });
        onSubmit(formdata);
      } catch (e) {
        setServerError("tellUsAboutYourself", "tell us error");
        throw e;
      }
    },
    [setLoading, onSubmit, groupId, setServerError, invokeTellUs]
  );

  return { submit, saveError };
}

const TellUsAboutYourself = ({
  onSubmit,
  setCache,
  cache,
  setLoading,
  setServerError,
  isLoading,
}: {
  onSubmit: any;
  cache: CachedFormState;
  setCache: CacheAPI["setCache"];
  setLoading: DispatcherAPI["setLoading"];
  setServerError: DispatcherAPI["setServerError"];
  isLoading: boolean;
  isSubmitted: boolean;
}) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();

  const profilePicRef = React.useRef(null);

  React.useEffect(() => {
    setValue("avatarComplete", !!cache["tellUs.profilePictureUrl"]);
  }, [setValue, cache]);

  const { submit } = useSubmit({
    onSubmit,
    setServerError,
    setLoading,
  });

  const fieldsDisabled = isLoading;

  return (
    <div id="tell-us-about-yourself" className="mt-12">
      <form className="text-left space-y-8" onSubmit={handleSubmit(submit)}>
        <div ref={profilePicRef} className="flex items-center flex-col">
          <div className="t-regular plus">Add your profile photo.</div>
          <div className="t-small">
            Your coworkers should recognize you in the photo.
          </div>
          <AddPhoto
            defaultUrl={cache["tellUs.profilePictureUrl"]}
            onUpload={(err, url) => {
              console.log(err, url);
              if (err) {
                return;
              }
              setCache("tellUs.profilePictureUrl", url);
            }}
          />
        </div>
        <FormGroup name="profileName" label="Full name" id="profile-name">
          <Input
            errorText={
              errors.profileName && (
                <>
                  {errors.profileName.type === "required" &&
                    "Profile name is required to use Frank"}
                  {errors.profileName.type === "maxLength" &&
                    "Profile name is too long"}
                </>
              )
            }
            helpText="What is the name you use at work? This will show up on your profile"
            autoFocus
            type="text"
            disabled={fieldsDisabled}
            onBlur={(e) =>
              setCache(
                "tellUs.profileName",
                (e.target as HTMLInputElement).value
              )
            }
            defaultValue={cache["tellUs.profileName"]}
            register={register}
            registerArgs={{ required: true, maxLength: 70 }}
            dataCy="profile-name"
          />
        </FormGroup>

        <div className="text-center">
          <Button
            type="submit"
            buttonStyle="brand"
            loading={isLoading}
            dataCy="submit-tell-us-about-yourself"
            size={Sizes.LG}
            full
          >
            Continue to Step 3
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TellUsAboutYourself;
