"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordStrengthMeter = exports.PasswordStrengthMeterSegments = exports.PasswordRequirements = exports.passwordStrengthDescription = void 0;
const React = __importStar(require("react"));
exports.passwordStrengthDescription = {
    0: "Try adding an unusual word",
    1: "Getting there.",
    2: "Getting there.",
    3: "Good!",
    4: "Great!"
};
const PasswordRequirements = () => (React.createElement("div", { className: "t-mini text-canvas-400" },
    React.createElement("div", null, "Must be at least 8 characters long."),
    React.createElement("div", null, "Avoid repeating characters (e.g. aaaaa) or common words like \"password\" or \"p@$$w0rd.\"")));
exports.PasswordRequirements = PasswordRequirements;
const passwordStrengthColors = {
    0: ["bg-canvas-400", "bg-canvas-400", "bg-canvas-400", "bg-canvas-400"],
    1: ["bg-frank-red-500", "bg-canvas-400", "bg-canvas-400", "bg-canvas-400"],
    2: ["bg-frank-red-500", "bg-brand-500", "bg-canvas-400", "bg-canvas-400"],
    3: [
        "bg-frank-green-500",
        "bg-frank-green-500",
        "bg-frank-green-500",
        "bg-canvas-400"
    ],
    4: [
        "bg-frank-green-500",
        "bg-frank-green-500",
        "bg-frank-green-500",
        "bg-frank-green-500"
    ]
};
const StrengthSegment = ({ color }) => (React.createElement("div", { className: `h-1 flex-grow-1 rounded ${color}` }));
const PasswordStrengthMeterSegments = ({ strength }) => {
    const colors = passwordStrengthColors[strength];
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex flex-row space-x-2" }, colors.map(c => (React.createElement(StrengthSegment, { color: c, key: Math.random() }))))));
};
exports.PasswordStrengthMeterSegments = PasswordStrengthMeterSegments;
const PasswordStrengthMeter = ({ passwordStrength, password }) => {
    return (React.createElement("div", { className: "space-y-2" },
        React.createElement("div", { className: "max-w-xs" },
            React.createElement(exports.PasswordStrengthMeterSegments, { strength: passwordStrength > 0 && (password === null || password === void 0 ? void 0 : password.length) ? passwordStrength : 0 })),
        !!password && (React.createElement("div", { className: `t-mini ${passwordStrength > 2 ? "text-frank-green-400" : "text-frank-red-400"}` }, exports.passwordStrengthDescription[passwordStrength] ||
            exports.passwordStrengthDescription[0])),
        React.createElement(exports.PasswordRequirements, null)));
};
exports.PasswordStrengthMeter = PasswordStrengthMeter;
