import { gql } from "@apollo/client";
import { Uppload, en, Local, Preview, Crop, xhrUploader } from "uppload";
import client from "../gqlClient";
import generateAuthHeader from "./generateAuthHeader";

en.services.local.button = "Upload a Photo";

async function setProfileAttachmentId(id: string) {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation {
        setProfilePic(attachmentId: "${id}") {
          nothing
        }
      }
    `,
  });
  if (errors && errors.length) {
    throw errors[0];
  }
  return data;
}

const UploadAvatar = async (
  callback: (err: Error | null, url?: string) => void,
  setGroupAttachmentId?: ({ attachmentId }) => void
) => {
  const uppload = new Uppload({
    lang: en,
    maxSize: [250, 250],
    uploader: xhrUploader({
      responseKey: "file",
      endpoint: `${process.env.REACT_APP_API_URL}/attachments`,
      settingsFunction(xmlHttp) {
        xmlHttp.setRequestHeader("authorization", generateAuthHeader());
      },
      responseFunction(responseText: string): string {
        return responseText;
      },
    }),
  });
  uppload.use([
    // @ts-ignore
    new Local(),
    new Crop({ aspectRatio: 1 }),
    new Preview(),
  ]);
  uppload.open();
  uppload.on("upload", async (respText) => {
    const response = JSON.parse(respText);
    try {
      if (setGroupAttachmentId) {
        await setGroupAttachmentId({ attachmentId: response.attachmentId });
      } else {
        await setProfileAttachmentId(response.attachmentId);
      }
    } catch (e) {
      return callback(e, null);
    }
    callback(null, response.file);
  });
  uppload.on("error", (err) => callback(err));
};

export default UploadAvatar;
