import { ApolloProvider } from "@apollo/client";
import "typeface-lora";
import * as React from "react";

import * as ReactDOM from "react-dom";
import "@get-frank-eng/design-system/dist/tailwind.css";
import "@get-frank-eng/design-system/dist/colorVars.css";
import "@get-frank-eng/design-system/dist/typography.css";
import "@get-frank-eng/design-system/dist/mentions.css";
import "@get-frank-eng/design-system/dist/coworkerTable.css";
import "@get-frank-eng/design-system/dist/segmentedControl.css";
import "@get-frank-eng/design-system/dist/upploadCustomization.css";

import "./index.css";
import { BrowserRouter } from "react-router-dom";
import client from "./gqlClient";
import Routes from "./Routes";
import { ModalProvider } from "./Modals";
import { AuthProvider } from "./Auth/AuthState";
import { LocationHistoryProvider } from "./hooks/useLocationHistory";
import ErrorBoundary from "./components/ErrorBoundary";
import FullPageError from "./FullPageError";
import {
  LayoutProvider,
  ResponsiveProvider,
  ToastProvider,
  useBreakpoint,
} from "@get-frank-eng/design-system";
import { ChannelsProvider, DraftProvider } from "@get-frank-eng/chat-client";
import configureChatBackend from "./Chat/util/configure";
import { NavigationStateProvider } from "./Navigation/hooks/useNavigationState";
import SentryClient from "./sentryClient";

SentryClient.init();

configureChatBackend();

const App = () => {
  const screenSize = useBreakpoint();

  return (
    // @ts-ignore
    <BrowserRouter>
      <ErrorBoundary customUI={<FullPageError />}>
        <LocationHistoryProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <NavigationStateProvider>
                <ResponsiveProvider screenSize={screenSize}>
                  <LayoutProvider>
                    <ToastProvider>
                      <ModalProvider>
                        <ChannelsProvider>
                          <DraftProvider>
                            <Routes />
                          </DraftProvider>
                        </ChannelsProvider>
                      </ModalProvider>
                    </ToastProvider>
                  </LayoutProvider>
                </ResponsiveProvider>
              </NavigationStateProvider>
            </AuthProvider>
          </ApolloProvider>
        </LocationHistoryProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const root = document.getElementById("root") as HTMLElement;
ReactDOM.render(<App />, root);
