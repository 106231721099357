import * as React from "react";
import Trustworthiness from "../vouchingSections/Trustworthiness";
import Eligibility from "../vouchingSections/Eligibility";
import Authenticity from "../vouchingSections/Authenticity";
import {
  useToaster,
  Button,
  Intent,
  Sizes,
  PendingMemberCard,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useModals } from "../../Modals";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useHistory } from "react-router";
import useQuery from "../../hooks/useQuery";
import { Collapse } from "react-collapse";

export default function ExpandablePendingMemberCard({
  pendingMember,
  loadingCoworkerTrustVote,
  setOpenMemberId,
  isExpanded,
  coworkerTrustVote,
  removeCoworkerTrustVote,
  refetch,
  canExpand,
  to,
}: {
  pendingMember: FrankBackendTypes.Coworker;
  loadingCoworkerTrustVote: boolean;
  setOpenMemberId: (id: FrankBackendTypes.Coworker["id"]) => void;
  isExpanded: boolean;
  coworkerTrustVote: (config: {
    coworkerId: FrankBackendTypes.Coworker["id"];
    trust: number;
    rejectionReason?: FrankBackendTypes.CoworkerRejectionReason;
    rejectionDetail?: string;
  }) => Promise<void>;
  removeCoworkerTrustVote: (config: {
    coworkerId: FrankBackendTypes.Coworker["id"];
  }) => Promise<void>;
  refetch?: () => void;
  canExpand: boolean;
  to?: string;
}) {
  const toaster = useToaster();
  const upvote = React.useCallback(async () => {
    await coworkerTrustVote({ coworkerId: pendingMember.id, trust: 1 });
    toaster.addToast({
      intent: Intent.SUCCESS,
      children: `You voted to approve ${pendingMember.name}`,
      action: {
        label: "Undo",
        onClick: () => {
          removeCoworkerTrustVote({ coworkerId: pendingMember.id });
        },
      },
    });
    await refetch();
  }, [
    coworkerTrustVote,
    pendingMember,
    toaster,
    removeCoworkerTrustVote,
    refetch,
  ]);

  const downvote = React.useCallback(
    async ({
      reason,
      rejectionDetail,
    }: {
      reason?: FrankBackendTypes.CoworkerRejectionReason;
      rejectionDetail?: string;
    }) => {
      await coworkerTrustVote({
        coworkerId: pendingMember.id,
        trust: -1,
        rejectionReason: reason,
        rejectionDetail,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: `You voted not to approve ${pendingMember.name}`,
      });
      await refetch();
    },
    [coworkerTrustVote, pendingMember, toaster, refetch]
  );

  const qs = useQuery();
  const history = useHistory();

  const { setModal } = useModals();

  const { currentGroup } = useCurrentUserData();

  const onClick = () => {
    if (!canExpand) {
      return;
    }
    if (isExpanded) {
      setOpenMemberId(null);
      if (qs.get("id")) {
        history.push("/coworkers");
      }
    } else {
      setOpenMemberId(pendingMember.id);
    }
  };

  return (
    <PendingMemberCard
      onClick={onClick}
      pendingMember={pendingMember}
      dataCy={`pending-member-${pendingMember.id}`}
    >
      <Collapse isOpened={isExpanded}>
        {isExpanded && (
          <div className="m-4 overflow-auto">
            <Eligibility pendingMember={pendingMember} />

            <Authenticity pendingMember={pendingMember} />

            <Trustworthiness pendingMember={pendingMember} />

            {pendingMember.canVoteOnTrust && (
              <div className="flex flex-row items-center">
                <Button
                  dataCy="vote-to-deny"
                  type="button"
                  size={Sizes.SM}
                  onClick={() => {
                    setModal({
                      type: "denyPendingMemberModal",
                      props: {
                        downvote,
                        pendingMember,
                        company: currentGroup.name,
                      },
                      afterClose: () => setOpenMemberId(null),
                    });
                  }}
                  className="mr-2"
                  loading={loadingCoworkerTrustVote}
                  buttonStyle="secondary"
                >
                  Vote to deny
                </Button>
                <Button
                  dataCy="vote-to-admit"
                  type="button"
                  size={Sizes.SM}
                  loading={loadingCoworkerTrustVote}
                  onClick={() => {
                    setModal({
                      type: "reviewPendingMemberModal",
                      props: {
                        pendingMember,
                        groupName: currentGroup.name,
                        upvote,
                      },
                      afterClose: () => setOpenMemberId(null),
                    });
                  }}
                  buttonStyle="brand"
                >
                  Vote to admit
                </Button>
              </div>
            )}
          </div>
        )}
      </Collapse>
    </PendingMemberCard>
  );
}
