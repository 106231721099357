import {
  Accordion,
  Button,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import { useNavigationState } from "../../Navigation/hooks/useNavigationState";
import { useCurrentTourContext } from "../../Tour/CurrentTourProvider";
import useRetakeTour from "../../Tour/dataAccess/useRetakeTour";

const CoworkersFAQModal = () => {
  const { retakeTour } = useRetakeTour();
  const { setRetakingTour, setCurrentTour } = useCurrentTourContext();
  const { refetch } = useNavigationState();
  const { closeModal } = useModals();

  return (
    <>
      <ModalHeader title="Help" />
      <ModalBody>
        <>
          <h2 className="t-large plus">FAQ</h2>

          <Accordion title="How do I use this page?">
            <p className="t-small">
              The Coworkers page helps you organize your group. You can use it
              to create list of potential group members, manage invitations,
              authenticate emails, and vote on trust. You can start in the
              Unverified Coworkers list to enter your coworkers names and
              emails, send invitations, and allow other group members to vote on
              trust. You can use the Verified Coworkers list to monitor your
              group size and power, check who hasn't joined Frank yet, and
              review trusted coworkers.
            </p>
          </Accordion>

          <Accordion title="What does it mean to trust someone?">
            <p className="t-small">
              Group members have control over who is or isn't permitted to join
              Frank. Your vote to trust or not trust someone is your expression
              of whether or not you think they should be in your group. Before
              you vote, you should consider how helpful this person is likely to
              be to your group's efforts.
            </p>
          </Accordion>

          <Accordion title="How does vouching work?">
            <p className="t-small">
              In order to join Frank, you need to be eligible, authentic, and
              trusted. This requires that at least two group members approve
              each new membership request. Members are also required to verify
              their emails and certify that they are not a member of management.
              If you see a member you believe does not meet any one of these
              three conditions, you should flag their account.
            </p>
          </Accordion>
          <div className="pt-4">
            <Button
              onClick={() => {
                setRetakingTour(true);
                retakeTour(FrankBackendTypes.TourType.Coworkers);
                setCurrentTour(FrankBackendTypes.TourType.Welcome);
                refetch();
                closeModal();
              }}
            >
              Show product tour
            </Button>
          </div>
        </>
      </ModalBody>
    </>
  );
};

export default CoworkersFAQModal;
