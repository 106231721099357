import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router";
import AuthN from "./AuthN";
import usePasswordStrength from "./usePasswordStrength";
import useSession from "./useSession";
import {
  InlineAlert,
  Button,
  FormGroup,
  Intent,
  PasswordStrengthMeter,
  Password,
} from "@get-frank-eng/design-system";

interface FormData {
  password: string;
  confirmPassword: string;
}

const PasswordReset = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [serverErr, setServerErr] = React.useState<Error | null>();
  const history = useHistory();
  const goToHome = React.useCallback(() => {
    history.push("/");
  }, [history]);
  const { login } = useSession({
    afterLogin: goToHome,
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
  } = useForm<FormData>();

  const password = watch("password");
  const passwordStrength = usePasswordStrength(password);

  const showPasswordError =
    formState.dirtyFields.has("password") &&
    passwordStrength > -1 &&
    passwordStrength < 3;

  const errorsPresent = !!Object.keys(errors).length;

  const submitHandler = React.useCallback(
    async (data: FormData) => {
      setLoading(true);
      await AuthN.submitNewPasswordAfterReset(token, data.password);
      try {
        await login(email, data.password);
        setLoading(false);
      } catch (e) {
        setServerErr(e);
        setLoading(false);
        throw new Error(JSON.stringify(e));
      }
    },
    [token, login, setLoading, setServerErr, email]
  );

  return (
    <div className="w-100 flex-1 flex flex-col mt-6 md:mt-64">
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="self-center md:shadow-lg rounded container mx-auto p-6 md:p-8 md:w-2/3 lg:w-1/3 bg-canvas-700"
      >
        {serverErr && (
          <div className="pb-4">
            <InlineAlert intent={Intent.FAILURE} title="Authentication Error">
              An error occurred resetting the password
            </InlineAlert>
          </div>
        )}
        <FormGroup label="Password" name="password" id="password">
          <Password
            disabled={loading}
            registerArgs={{ required: true, minLength: 6, maxLength: 255 }}
            register={register}
          />
        </FormGroup>
        <PasswordStrengthMeter
          passwordStrength={passwordStrength}
          password={password}
        />
        <div className="flex items-center justify-between pt-3">
          <Button
            dataCy="reset-password"
            loading={loading}
            disabled={
              showPasswordError ||
              password?.length < 6 ||
              !password ||
              errorsPresent
            }
          >
            Set new Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
