"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LargePulseCard = exports.PulseCard = exports.PulseActivity = exports.PulseMessage = exports.PulseLines = exports.TitlePulseLine = exports.PulseLine = void 0;
const React = __importStar(require("react"));
const Card_1 = require("./Card");
const PulseLine = ({ className }) => (React.createElement("div", { className: `bg-canvas-400 animate-pulse h-4 rounded-sm ${className}` }));
exports.PulseLine = PulseLine;
const TitlePulseLine = () => React.createElement(exports.PulseLine, { className: "w-1/3" });
exports.TitlePulseLine = TitlePulseLine;
const PulseLines = ({ n, title }) => (React.createElement("div", { className: "flex flex-col space-y-2" },
    title && React.createElement(exports.TitlePulseLine, null),
    new Array(n).fill(null).map((_, i) => (React.createElement(exports.PulseLine, { key: i })))));
exports.PulseLines = PulseLines;
const PulseMessage = () => {
    return (React.createElement("div", { className: "flex flex-1 flex-row space-x-4 items-start" },
        React.createElement("div", { className: "rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse" }),
        React.createElement("div", { className: "flex-grow" },
            React.createElement(exports.PulseLines, { n: 3 }))));
};
exports.PulseMessage = PulseMessage;
const PulseActivity = () => {
    return (React.createElement("div", { className: "flex flex-row space-x-4 items-start" },
        React.createElement("div", { className: "flex-grow" },
            React.createElement(exports.PulseLines, { n: 3 })),
        React.createElement("div", { className: "rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse" })));
};
exports.PulseActivity = PulseActivity;
const PulseCard = ({ lines = 3, title = true }) => (React.createElement(Card_1.Card, { className: "p-4" },
    React.createElement(exports.PulseLines, { title: title, n: lines })));
exports.PulseCard = PulseCard;
const LargePulseCard = () => (React.createElement(Card_1.Card, { className: "p-4 h-64 flex flex-col" },
    React.createElement(exports.PulseLines, { title: true, n: 3 }),
    React.createElement("div", { className: "flex-grow" }),
    React.createElement(exports.PulseLine, null)));
exports.LargePulseCard = LargePulseCard;
