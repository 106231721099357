const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;

export enum DateIntervals {
  ONE_HOUR = oneHour,
  ONE_DAY = oneDay,
  THREE_DAYS = oneDay * 3,
  ONE_WEEK = oneDay * 7,
}

export const generateFutureDate = (start: Date, interval: number): Date => {
  return new Date(start.getTime() + interval);
};
