/* eslint-disable react/destructuring-assignment */
import * as React from "react";

class ErrorBoundary extends React.Component<
  { customUI?: React.ReactElement },
  { hasError: boolean; error: any }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      console.log("an unrecoverable error has occurred", this.state.error);
      if (this.props.customUI) {
        return this.props.customUI;
      }

      return (
        <p className="text-brand-600">
          Something went wrong. Try refreshing this page.
        </p>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
