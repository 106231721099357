import * as React from "react";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { FrankBackendTypes } from "frank-types";

export default function Eligibility({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const { currentGroup } = useCurrentUserData();

  return (
    <PendingMemberSection
      title="Eligibility"
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.eligibilityIssues.map((i) => i.type),
        null,
        2
      )}
      complete={pendingMember.onboardingIssues.eligibilityIssues.length === 0}
    >
      <TaskRow
        dataCy="pending-coworker-eligibility"
        complete={pendingMember.onboardingIssues.eligibilityIssues.length === 0}
        title={`${pendingMember.name} must be a current worker and not a manager at ${currentGroup.name}`}
        description={`${
          pendingMember.name
        } self-certified they are a current, non-management worker at ${
          currentGroup.name
        }. ${
          pendingMember.onboardingIssues.eligibilityIssues.length === 0
            ? "This was verified by at least one other coworker."
            : "This has not been verified by anyone else."
        }`}
      />
    </PendingMemberSection>
  );
}
