"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuDivider = exports.MenuItem = exports.PureMenuItem = exports.NonInteractiveMenuItem = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const menuItemClassnames = "w-full text-left p-1 ";
const NonInteractiveMenuItem = ({ children, className }) => {
    return (React.createElement("div", { className: "p-1 t-small" },
        React.createElement("div", { className: `py-3 px-2 ${className}` }, children)));
};
exports.NonInteractiveMenuItem = NonInteractiveMenuItem;
exports.PureMenuItem = React.forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const className = listItemClassname({ className: props.className });
    return (React.createElement("li", Object.assign({ ref: ref }, props, { className: className }), children));
});
const listItemClassname = ({ selected = false, className = "" }) => `t-small rounded hoverable ${selected ? "hovering" : ""} focus:outline-none frank-menu-item text-canvas-400 w-full text-left ${className}`;
const MenuItem = ({ children, onClick, id, className, to, selected, pad = true }) => {
    const innerClasses = listItemClassname({ selected, className });
    const inner = to ? (React.createElement(react_router_dom_1.Link, { id: id, onClick: onClick, to: to, className: innerClasses },
        React.createElement("div", { className: `${pad ? "py-4 px-3" : ""} truncate hoverable rounded` }, children))) : (React.createElement("button", { id: id, type: "button", onClick: onClick, className: innerClasses },
        React.createElement("div", { className: `${pad ? "py-3 px-2" : ""} truncate rounded` }, children)));
    return (React.createElement("div", { id: id, className: menuItemClassnames }, inner));
};
exports.MenuItem = MenuItem;
function MenuDivider() {
    return (React.createElement("div", { className: "relative my-0.5" },
        React.createElement("div", { className: "absolute inset-0 flex items-center", "aria-hidden": "true" },
            React.createElement("div", { className: "w-full border-t" }))));
}
exports.MenuDivider = MenuDivider;
