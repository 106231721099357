"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = exports.selectClasses = void 0;
const React = __importStar(require("react"));
const useComposeEventHandler_1 = require("../../hooks/useComposeEventHandler");
const FormGroup_1 = require("./FormGroup");
const FormHelpText_1 = require("./FormHelpText");
const selectClasses = (outlineColor, focusOutline) => `block t-small w-full pl-3 pr-10 py-2 text-white disabled:text-canvas-400 bg-transparent disabled:bg-canvas-700 border border-${outlineColor} focus:outline-none focus:ring-${focusOutline} focus:border-${focusOutline} rounded`;
exports.selectClasses = selectClasses;
const Select = (_a) => {
    var { helpTextRef, helpText, onChange, register, children, placeholder, registerArgs, options, onFocus, id, name, errorText } = _a, selectProps = __rest(_a, ["helpTextRef", "helpText", "onChange", "register", "children", "placeholder", "registerArgs", "options", "onFocus", "id", "name", "errorText"]);
    const outlineColor = errorText ? "frank-red-400" : "canvas-500";
    const focusOutline = errorText ? "frank-red-400" : "canvas-500";
    const [isDirty, setDirty] = React.useState(false);
    const setDirtyTrue = React.useCallback(() => setDirty(true), [setDirty]);
    const placeholderShouldShow = !isDirty && !selectProps.defaultValue;
    const composedOnChange = useComposeEventHandler_1.useComposeEventHandler([onChange]);
    const composedOnFocus = useComposeEventHandler_1.useComposeEventHandler([onFocus, setDirtyTrue]);
    return (React.createElement(React.Fragment, null,
        React.createElement("select", Object.assign({ ref: register && register(registerArgs), onBlur: composedOnChange, onChange: composedOnChange, onFocus: composedOnFocus, id: id, name: name, className: exports.selectClasses(outlineColor, focusOutline) }, selectProps),
            placeholder && (React.createElement("option", { disabled: true, selected: placeholderShouldShow }, placeholder)),
            children || (options === null || options === void 0 ? void 0 : options.map(option => (React.createElement("option", { key: option.value, value: option.value, disabled: option.disabled }, option.label))))),
        helpTextRef && (React.createElement(FormGroup_1.HelpTextPortal, { elemRef: helpTextRef },
            helpText && React.createElement(FormHelpText_1.HelpText, null, helpText),
            errorText && React.createElement(FormHelpText_1.ErrorText, null, errorText)))));
};
exports.Select = Select;
