import { Maybe } from "graphql/jsutils/Maybe";
import { ChatTypes } from "frank-types";

export type ChannelState = {
  channels: ChatTypes.Channel[];
  loading: {
    getChannels: boolean;
    createChannel: boolean;
  };
  errors: {
    getChannels: Maybe<string>;
    createChannel: Maybe<string>;
  };
};

export const defaultChannelState: ChannelState = {
  channels: [],
  loading: {
    getChannels: false,
    createChannel: false,
  },
  errors: {
    getChannels: null,
    createChannel: null,
  },
};

type ChannelAction =
  | { type: "channels-loaded"; channels: ChatTypes.Channel[] }
  | { type: "channel-changed"; channel: ChatTypes.Channel }
  | { type: "clear-unread"; channelId: string }
  | {
      type: "error";
      errorType: keyof ChannelState["errors"];
      error: string;
      errorObject?: Error;
    }
  | { type: "finish-loading"; loadingType: keyof ChannelState["loading"] }
  | { type: "loading"; loadingType: keyof ChannelState["loading"] }
  | { type: "reset-channels" };

export function channelReducer(
  previousState: ChannelState,
  action: ChannelAction
): ChannelState {
  if (action.type === "clear-unread") {
    return {
      ...previousState,
      channels: previousState.channels.map((ch) => {
        if (ch.id === action.channelId) {
          return {
            ...ch,
            importantCount: 0,
            unreadCount: 0,
            cursor: new Date(),
          };
        }
        return ch;
      }),
    };
  }
  if (action.type === "error") {
    console.warn("error action in channel reducer", action);
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.errorType]: false,
      },
      errors: {
        ...previousState.errors,
        [action.errorType]: action.error,
      },
    };
  }
  if (action.type === "loading") {
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.loadingType]: true,
      },
      errors: {
        ...previousState.errors,
        [action.loadingType]: null,
      },
    };
  }
  if (action.type === "finish-loading") {
    return {
      ...previousState,
      loading: {
        ...previousState.loading,
        [action.loadingType]: false,
      },
    };
  }
  if (action.type === "channels-loaded") {
    return {
      ...previousState,
      channels: action.channels,
    };
  }
  if (action.type === "channel-changed") {
    return {
      ...previousState,
      channels: previousState.channels.map((ch) => {
        if (ch.id === action.channel.id) {
          return action.channel;
        }
        return ch;
      }),
    };
  }
  if (action.type === "reset-channels") {
    return defaultChannelState;
  }
  return previousState;
}
