import {
  Button,
  FormGroup,
  Input,
  Intent,
  Sizes,
  useToaster,
  ModalBody,
  ModalHeader
} from "@get-frank-eng/design-system";
import * as React from "react";

export default function VerifyEmailModal({
  email,
  sendConfirmationEmail
}: {
  email: string;
  sendConfirmationEmail: () => void;
}) {
  const toaster = useToaster();
  const onResendClick = React.useCallback(async () => {
    try {
      await sendConfirmationEmail();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Email confirmation resent"
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "There was an error resending your confirmation"
      });
    }
  }, [toaster, sendConfirmationEmail]);

  return (
    <>
      <ModalHeader title="Email verification" />
      <ModalBody>
        <p className="mb-3">
          We've sent a confirmation email to the following email address
        </p>

        <FormGroup label="Personal email address" id="email" name="email">
          <Input type="email" disabled value={email} />
        </FormGroup>

        <p className="my-3">
          Once you receive the email, click on the button in that email to
          verify your email address. You will be taken back here to complete
          your security check.
        </p>

        <p className="my-3">Didn't receive the email?</p>

        <Button
          buttonStyle="outline"
          size={Sizes.SM}
          type="button"
          onClick={onResendClick}
        >
          Send confirmation email again
        </Button>
      </ModalBody>
    </>
  );
}
