import * as React from "react";
import classNames from "classnames";
import { Avatar, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const Gradient = () => {
  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 52.6%, #FFFFFF 100%)",
        height: "168px",
      }}
      className={classNames([
        "invisible",
        "md:visible",
        "pointer-events-none",
        "fixed",
        "bottom-0",
        "left-0",
        "w-screen",
        "z-10",
      ])}
    />
  );
};

const Wrapper = ({ inviter }: { inviter?: FrankBackendTypes.Inviter }) => {
  return (
    <>
      {/* <Gradient /> */}
      {inviter && (
        <div
          className={classNames([
            "fixed",
            "bg-canvas-100",
            "md:bg-transparent",
            "pointer-events-none",
            "bottom-0",
            "left-0",
            "w-screen",
            "border-t",
            "md:border-none",
            "h-20",
            "z-20",
            "flex",
            "flex-row",
            "items-center",
            "px-6",
            "t-micro",
          ])}
        >
          <Avatar
            size={Sizes.SM}
            src={inviter.profilePicUrl}
            altText={inviter.name}
          />
          <div className="ml-2 w-64">
            {inviter.name} and your other coworkers are improving your workplace
            using Frank
          </div>
        </div>
      )}
    </>
  );
};

export default Wrapper;
