"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropzoneProvider = void 0;
const React = __importStar(require("react"));
const DropzoneContext = React.createContext(undefined);
function DropzoneProvider({ children }) {
    const [isDragging, setDragging] = React.useState(false);
    // A flag to check if a file is being dragged in from the operating system.
    const isFileFromOS = React.useRef(true);
    // We will cache the target that we first drag over when dragenter fires.
    // This will allow us to compare the cached value with the target that
    // dragleave returns and check whether the dragged item is really out of view.
    const cachedTarget = React.useRef(null);
    const hasDraggedFileFromBrowserOutsideOfWindow = React.useRef(false);
    React.useEffect(() => {
        const handleDragEnter = (e) => {
            cachedTarget.current = e.target;
        };
        // dragstart event only fires when a file is dragged from within the browser
        // window. It won't be fired when coming from the OS.
        const handleDragStart = () => {
            isFileFromOS.current = false;
            hasDraggedFileFromBrowserOutsideOfWindow.current = false;
            console.log("Drag started from browser");
        };
        const handleDragOver = (e) => {
            // e.preventDefault will allow us to drag a file in the browser without it opening.
            e.preventDefault();
            // If document still has focus, that means the user never dropped the file.
            if (hasDraggedFileFromBrowserOutsideOfWindow.current &&
                document.hasFocus()) {
                console.log("Dragged file back into view from browser");
            }
            else if (isFileFromOS.current) {
                console.log("Dragging from OS");
                hasDraggedFileFromBrowserOutsideOfWindow.current = false;
                if (!isDragging)
                    setDragging(true);
            }
            else {
                console.log("Dragging from browser");
            }
        };
        const handleDragLeave = (e) => {
            if (e.target === cachedTarget.current) {
                if (isFileFromOS.current &&
                    !hasDraggedFileFromBrowserOutsideOfWindow.current) {
                    console.log("Left view from OS");
                    if (isDragging)
                        setDragging(false);
                }
                else {
                    // Left view from browser. If a user drops an item here, we won't be able to
                    // capture that event since it's outside of the window. We need to somehow
                    // find out if at any point the document loses focus; aka the user opened
                    // a Finder/Explorer window to drag a new file in.
                    // We will set a flag to signal that the file has been dragged outside of
                    // the window so our dragover handler can compare this with document.hasFocus()
                    // to check if the user has tabbed out to drag another file from the OS.
                    console.log("Left view from browser");
                    hasDraggedFileFromBrowserOutsideOfWindow.current = true;
                    // Reset isFileFromOS flag
                    isFileFromOS.current = true;
                }
            }
        };
        const handleDrop = (e) => {
            // Again, e.preventDefault needs to be here and on dragover event
            // to prevent the file from opening in the browser.
            console.log("drop");
            e.preventDefault();
            // Reset isFileFromOS flag
            isFileFromOS.current = true;
            hasDraggedFileFromBrowserOutsideOfWindow.current = false;
            if (isDragging)
                setDragging(false);
        };
        window.addEventListener("dragenter", handleDragEnter);
        window.addEventListener("dragstart", handleDragStart);
        window.addEventListener("dragover", handleDragOver);
        window.addEventListener("dragleave", handleDragLeave);
        window.addEventListener("drop", handleDrop);
        // Cleanup.
        return () => {
            window.removeEventListener("dragenter", handleDragEnter);
            window.removeEventListener("dragstart", handleDragStart);
            window.removeEventListener("dragover", handleDragOver);
            window.removeEventListener("dragleave", handleDragLeave);
            window.removeEventListener("drop", handleDrop);
        };
    }, [isDragging]);
    const value = React.useMemo(() => {
        return { isDragging };
    }, [isDragging]);
    return (React.createElement(DropzoneContext.Provider, { value: value }, children));
}
exports.DropzoneProvider = DropzoneProvider;
function useFileDrag() {
    const context = React.useContext(DropzoneContext);
    if (!context) {
        throw new Error(`useDropzone must be used within a DropzoneProvider`);
    }
    return context.isDragging;
}
exports.default = useFileDrag;
