"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignOrganizers = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const __1 = require("../..");
const CampaignOrganizers = ({ campaignOrganizers, showOrganizedBy = true, size = __1.Sizes.SM }) => (React.createElement("div", { className: "flex flex-row items-center" },
    React.createElement(__1.AvatarList, { avatarData: campaignOrganizers.map(colab => {
            var _a;
            return ({
                url: ((_a = colab.profilePic) === null || _a === void 0 ? void 0 : _a.url) || "",
                alt: colab.name || "",
                link: `/users/${colab.id}` || ""
            });
        }), size: size }),
    showOrganizedBy && (React.createElement("div", { className: "ml-2 t-small text-white" },
        "Organized by",
        " ",
        React.createElement("div", null,
            React.createElement("span", { className: "t-small" }, campaignOrganizers.slice(0, 2).map((colab, i) => {
                return (React.createElement(react_router_dom_1.Link, { key: colab.id, className: "t-small plus", to: `/users/${colab.id}` },
                    colab.shortName,
                    i !== campaignOrganizers.length - 1 && ", "));
            })),
            campaignOrganizers.length > 2 && (React.createElement("span", null,
                "and ",
                campaignOrganizers.length - 2,
                " ",
                campaignOrganizers.length === 3 && " other",
                campaignOrganizers.length > 3 && " others")))))));
exports.CampaignOrganizers = CampaignOrganizers;
