import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      nothing
    }
  }
`;

export default function useUpdateProfile() {
  const [invokeUpdateProfile, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationUpdateProfileArgs
  >(UPDATE_PROFILE);

  const updateProfile = React.useCallback(
    async ({ name }: { name: string }) => {
      await invokeUpdateProfile({
        variables: { input: { name } },
      });
    },
    [invokeUpdateProfile]
  );

  return {
    updateProfile,
    loading,
    called,
    error,
  };
}
