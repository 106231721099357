import { gql } from "@apollo/client";
import { getClient } from "../../gql-client";
import { ChatTypes } from "frank-types";

export async function getChannels(): Promise<ChatTypes.Channel[]> {
  const client = await getClient();
  const { data, errors } = await client.query<
    Pick<ChatTypes.Query, "channels">
  >({
    query: gql`
      query GetChannels {
        channels {
          id
          name
          unreadCount
          importantCount
          allowReplies
          cursor
          type
        }
      }
    `,
    fetchPolicy: "no-cache",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.channels;
}

export async function getChannel(
  channelId: string
): Promise<ChatTypes.Channel> {
  const client = await getClient();
  const { data, errors } = await client.query<
    Pick<ChatTypes.Query, "channel">,
    ChatTypes.QueryChannelArgs
  >({
    query: gql`
      query GetChannel($id: ID!) {
        channel(id: $id) {
          id
          name
          unreadCount
          allowReplies
          importantCount
          cursor
          type
        }
      }
    `,
    variables: { id: channelId },
    fetchPolicy: "no-cache",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.channel;
}
