import * as React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Icon,
  LargePulseCard,
  Sizes,
  TemplateCard,
  useResponsive,
  FoldedCard,
  ScreenSizes,
  Delay,
} from "@get-frank-eng/design-system";
import useImportTemplate from "./dataAccess/mutations/useImportTemplate";
import useGetTemplates from "./dataAccess/queries/useGetTemplates";
import useCreateCampaign from "./dataAccess/mutations/useCreateCampaign";
import Back from "../components/Back";
import IntercomShouldShow from "../components/IntercomShouldShow";

const CardGrid = ({ children }) => {
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 md:gap-6">
      {children}
    </div>
  );
};

const Templates = () => {
  const { importTemplateCalled, importTemplateData } = useImportTemplate();

  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;

  if (importTemplateCalled && importTemplateData) {
    return (
      <Redirect
        to={`/campaigns/${importTemplateData.importTemplate.id}/builder`}
      />
    );
  }

  const webTop = (
    <div className="relative top-0 left-0 p-4 md:p-8">
      <Back />
    </div>
  );

  const mobileTop = (
    <div className="relative top-0 left-0 p-4">
      <Link to="/campaigns">
        <Icon icon="keyboard_backspace" size={Sizes.LG} />
      </Link>
    </div>
  );

  return (
    <div>
      <IntercomShouldShow />
      {isMobile ? mobileTop : webTop}
      <div className="w-screen">
        <div className="px-4 md:pt-24 max-w-6xl sm:mx-auto sm:px-8 w-full">
          <h1 className={classNames(["t-title-4 plus"])}>New Campaign</h1>
          <p className="t-regular">
            We suggest starting from an expert template.
          </p>

          <section className="my-12">
            <TemplateGallery showBlank />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Templates;

export const TemplateGallery = ({ showBlank }) => {
  const {
    createCampaign,
    createCampaignData,
    createCampaignCalled,
  } = useCreateCampaign();

  const { templates, errorTemplates, loadingTemplates } = useGetTemplates(null);
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;

  const minCardHeight = isMobile ? "180px" : "212px";

  if (createCampaignCalled && createCampaignData) {
    return (
      <Redirect
        to={`/campaigns/${createCampaignData.createCampaign.id}/builder`}
      />
    );
  }

  if (loadingTemplates) {
    return (
      <Delay delay={500}>
        <CardGrid>
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
        </CardGrid>
      </Delay>
    );
  }

  if (errorTemplates) {
    throw Error(errorTemplates.message);
  }

  return (
    <CardGrid>
      {showBlank && (
        <FoldedCard
          pad
          onClick={async () => createCampaign()}
          size={isMobile ? Sizes.SM : Sizes.LG}
        >
          <div
            className="flex flex-col justify-center flex-grow mx-auto"
            style={{ minHeight: minCardHeight }}
          >
            <i
              style={{ fontSize: "24px" }}
              className="material-icons-outlined self-center"
            >
              add
            </i>
            <div className="t-small plus pt-2">Blank Campaign</div>
          </div>
        </FoldedCard>
      )}
      {templates.map((template) => (
        <TemplateCard
          authorBio={template.author.bio}
          authorImage={template.author.image.url}
          authorName={template.author.name}
          category={template.categories[0]?.name}
          subcategory={template.categories[1]?.name}
          title={template.title}
          url={`/templates/${template.id}/preview`}
          key={template.id}
        />
      ))}
    </CardGrid>
  );
};
