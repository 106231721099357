export default ({ complete }: { complete: boolean }) => {
  if (complete) {
    return {
      login: "Sign out",
      stepLabel: "Step 1 Complete",
      sectionHeader: "Account created",
      subText: ""
    };
  }
  return {
    login: "Log in",
    stepLabel: `Step 1 / 3`,
    sectionHeader: "Create your account",
    subText:
      "Make sure you’re using personal information and not information associated with your workplace."
  };
};
