import {
  Avatar,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import { EventTypes, FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useCompleteTour from "../dataAccess/useCompleteTour";
import { useNavigationState } from "../../Navigation/hooks/useNavigationState";
import useFindFriendlyFaces from "../dataAccess/useFindFriendlyFaces";
import { FriendlyFaces } from "../components/FriendlyFaces";
import * as analytics from "../../analytics";

export default function WelcomeModal({
  callback,
  workerType,
  groupName,
  groupLogoUrl,
  setCurrentTour,
}: {
  callback: () => void;
  workerType: FrankBackendTypes.WorkerType;
  groupName: string;
  groupLogoUrl?: string;
  setCurrentTour: (tour: FrankBackendTypes.TourType) => void;
}) {
  const { closeModal } = useModals();
  const { completeTour } = useCompleteTour();
  const { refetch } = useNavigationState();

  const onOptOut = React.useCallback(async () => {
    await completeTour(FrankBackendTypes.TourType.Welcome);
    await refetch();
    setCurrentTour(null);
  }, [completeTour, refetch, setCurrentTour]);
  const { friendlyFaces, loadingFriendlyFaces } = useFindFriendlyFaces();

  React.useEffect(() => {
    analytics.capture(EventTypes.AnalyticsEvents.VIEW_HOME_SCREEN);
  }, []);

  const header = React.useMemo(() => {
    if (loadingFriendlyFaces) {
      return (
        <div className="flex -space-x-3 overflow-hidden items-center">
          <div className="rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse border z-10" />
          <div className="rounded-lg w-12 h-12 flex-shrink-0 bg-canvas-400 animate-pulse border z-20" />
          <div className="rounded-lg w-14 h-14 flex-shrink-0 bg-canvas-400 animate-pulse border z-30" />
          <div className="rounded-lg w-12 h-12 flex-shrink-0 bg-canvas-400 animate-pulse border z-20" />
          <div className="rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse border z-10" />
        </div>
      );
    }
    if (friendlyFaces?.length < 4 || !friendlyFaces) {
      return (
        <Avatar
          size={Sizes.XL}
          textPreview={groupLogoUrl ? null : groupName}
          src={groupLogoUrl}
        />
      );
    }
    return (
      <FriendlyFaces
        faces={friendlyFaces?.map((f) => ({
          url: f.url,
          altText: "group member avatar",
        }))}
        groupName={groupName}
        groupAvatar={groupLogoUrl}
      />
    );
  }, [loadingFriendlyFaces, friendlyFaces, groupName, groupLogoUrl]);

  const modalFooter = (
    <ModalFooter specialty border={false}>
      <div className="space-y-8">
        <div className="flex flex-row w-full justify-center space-x-2">
          <Button
            buttonStyle="brand"
            onClick={() => {
              closeModal();
              callback();
            }}
          >
            Show me around
          </Button>
          <Button
            id="opt-out-of-welcome-tour"
            buttonStyle="secondary"
            onClick={() => {
              closeModal();
              onOptOut();
            }}
          >
            I'll take it from here
          </Button>
        </div>
      </div>
    </ModalFooter>
  );

  if (workerType === FrankBackendTypes.WorkerType.WorkerOne) {
    return (
      <>
        <ModalHeader
          icon={<Avatar size={Sizes.XL} src="/icon-brand-square.png" />}
          specialty
          title="Welcome to your new group"
          onCloseClick={onOptOut}
        />
        <ModalBody>
          <div className="t-small text-canvas-400 text-center space-y-4">
            <div>
              Starting the important conversations in your workplace is hard. It
              takes courage, leadership, and support from those around you.
            </div>
            <div>
              We wish you all the best in your journey to improve your
              workplace, and we are here for you to support at any time.
            </div>
            <div className="t-small plus">- Frank</div>
          </div>
        </ModalBody>
        {modalFooter}
      </>
    );
  }

  return (
    <>
      <ModalHeader
        icon={header}
        specialty
        title={`Welcome to Frank`}
        onCloseClick={onOptOut}
      />
      <ModalBody specialty>
        <div className="t-small text-canvas-400">
          Starting the important conversations in your workplace is hard. It
          takes courage, leadership, and support from those around you. We wish
          you all the best in your journey to improve your workplace, and we are
          here for you to support at any time.
        </div>
        <div className="t-small text-canvas-400 pt-2">
          Wondering what to do next? Let us show you four ways you can get
          started with Frank.
        </div>
      </ModalBody>
      {modalFooter}
    </>
  );
}
