import * as React from "react";
import { useModals } from "../Modals";
import {
  Button,
  Card,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import useMyProfileDateAcceptedNoMgmt from "./dataAccess/queries/useMyProfileNoMgmtAgreement";
import Loading from "../components/Loading";
import { useHistory } from "react-router";
import { useAcceptNoMgmtAgreement } from "./dataAccess/queries/mutations/useAcceptNoMgmtAgreement";
import { useNavigationState } from "../Navigation/hooks/useNavigationState";

const DefaultInviteModal = () => {
  const { setModal } = useModals();
  const {
    hasAcceptedShareWarning,
    error,
    loading,
    refetch,
  } = useMyProfileDateAcceptedNoMgmt();

  const { acceptNoMgmt } = useAcceptNoMgmtAgreement({ onAccept: refetch });
  const { refetch: refetchNavigation } = useNavigationState();

  const history = useHistory();

  const openEmailInviteModal = React.useCallback(() => {
    setModal({
      type: "emailInviteModal",
      props: {
        view: "modal",
      },
    });
  }, [setModal]);

  const openLinkInviteModal = React.useCallback(() => {
    setModal({
      type: "linkInviteModal",
      props: {},
    });
  }, [setModal]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!hasAcceptedShareWarning) {
    return (
      <>
        <ModalHeader
          icon={
            <Icon icon="warning" classNames="text-brand-400" size={Sizes.XL} />
          }
          specialty
          title="No managers allowed"
        />
        <ModalBody specialty>
          <div className="t-small text-canvas-400">
            Frank is only for non-management workers. You agree not to share
            Frank with anyone outside the company or any managers. Management
            includes anyone with hiring/firing power, anyone with decision
            making ability in work assignments, and/or anyone with decision
            making ability in worker schedules.
          </div>
        </ModalBody>
        <ModalFooter specialty>
          <Button onClick={() => history.push("/legal")}>
            Read our policies
          </Button>
          <Button
            buttonStyle="brand"
            onClick={() => {
              acceptNoMgmt();
              refetchNavigation();
            }}
          >
            I agree
          </Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader title="Invite coworkers" />
      <div className="p-6">
        <div className="t-regular text-canvas-400 pb-6">
          Every worker invited to the group must also be verified and admitted
          by at least two verified group members.
        </div>
        <div className="space-y-3">
          <Card pad size={Sizes.SM} onClick={openEmailInviteModal}>
            <div className="flex flex-row justify-start items-center">
              <Icon icon="mail" size={Sizes.XL} />
              <div className="ml-3">
                <div className="t-small text-white">Invite via email</div>
                <div className="t-small text-canvas-400">
                  Send an email to your coworker
                </div>
              </div>
            </div>
          </Card>
          <Card pad size={Sizes.SM} onClick={openLinkInviteModal}>
            <div className="flex flex-row justify-start items-center">
              <Icon icon="link" size={Sizes.XL} />
              <div className="ml-3">
                <div className="t-small text-white">
                  Invite via private link
                </div>
                <div className="t-small text-canvas-400">
                  Generate a unique link for coworkers to access
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default DefaultInviteModal;
