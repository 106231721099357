import { Tooltip } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Link } from "react-router-dom";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";
import { frankflection } from "frankflection";

function Description({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  if (pendingMember.trustJudgementCount === 0) {
    return (
      <>
        {pendingMember.name} hasn't been marked as "trusted" by anyone in the
        group yet. 2 coworkers are needed to admit someone into Frank.
      </>
    );
  }
  return (
    <>
      {pendingMember.totalTrustUpvotes > 0 && (
        <Tooltip
          hideArrow={false}
          trigger="hover"
          tooltip={
            <div>
              {pendingMember.trustedBy.map((user) => (
                <div>
                  <Link
                    key={user.id}
                    className="underline"
                    to={`/users/${user.id}`}
                  >
                    {user.shortName}
                  </Link>
                </div>
              ))}
            </div>
          }
        >
          <span>
            {`${pendingMember.totalTrustUpvotes} ${frankflection.coworker(
              pendingMember.totalTrustUpvotes
            )}`}{" "}
            voted to admit {pendingMember.name}.{" "}
          </span>
        </Tooltip>
      )}
      {pendingMember.totalTrustDownvotes > 0 && (
        <Tooltip
          hideArrow={false}
          trigger="hover"
          tooltip={
            <div>
              {pendingMember.distrustedBy.map((user) => (
                <div>
                  <Link
                    key={user.id}
                    className="underline"
                    to={`/users/${user.id}`}
                  >
                    {user.shortName}
                  </Link>
                </div>
              ))}
            </div>
          }
        >
          <span data-cy="coworkers-voted-to-deny">
            {`${pendingMember.totalTrustDownvotes} ${frankflection.coworker(
              pendingMember.totalTrustDownvotes
            )}`}{" "}
            voted to deny {pendingMember.name}.
          </span>
        </Tooltip>
      )}
    </>
  );
}

export default function Trustworthiness({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  return (
    <PendingMemberSection
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.trustworthinessIssues.map((i) => i.type),
        null,
        2
      )}
      title="Trustworthiness"
      complete={
        pendingMember.onboardingIssues.trustworthinessIssues.length === 0
      }
    >
      <TaskRow
        dataCy="trustworthiness"
        complete={
          pendingMember.onboardingIssues.trustworthinessIssues.length === 0
        }
        title={
          pendingMember.netTrust < 2
            ? "Must be trusted by at least 2 verified members"
            : "Trusted by at least 2 verified members"
        }
        description={<Description pendingMember={pendingMember} />}
      />
    </PendingMemberSection>
  );
}
