"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlideOver = void 0;
const react_1 = require("@headlessui/react");
const React = __importStar(require("react"));
const constants_1 = require("../../constants");
const Button_1 = require("../Button");
const TopBar_1 = require("./TopBar");
const SlideOver = ({ isOpen, children, title, onClose, right }) => {
    const closeButton = (React.createElement(Button_1.IconButton, { buttonStyle: "minimal", size: constants_1.Sizes.MD, icon: "close", onClick: onClose }));
    return (React.createElement("div", { className: `${isOpen ? "pointer-events-auto" : "pointer-events-none"} fixed inset-0 overflow-hidden z-50` },
        React.createElement("div", { className: `absolute inset-0 overflow-hidden ${isOpen ? "pointer-events-auto" : "pointer-events-none"}` },
            React.createElement(react_1.Transition, { show: isOpen, enter: "ease-in-out duration-500", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in-out duration-500", leaveFrom: "opacity-100", leaveTo: "opacity-0", "aria-hidden": !isOpen, onClick: onClose },
                React.createElement("div", { className: "absolute inset-0 bg-canvas-900 bg-opacity-75 transition-opacity", "aria-hidden": "true" })),
            React.createElement("section", { className: "absolute inset-y-0 right-0 pl-10 max-w-full flex", "aria-labelledby": "slide-over-heading" },
                React.createElement(react_1.Transition, { enter: "transform transition ease-in-out duration-500 sm:duration-700", enterFrom: "translate-x-full", enterTo: "translate-x-0", leave: "transform transition ease-in-out duration-500 sm:duration-700", leaveFrom: "translate-x-0", leaveTo: "translate-x-full", show: isOpen },
                    React.createElement("div", { className: "w-screen max-w-sm" },
                        React.createElement("div", { className: "h-screen flex flex-col bg-canvas-700 shadow-xl overflow-y-auto" },
                            React.createElement(TopBar_1.TopBar, { title: title, left: closeButton, border: false, right: right }),
                            React.createElement("div", { className: "relative overflow-y-auto flex-1 px-4 sm:px-6" },
                                React.createElement("div", { className: "absolute inset-0 px-4 sm:px-6" }, children)))))))));
};
exports.SlideOver = SlideOver;
