import { Intent, Select, useToaster } from "@get-frank-eng/design-system";
import * as React from "react";
import { DateIntervals } from "../../utils/Date";
import SecurityFeatureRow from "../components/SecurityFeatureRow";

export default ({
  currentExpiration,
  loading,
  update,
  token,
  idModifier = ""
}) => {
  const toaster = useToaster();

  const [hasClickedUpdate, setHasClickedUpdate] = React.useState(false);

  const updateExpiration = async expiration => {
    try {
      await update({ expiration }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Update successful"
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong"
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`expiration${idModifier}`}
      title="Set Expiration"
      defaultChecked={!!currentExpiration}
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updateExpiration(null);
          return;
        }
        if (!isChecked && currentExpiration) {
          updateExpiration(null);
        }
      }}
      contentToShowWhenChecked={setDirty => {
        const select = (
          <div className="w-64">
            <Select
              autoFocus
              name="expiration"
              disabled={loading}
              onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                setDirty(true);
                updateExpiration(e.currentTarget.value);
              }}
              defaultValue={currentExpiration || null}
            >
              <optgroup label="Expires in">
                <option value={null}>Select a value</option>
                <option value={DateIntervals.ONE_HOUR}>1 Hour</option>
                <option value={DateIntervals.ONE_DAY}>24 Hours</option>
                <option value={DateIntervals.THREE_DAYS}>3 Days</option>
                <option value={DateIntervals.ONE_WEEK}>1 Week</option>
              </optgroup>
            </Select>
          </div>
        );

        if (hasClickedUpdate) {
          return select;
        }

        return (
          <>
            {currentExpiration ? (
              <div className="t-small mt-2">
                <span>Expires {currentExpiration}</span>
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    setHasClickedUpdate(true);
                  }}
                  className="ml-2 t-small plus"
                >
                  Update
                </button>
              </div>
            ) : (
              select
            )}
          </>
        );
      }}
    >
      Set this invite link to expire after a specified amount of time. You can
      choose 1 hour, 1 day, 3 days, or a week.
    </SecurityFeatureRow>
  );
};
