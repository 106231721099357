import { useMessages } from "@get-frank-eng/chat-client";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { ChatTypes } from "frank-types";
import * as React from "react";
import { MessageBarProps } from "../components/WrappedMessageBar";
import uploadAttachments from "../util/messageAttachment";

type HookReturn = ReturnType<typeof useMessages>;

export const useProcessAttachmentsLinksAndSendMessage = ({
  sendMessage,
  editMessage,
  editingMessage,
  setDraft,
  setMessageEditing,
}: {
  sendMessage: HookReturn["sendMessage"];
  editMessage: HookReturn["editMessage"];
  editingMessage?: ChatTypes.Message;
  setMessageEditing: (m: ChatTypes.Message | null) => void;
  setDraft: (draft: string) => void;
}) => {
  const toaster = useToaster();
  const [loading, setLoading] = React.useState(false);
  const processAttachmentsLinksAndSendMessage = React.useCallback(
    async (params: Parameters<MessageBarProps["onSend"]>[0]) => {
      if (params.files) {
        setLoading(true);
      }
      const attachments = await uploadAttachments(params.files);
      setLoading(false);
      const richObjects = [
        ...attachments.map((a) => ({
          type: "attachment",
          foreignIdentifier: a.attachmentId,
        })),
      ];

      if (params.pollId) {
        richObjects.push({ type: "poll", foreignIdentifier: params.pollId });
      }
      if (editingMessage) {
        await editMessage(editingMessage.id, params.text);
        setMessageEditing(null);
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: "Message edited",
        });
      } else {
        setDraft("");

        await sendMessage({
          body: params.text,
          richObjects,
          sentAt: new Date(),
        });
      }
    },
    [
      toaster,
      setLoading,
      editMessage,
      editingMessage,
      sendMessage,
      setDraft,
      setMessageEditing,
    ]
  );
  return { processAttachmentsLinksAndSendMessage, loading };
};
