"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
const React = __importStar(require("react"));
function Switch({ label, checked, disabled, id, loading, onChange }) {
    return (React.createElement("button", { id: id, type: "button", onClick: !disabled && !loading ? onChange : undefined, "aria-pressed": "false", style: { width: "44px" }, className: `${checked ? "bg-frank-green-400" : "bg-canvas-500"} ${disabled ? "cursor-not-allowed" : ""} ${loading ? "cursor-wait" : ""} relative inline-flex flex-shrink-0 h-6 border-2 border-transparent focus:border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200` },
        React.createElement("span", { className: "sr-only" }, label),
        React.createElement("span", { "aria-hidden": "true", className: `${checked ? "translate-x-5" : "translate-x-0"} translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200` })));
}
exports.Switch = Switch;
