import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { generateFutureDate } from "../../../../utils/Date";

const INVITE_BY_EMAIL = gql`
  mutation BulkInviteByEmail($input: BulkInviteByEmailDTO!) {
    bulkInviteByEmail(input: $input)
  }
`;

export const useBulkInviteByEmail = () => {
  const [
    invokeInviteByEmail,
    { loading: loadingInviteByEmail, error: saveErrorByEmail },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "bulkInviteByEmail">,
    FrankBackendTypes.MutationBulkInviteByEmailArgs
  >(INVITE_BY_EMAIL);

  const bulkInviteByEmail = React.useCallback(
    async (data: {
      emails: string[];
      message?: string;
      passphrase?: string;
      expiration?: string;
    }) => {
      const { data: returnedCoworkerIds } = await invokeInviteByEmail({
        variables: {
          input: {
            emails: data.emails,
            message: data.message,
            passphrase: data.passphrase,
            expiration: data.expiration
              ? generateFutureDate(new Date(), +data.expiration)
              : undefined,
          },
        },
      });
      return returnedCoworkerIds;
    },
    [invokeInviteByEmail]
  );

  return {
    bulkInviteByEmail,
    loadingInviteByEmail,
    saveErrorByEmail,
  };
};
