import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { Intent, useToaster } from "@get-frank-eng/design-system";

const ARCHIVE_CAMPAIGN = gql`
  mutation ArchiveCampaign($campaignId: ID!) {
    archiveCampaign(campaignId: $campaignId) {
      nothing
    }
  }
`;
export default function useArchiveCampaign({
  campaignId,
  refetch
}: {
  campaignId: string;
  refetch: () => any;
}) {
  const [invokeArchiveCampaign, { loading, error }] = useMutation<
    { archiveCampaign: void },
    { campaignId: string }
  >(ARCHIVE_CAMPAIGN);

  const toaster = useToaster();
  const archiveCampaign = React.useCallback(async () => {
    try {
      await invokeArchiveCampaign({
        variables: {
          campaignId
        }
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Campaign archived"
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "There was an error archiving the campaign"
      });
      throw e;
    }
  }, [invokeArchiveCampaign, campaignId, refetch, toaster]);

  return {
    archiveCampaign,
    loadingArchive: loading,
    errorArchive: error
  };
}
