import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, Redirect } from "react-router";
import Frank from "./components/icons/Frank";
import { useModals } from "./Modals";
import useSession, { LoginStatus } from "./Auth/useSession";
import { AuthStates } from "./Auth/AuthState";
import {
  Button,
  Sizes,
  FormGroup,
  Input,
  Password,
  InlineAlert,
  Intent,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import ShortFrank from "./components/icons/ShortFrank";
import { Link } from "react-router-dom";
import useQuery from "./hooks/useQuery";
import { useChannels } from "@get-frank-eng/chat-client";

interface FormData {
  email: string;
  password: string;
}

const Login = ({
  externalLoginHandler,
}: {
  externalLoginHandler?: (email: string, password: string) => any;
}) => {
  const [serverErr, setServerErr] = React.useState<Error | null>();
  const [loginErr, setLoginErr] = React.useState<Boolean>(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const { setModal } = useModals();
  const params = useQuery();

  const { email } = watch();

  const openForgotPasswordModal = React.useCallback(() => {
    setModal({
      type: "forgotPasswordModal",
      props: {
        defaultEmail: email,
      },
    });
  }, [email, setModal]);

  const [
    onboardingWorkflowState,
    setOnboardingWorkflowState,
  ] = React.useState<FrankBackendTypes.OnboardingWorkflowState>(null);

  const { login, authState } = useSession({});
  const { enterRoom } = useChannels();

  const afterLogin = searchParams.get("after") || "/home";

  const loginHandler = React.useCallback(
    async (data) => {
      try {
        if (!externalLoginHandler) {
          const result = await login(data.email, data.password);
          if (result.status === LoginStatus.SUCCESS) {
            setOnboardingWorkflowState(result.data);
          } else if (result.status === LoginStatus.FAILED) {
            setLoginErr(true);
          }
        } else {
          await externalLoginHandler(data.email, data.password);
        }
        enterRoom();
      } catch (e) {
        setServerErr(e);
        throw new Error(JSON.stringify(e));
      }
    },
    [
      login,
      setServerErr,
      externalLoginHandler,
      setOnboardingWorkflowState,
      enterRoom,
    ]
  );

  if (authState === AuthStates.LOGGED_IN) {
    if (
      onboardingWorkflowState &&
      onboardingWorkflowState ===
        FrankBackendTypes.OnboardingWorkflowState.CreatingNewGroup
    ) {
      return <Redirect to="/create-group/profile" />;
    }
    if (
      onboardingWorkflowState &&
      onboardingWorkflowState !==
        FrankBackendTypes.OnboardingWorkflowState.Finished
    ) {
      if (afterLogin.includes("verifyEmail")) {
        return <Redirect to={afterLogin} />;
      }
      return <Redirect to="/finish-onboarding" />;
    }
    if (onboardingWorkflowState) {
      return <Redirect to={afterLogin} />;
    }
  }

  return (
    <div className="relative w-full flex flex-col justify-center items-center h-screen overflow-hidden transform -translate-y-6 sm:translate-y-0">
      <a
        href="https://getfrank.com"
        className="hidden sm:block absolute top-0 left-0 mt-4 ml-6"
      >
        <Frank />
      </a>
      <div className="px-6 sm:px-0 sm:max-w-sm sm:mx-auto w-full flex flex-col justify-center items-center">
        {loginErr && (
          <div className="py-4 w-full">
            <InlineAlert intent={Intent.FAILURE} title="Authentication Error">
              Email or password incorrect
            </InlineAlert>
          </div>
        )}
        {serverErr && (
          <div className="py-4 w-full">
            <InlineAlert intent={Intent.FAILURE} title="Error">
              Something went wrong. Please try again.
            </InlineAlert>
          </div>
        )}
        <div className="h-12 w-12 rounded-lg bg-canvas-700 flex flex-row justify-center items-center mb-4">
          <ShortFrank />
        </div>
        <div className="t-title-4 plus">Log in to Frank</div>
        <div>
          <span className="text-canvas-400 t-small">Or </span>{" "}
          <Link
            to="/create-group/email"
            className="text-brand-300 t-small plus"
          >
            sign up for free
          </Link>
        </div>

        <form
          noValidate
          onSubmit={handleSubmit(loginHandler)}
          className="pt-1 pb-1 w-full"
        >
          <div className="mb-4">
            <FormGroup id="email" label="" name="email" className="-mb-1">
              <Input
                dataCy="email"
                register={register}
                errorText={
                  errors.email && (
                    <>
                      {errors.email.type === "required" && "Email is required"}
                      {errors.email.type === "pattern" && "Not a valid email"}
                    </>
                  )
                }
                registerArgs={{ required: true, pattern: /^\S+@\S+$/i }}
                type="email"
                disabled={authState === AuthStates.LOADING_NEW_SESSION}
                placeholder="Personal email address"
                defaultValue={params.get("email") || null}
              />
            </FormGroup>
            <FormGroup name="password" id="password" label="">
              <Password
                disabled={authState === AuthStates.LOADING_NEW_SESSION}
                errorText={errors.password && "Please type a password"}
                register={register}
                registerArgs={{ required: true }}
                placeholder="Password"
              />
            </FormGroup>
          </div>

          <Button
            dataCy="log-in"
            loading={authState === AuthStates.LOADING_NEW_SESSION}
            buttonStyle="brand"
            className="w-full"
            size={Sizes.MD}
            disabled={!email?.length}
          >
            Log in
          </Button>
        </form>
        <button
          className="text-brand-300 w-full t-small plus mt-4"
          onClick={openForgotPasswordModal}
          data-cy="open-forgot-password"
        >
          Forgot your password?
        </button>
      </div>
      <div className="hidden sm:block absolute bottom-0 w-full">
        <div className="flex flex-row justify-between pb-6 mx-6">
          <div className="py-0 text-white t-mini flex justify-center items-center">
            GetFrank, PBC
          </div>
          <div className="flex flex-row justify-center space-x-4">
            <a
              rel="noreferrer"
              href="https://twitter.com/GetFrank_com"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              Twitter
            </a>
            <a
              rel="noreferrer"
              href="https://medium.com/@getfrank"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              Blog
            </a>
            <a
              rel="noreferrer"
              href="https://app.getfrank.com/legal/privacy"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              Privacy
            </a>
            <a
              rel="noreferrer"
              href="https://app.getfrank.com/contact"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
