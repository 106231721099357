import { useQuery, gql } from "@apollo/client";
import { pendingMemberDetailFragment } from "../../PendingMembers/dataAccess/queries/usePendingMembersListData";
import { resourceFragment } from "../../Resources/dataAccess/useResourcesData";
import { homeFeedFragment } from "./useHomeFeedData";
import { campaignsFragment } from "../../CampaignList/dataAccess/queries/useCampaignList";
import { defaultPendingMemberOrder } from "../../PendingMembers/dataAccess/orders";
import { pendingMemberCoworkersRestriction } from "../../PendingMembers/dataAccess/filters";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query HomePage(
    $feedPagination: PaginationArgumentDTO!
    $coworkerPagination: PaginationArgumentDTO!
    $coworkerFilter: CoworkerFilterDTO!
    $coworkerOrder: CoworkerOrderArgumentDTO
    $secondCoworkerOrder: CoworkerOrderArgumentDTO
    $campaignWorkflowState: CampaignWorkflowState
  ) {
    myProfile {
      id
      homeFeed(pagination: $feedPagination) {
        ...HomeFeedFragment
      }
    }
    coworkers(
      pagination: $coworkerPagination
      filter: $coworkerFilter
      order: $coworkerOrder
      secondOrder: $secondCoworkerOrder
    ) {
      ...PendingMemberDetailFragment
    }
    resources(tag: "home") {
      ...ResourceFragment
    }
    campaigns(workflowState: $campaignWorkflowState) {
      ...CampaignsFragment
    }
  }
  ${homeFeedFragment}
  ${pendingMemberDetailFragment}
  ${resourceFragment}
  ${campaignsFragment}
`;

const PERPAGE_ACTIVITY = 3;
const PERPAGEPENDINGMEMBER = 4;

export default function useHomePageData() {
  const { loading, data, refetch, error } = useQuery<
    {
      myProfile: FrankBackendTypes.MyProfile;
      coworkers: FrankBackendTypes.PaginatedCoworker;
      resources: FrankBackendTypes.Resource[];
      campaigns: FrankBackendTypes.Campaign[];
    },
    {
      feedPagination: FrankBackendTypes.PaginationArgumentDto;
      coworkerPagination: FrankBackendTypes.PaginationArgumentDto;
      coworkerFilter: FrankBackendTypes.CoworkerFilterDto;
      coworkerOrder: FrankBackendTypes.CoworkerOrderArgumentDto;
      secondCoworkerOrder: FrankBackendTypes.CoworkerOrderArgumentDto;
      campaignWorkflowState: FrankBackendTypes.CampaignWorkflowState;
    }
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      feedPagination: { page: 0, perPage: PERPAGE_ACTIVITY },
      coworkerPagination: { page: 0, perPage: PERPAGEPENDINGMEMBER },
      coworkerOrder: defaultPendingMemberOrder.order,
      secondCoworkerOrder: defaultPendingMemberOrder.secondOrder,
      coworkerFilter: pendingMemberCoworkersRestriction,
      campaignWorkflowState: FrankBackendTypes.CampaignWorkflowState.Published,
    },
    pollInterval: 300000,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
}
