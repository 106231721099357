import {
  Button,
  FormGroup,
  Input,
  Intent,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import useUpdateGroupDetails from "../dataAccess/mutations/useUpdateGroup";
type FormParams = {
  name?: string;
  size?: number;
  companyName?: string;
};

export const GroupSettings = () => {
  const { currentGroup, refetchCurrentUserData } = useCurrentUserData();
  const toaster = useToaster();
  const { updateGroupDetails } = useUpdateGroupDetails();
  const { register, handleSubmit } = useForm<FormParams>();
  const [isDirty, setIsDirty] = React.useState(false);

  const saveUpdate = async (formData: FormParams) => {
    try {
      await updateGroupDetails({
        name: formData.name,
        size: +formData.size,
        companyName: formData.companyName,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Successfully updated group details",
      });
      setIsDirty(false);
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong updating group details",
      });
    }
  };

  return (
    <div className="max-w-md">
      <form
        onSubmit={handleSubmit(saveUpdate)}
        noValidate
        className="space-y-8"
      >
        <section>
          <FormGroup name="name" label="Group name" id="name">
            <Input
              register={register}
              id="name"
              maxLength={120}
              placeholder="Group name"
              helpText="E.g. Amazon Workers United"
              defaultValue={currentGroup.name || null}
              onChange={() => setIsDirty(true)}
            />
          </FormGroup>
        </section>
        <section>
          <div className="t-mini plus -mb-2">Group logo</div>
          <AddPhoto
            setGroupAttachmentId={({ attachmentId }) =>
              updateGroupDetails({ attachmentId })
            }
            defaultUrl={currentGroup.logo?.url}
            onUpload={(err, url) => {
              setIsDirty(true);
              refetchCurrentUserData();
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: "Successfully updated group logo",
              });
              if (err) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children: "Something went wrong updating your group logo",
                });
              }
            }}
          />
        </section>
        <section>
          <FormGroup name="companyName" label="Company name" id="companyName">
            <Input
              register={register}
              id="companyName"
              placeholder="Company Name"
              helpText="What is the name of the company you and your group members work at?"
              maxLength={120}
              defaultValue={currentGroup.company || null}
              onChange={() => setIsDirty(true)}
            />
          </FormGroup>
        </section>
        <section>
          <FormGroup name="size" label="Company Size" id="size">
            <Input
              type="number"
              register={register}
              id="size"
              maxLength={20}
              placeholder="Company Size"
              helpText="About how many people work at your company?"
              defaultValue={currentGroup.size || null}
              onChange={() => setIsDirty(true)}
            />
          </FormGroup>
        </section>
        <Button type="submit" buttonStyle="brand" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </div>
  );
};
