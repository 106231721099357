import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import attachmentFragment from "../../../Attachment/dataAccess/attachmentFragment";
import * as React from "react";
import { PaginationArgumentDto } from "frank-types/dist/frank-backend-types";

const CAMPAIGN_ATTACHMENT_QUERY = gql`
  query Attachments(
    $pagination: PaginationArgumentDTO!
    $campaignId: ID
    $topicId: ID
  ) {
    attachments(
      pagination: $pagination
      campaignId: $campaignId
      topicId: $topicId
    ) {
      total
      hasNext
      objects {
        ...AttachmentFragment
      }
    }
  }
  ${attachmentFragment}
`;

const perPage = 10;

export default function useAttachments({
  campaignId,
  topicId,
}: {
  campaignId?: string;
  topicId?: string;
}) {
  const { data, loading, error, fetchMore, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "attachments">,
    FrankBackendTypes.QueryAttachmentsArgs & {
      pagination: PaginationArgumentDto;
    }
  >(CAMPAIGN_ATTACHMENT_QUERY, {
    variables: { campaignId, topicId, pagination: { page: 0, perPage } },
  });

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        campaignId,
        pagination: {
          page: Math.round(data?.attachments.objects.length / perPage),
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        const toReturn = {
          campaign: {
            attachments: {
              ...prev.attachments,
              ...fetchMoreResult.attachments,
              objects: [
                ...prev.attachments.objects,
                ...fetchMoreResult.attachments.objects,
              ],
            },
          },
        };
        return toReturn;
      },
    });
  }, [fetchMore, campaignId, data?.attachments.objects.length]);

  return {
    loading,
    error,
    refetch,
    loadMore,
    attachments: data?.attachments,
  };
}
