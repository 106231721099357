import {
  ActivityFeed,
  IconButton,
  Nudge,
  ResourceCard,
  Sizes,
  StatusBadge,
  SlideOver,
  useScreenSize,
  ScreenSizes,
  useLayout,
  EmptyState,
  Button,
  SimpleSectionHeader,
  AttachedBadge,
} from "@get-frank-eng/design-system";
import * as React from "react";
import CampaignList from "../Campaigns/CampaignList";
import DetailLayout from "../components/layouts/Detail";
import Pager, { PaginationStrategy } from "../components/Pager";
import { useModals } from "../Modals";
import useHomePageData from "./dataAccess/useHomePageData";
import HomePageLoading from "./HomePageLoading";
import Alerts from "../Alerts";
import { FrankBackendTypes } from "frank-types";
import useQuery from "../hooks/useQuery";
import { useHistory } from "react-router";
import WrappedPageHeader from "../components/WrappedPageHeader";
import { useNavigationState } from "../Navigation/hooks/useNavigationState";
import PendingMemberList from "../PendingMembers/pages/PendingMemberList";
import { useCurrentTourContext } from "../Tour/CurrentTourProvider";

const Home = () => {
  const { data, loading, error } = useHomePageData();
  const [showAlerts, setShowAlerts] = React.useState(false);
  const isMobile = useScreenSize() < ScreenSizes.MD;
  const { navigation, refetch: refetchNavigationState } = useNavigationState();
  const { setMobilePanel } = useLayout();
  const { push } = useHistory();
  const { setCurrentTour } = useCurrentTourContext();

  const querylist = useQuery();
  const { setModal } = useModals();

  const unreadAlerts = React.useMemo(() => {
    if (navigation) {
      const topLevelSections = navigation.sections.find(
        (section) => section.title === "Top level app sections"
      );
      const home = topLevelSections.items.find(
        (item) => item.identifier === "home"
      );
      return home.badge;
    }
    return false;
  }, [navigation]);

  React.useEffect(() => {
    const modal = querylist.get("modal");
    if (modal) {
      if (modal === "invite") {
        setModal({ type: "defaultInviteModal", props: {} });
      }
      if (modal === "createTopic") {
        setModal({ type: "createTopicModal", props: { afterSubmit() {} } });
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      navigation?.incompleteTours.includes(FrankBackendTypes.TourType.Welcome)
    ) {
      setCurrentTour(FrankBackendTypes.TourType.Welcome);
    }
  }, [navigation, setCurrentTour]);

  if (loading) {
    return <HomePageLoading />;
  }

  if (error) {
    if (!data) {
      throw new Error(error.message);
    }
  }

  const noActivityYet = !data?.myProfile?.homeFeed.objects.length;

  const alertsBell = (
    <div className="-mb-4">
      <IconButton
        size={Sizes.XL}
        buttonStyle="minimal"
        icon="notifications_none"
        onClick={() => {
          setShowAlerts(!showAlerts);
        }}
      />
      {unreadAlerts && (
        <Nudge y={-39} x={28}>
          <StatusBadge color="brand-400" size={Sizes.MD} />
        </Nudge>
      )}
    </div>
  );

  const header = (
    <WrappedPageHeader
      title="Home"
      right={
        <span className="inline-block relative md:mr-4">{alertsBell}</span>
      }
    />
  );

  const welcomeTour = navigation?.incompleteTours.find(
    (tour) => tour === FrankBackendTypes.TourType.Welcome
  );

  const mobileHeader = (
    <div>
      <div className="flex flex-row justify-between pb-14 pt-4">
        <AttachedBadge
          color="brand-300"
          position="upper-right"
          size={Sizes.MD}
          ping
          show={Boolean(welcomeTour)}
        >
          <IconButton
            size={Sizes.XL}
            buttonStyle="minimal"
            icon="menu"
            onClick={() => setMobilePanel("left")}
          />
        </AttachedBadge>

        <div className="inline-block relative">{alertsBell}</div>
      </div>
      <div className="t-title-4 plus">Home</div>
    </div>
  );

  const right = (
    <>
      <section>
        <SimpleSectionHeader title="Resources" />
        <div className="space-y-2 mt-3">
          {data?.resources.map((resource) => (
            <ResourceCard
              key={resource.title}
              resourceLabel={resource.label}
              title={resource.title}
              url={resource.url}
              topic="Frank"
              imageUrl={resource.imageUrl}
            />
          ))}
        </div>
      </section>
    </>
  );

  return (
    <DetailLayout
      header={isMobile ? mobileHeader : header}
      direction="right"
      side={right}
    >
      <SlideOver
        isOpen={showAlerts}
        onClose={() => {
          setShowAlerts(false);
          refetchNavigationState();
        }}
        right={
          <Button
            buttonStyle="minimal"
            size={Sizes.SM}
            onClick={() => push("/account/preferences")}
          >
            Preferences
          </Button>
        }
      >
        <div className="border-right w-full h-screen">
          <Alerts />
        </div>
      </SlideOver>

      <section className="pb-8">
        {data?.coworkers.total > 0 ? (
          <PendingMemberList />
        ) : (
          <>
            <SimpleSectionHeader
              title="Membership requests"
              classNames="mb-3"
            />
            <EmptyState
              onClick={() =>
                setModal({ type: "defaultInviteModal", props: {} })
              }
              title="You have no membership requests"
              buttonLabel="Invite Coworkers"
              learnTo="https://getfrank.medium.com/how-vouching-works-5d86557f2d02"
              learnLabel="Learn how vouching works"
            >
              Groups on Frank are invite-only. Once invited, verified members in
              the group must review applications for new members before they are
              verified.
            </EmptyState>
          </>
        )}
      </section>

      <section className="mb-8">
        <SimpleSectionHeader title="Campaigns" classNames="mb-3" />
        {!!data?.campaigns.length ? (
          <CampaignList size="large" campaigns={data?.campaigns} />
        ) : (
          <EmptyState
            onClick={() => push("/campaigns/new")}
            title="You have no campaigns right now"
            buttonLabel="Create a campaign"
            learnTo="https://getfrank.medium.com/how-campaigns-work-1e86bc577afc"
            learnLabel="Learn how campaigns work"
          >
            Published and draft campaigns you write and campaigns you support
            will appear here.
          </EmptyState>
        )}
      </section>

      <div className="pb-8 md:pb-0">
        {noActivityYet && (
          <>
            <SimpleSectionHeader title="Group activity" classNames="mb-3" />

            <div className="py-2">
              <EmptyState title="No activity yet">
                As you use Frank, your group's activity will populate here
              </EmptyState>
            </div>
          </>
        )}
        {!noActivityYet && (
          <Pager<FrankBackendTypes.Activity>
            header={<SimpleSectionHeader title="Group activity" />}
            pagination={data?.myProfile.homeFeed}
            onShowMore={() =>
              setModal({ type: "homeFeedModalArgs", props: {} })
            }
            paginationStrategy={PaginationStrategy.SHOW_MORE}
          >
            <ActivityFeed activities={data?.myProfile.homeFeed.objects} />
          </Pager>
        )}
      </div>
    </DetailLayout>
  );
};

export default Home;
