import { windowScroll } from "seamless-scroll-polyfill";

export default function scrollDown(
  targetElem: HTMLElement,
  smooth = true,
  offset = 65
) {
  const top = window.scrollY;

  const amount = targetElem.getBoundingClientRect().top;

  windowScroll({
    top: top + amount - offset,
    behavior: smooth ? "smooth" : "auto"
  });
}
