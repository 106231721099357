"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineItem = exports.TimelineWrapper = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Icon_1 = require("./Icon");
const Nudge_1 = require("./Nudge");
const statusMap = {
    complete: (React.createElement(Icon_1.Icon, { size: constants_1.Sizes.XL, classNames: "text-frank-green-300", icon: "check_circle_outline" })),
    "in-progress": (React.createElement(Icon_1.Icon, { size: constants_1.Sizes.XL, classNames: "text-canvas-400", icon: "radio_button_checked" })),
    open: (React.createElement(Icon_1.Icon, { size: constants_1.Sizes.XL, classNames: "text-canvas-400", icon: "radio_button_unchecked" }))
};
const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
};
const dayDateFormatter = new Intl.DateTimeFormat("en-us", options);
const TimelineWrapper = ({ children }) => {
    return (React.createElement("div", { className: "flow-root" },
        React.createElement("ul", { className: "-mb-8" }, children)));
};
exports.TimelineWrapper = TimelineWrapper;
const TimelineItem = ({ title, description, status, dateCompleted, last = false }) => {
    return (React.createElement("li", null,
        React.createElement("div", { className: "relative mb-4" },
            !last && (React.createElement("span", { className: `absolute w-0.5 ${status === "complete" ? "bg-frank-green-300" : "bg-canvas-400"}`, "aria-hidden": "true", style: { top: "40px", left: "11px", height: "80%" } })),
            React.createElement("div", { className: "flex items-center" },
                React.createElement("div", { className: "min-w-0 flex-1 flex-col" },
                    React.createElement("div", { className: "flex justify-start items-center pt-2.5" },
                        React.createElement("div", null,
                            React.createElement(Nudge_1.Nudge, { y: 3 }, statusMap[status])),
                        React.createElement("p", { className: "t-small plus text-white pl-3" }, title),
                        dateCompleted && (React.createElement("div", { className: "text-right whitespace-nowrap t-mini text-canvas-400 pl-2" }, dayDateFormatter.format(new Date(dateCompleted))))),
                    React.createElement(Nudge_1.Nudge, { x: -4 },
                        React.createElement("div", { className: "pl-10 t-small text-canvas-400" }, description)))))));
};
exports.TimelineItem = TimelineItem;
