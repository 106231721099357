import {
  ResponsiveProvider,
  RightSidePanel,
  ScreenSizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useMemo } from "react";
import { useChannels } from "@get-frank-eng/chat-client";
import Channel from "../../Chat";
import Loading from "../../components/Loading";
import FromFrankRightSide from "../components/FromFrankRightSide";

const FromFrankDetail: React.FC = () => {
  const { channels } = useChannels();
  const fromFrankChannel = useMemo(
    () => channels.find((ch) => ch.type === "from-frank"),
    [channels]
  );

  if (!fromFrankChannel) {
    return <Loading />;
  }

  return (
    <>
      <Channel
        title="#from-frank"
        channelId={fromFrankChannel.id}
        key={fromFrankChannel.id}
      />
      <RightSidePanel>
        <ResponsiveProvider screenSize={ScreenSizes.XS}>
          <FromFrankRightSide channelId={fromFrankChannel.id} />
        </ResponsiveProvider>
      </RightSidePanel>
    </>
  );
};

export default FromFrankDetail;
