"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineAlert = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Icon_1 = require("./Icon");
const Nudge_1 = require("./Nudge");
const colorMap = {
    [constants_1.Intent.NONE]: "canvas-600",
    [constants_1.Intent.FAILURE]: "brand-600",
    [constants_1.Intent.WARNING]: "frank-gold-500",
    [constants_1.Intent.SUCCESS]: "frank-green-500",
    [constants_1.Intent.INFO]: "canvas-500"
};
const InlineAlert = ({ title, children, icon, intent = constants_1.Intent.NONE, actions }) => {
    const color = colorMap[intent];
    if (!icon && (intent === constants_1.Intent.FAILURE || intent === constants_1.Intent.WARNING)) {
        // eslint-disable-next-line no-param-reassign
        icon = "error";
    }
    if (!icon && intent === constants_1.Intent.SUCCESS) {
        // eslint-disable-next-line no-param-reassign
        icon = "check_circle";
    }
    return (React.createElement("div", { className: "bg-canvas-700 shadow-sm sm:rounded border flex flex-row items-stretch overflow-hidden" },
        React.createElement("div", { className: `w-1 flex-shrink-0 bg-${color}` }),
        React.createElement("div", { className: `flex-grow flex flex-row px-${icon ? "4" : "6"} py-5 items-start space-x-3` },
            icon && (React.createElement(Nudge_1.Nudge, { y: 1, className: `text-${color}` },
                React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, icon: icon }))),
            React.createElement("div", null,
                React.createElement("h3", { className: "t-regular plus" }, title),
                React.createElement("div", { className: "mt-2 max-w-xl text-sm text-gray-500" },
                    React.createElement("p", null, children)),
                actions && (React.createElement("div", { className: "mt-5 flex flex-row space-x-2" }, actions))))));
};
exports.InlineAlert = InlineAlert;
