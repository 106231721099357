import { gql } from "@apollo/client";

const topicFragment = gql`
  fragment TopicFragment on Topic {
    id
    name
    description
    channelId
    supportsFiles
    slug
  }
`;

export default topicFragment;
