"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignWorkflowStateLabel = void 0;
const frank_types_1 = require("frank-types");
const React = __importStar(require("react"));
const __1 = require("../..");
const CampaignWorkflowStateLabel = ({ workflowState }) => {
    switch (workflowState) {
        case frank_types_1.FrankBackendTypes.CampaignWorkflowState.Draft:
            return (React.createElement(__1.OutlinePillBadge, { size: __1.Sizes.MD, textColor: "frank-green-300", borderColor: "frank-green-300" }, "Draft"));
        case frank_types_1.FrankBackendTypes.CampaignWorkflowState.Delivered:
            return (React.createElement(__1.OutlinePillBadge, { size: __1.Sizes.MD, textColor: "frank-green-300", borderColor: "frank-green-300" }, "Delivered"));
        case frank_types_1.FrankBackendTypes.CampaignWorkflowState.Published:
            return (React.createElement(__1.OutlinePillBadge, { size: __1.Sizes.MD, textColor: "frank-green-300", borderColor: "frank-green-300" }, "Gaining Signatures"));
        default:
            return null;
    }
};
exports.CampaignWorkflowStateLabel = CampaignWorkflowStateLabel;
