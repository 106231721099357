import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import * as React from "react";
import { v4 as uuid } from "uuid";
import useDataTableChanger from "./useDataTableChanger";
import { FrankBackendTypes } from "frank-types";

function useCoworkerErrorsState() {
  const [coworkerErrors, setCoworkerErrors] = React.useState<
    FrankBackendTypes.CoworkerError[]
  >([]);

  const setCoworkerErrorsUniq = React.useCallback(
    (errs: FrankBackendTypes.CoworkerError[]) => {
      setCoworkerErrors(
        uniqBy<FrankBackendTypes.CoworkerError>(
          errs,
          (err) => `${err.coworkerId}-${err.type}`
        )
      );
    },
    [setCoworkerErrors]
  );

  const addError = React.useCallback(
    (error: FrankBackendTypes.CoworkerError) => {
      setCoworkerErrorsUniq([...coworkerErrors, error]);
    },
    [setCoworkerErrorsUniq, coworkerErrors]
  );
  const clearError = React.useCallback(
    (change: FrankBackendTypes.CoworkerChangeDto) => {
      setCoworkerErrorsUniq(
        coworkerErrors.filter(
          (err) =>
            err.coworkerId !== change.id &&
            !Object.keys(change).includes(err.column)
        )
      );
    },
    [setCoworkerErrorsUniq, coworkerErrors]
  );

  return { addError, clearError, coworkerErrors };
}

export default function useRecentlyAdded({
  coworkers = [],
  allowAdd,
  defaultForNewRows = {},
  refetchFromBackend,
  prependWithEmptyRow,
}: {
  coworkers: FrankBackendTypes.Coworker[];
  allowAdd: boolean;
  defaultForNewRows?: Omit<FrankBackendTypes.CoworkerChangeDto, "id">;
  prependWithEmptyRow?: boolean;
  refetchFromBackend: (
    args?: FrankBackendTypes.QueryCoworkersArgs
  ) => Promise<any>;
}) {
  const { coworkerErrors, clearError, addError } = useCoworkerErrorsState();
  const makeNewInsertRow = React.useCallback(() => {
    return {
      id: uuid(),
      noteCount: 0,
      editable: true,
      ...defaultForNewRows,
    } as FrankBackendTypes.Coworker;
  }, [defaultForNewRows]);

  const { change, changeError, changeLoading } = useDataTableChanger({
    view: "table",
  });

  const [recentlyAdded, setRecentlyAdded] = React.useState<
    FrankBackendTypes.Coworker[]
  >(prependWithEmptyRow ? [makeNewInsertRow()] : []);

  const applyChangeToCoworker = React.useCallback(
    (ch: FrankBackendTypes.CoworkerChangeDto | FrankBackendTypes.Coworker) => {
      const recentIds = recentlyAdded.map((r) => r.id);

      if (!recentIds.includes(ch.id)) {
        return;
      }

      const withChange = recentlyAdded.map((priorRecentlyAdded) => {
        if (priorRecentlyAdded.id !== ch.id) {
          return priorRecentlyAdded;
        }
        return { ...priorRecentlyAdded, ...ch };
      });

      setRecentlyAdded(withChange);
    },
    [recentlyAdded, setRecentlyAdded]
  );

  const computedCoworkers = React.useMemo(() => {
    return uniqBy([...recentlyAdded, ...coworkers], "id");
  }, [recentlyAdded, coworkers]);

  const computedCoworkersById = React.useMemo(() => {
    return keyBy(computedCoworkers, "id");
  }, [computedCoworkers]);

  const refetch = React.useCallback(async () => {
    await refetchFromBackend();
    setRecentlyAdded([]);
  }, [setRecentlyAdded, refetchFromBackend]);

  const changeAndSetRecentlyAdded = React.useCallback(
    async (ch: FrankBackendTypes.CoworkerChangeDto) => {
      const existing = computedCoworkersById[ch.id];
      applyChangeToCoworker(ch);
      try {
        const { isNew, coworker, error } = await change(ch);
        if (error) {
          addError(error);
        } else {
          clearError(ch);
        }
        applyChangeToCoworker(coworker);
      } catch (e) {
        console.error("error updating coworker", e);
        applyChangeToCoworker(existing);
      }
    },
    [change, applyChangeToCoworker, computedCoworkersById, addError, clearError]
  );

  const addCoworker = React.useCallback(
    async function addCoworker() {
      if (!allowAdd) {
        return;
      }
      const newRow = makeNewInsertRow();
      setRecentlyAdded([newRow, ...recentlyAdded]);
      await changeAndSetRecentlyAdded(
        omit(newRow, [
          "editable",
          "noteCount",
        ]) as FrankBackendTypes.CoworkerChangeDto
      );
    },
    [allowAdd, recentlyAdded, changeAndSetRecentlyAdded, makeNewInsertRow]
  );

  return {
    computedCoworkers,
    changeAndSetRecentlyAdded,
    changeLoading,
    changeError,
    addCoworker,
    coworkerErrors,
    refetch,
  };
}
