import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import { DateIntervals } from "../../utils/Date";
import { FormGroup, Input, Select } from "@get-frank-eng/design-system";

const BulkInviteAdvancedOptions = ({
  register,
  errors,
  setValue,
}: {
  register: () => any;
  errors: any;
  setValue?: (name: string, value: any) => any;
}) => {
  return (
    <div className="my-6">
      <SecurityFeatureRow
        id="bulkRequirePassphrase"
        title="Require Passcode"
        contentToShowWhenChecked={() => (
          <div className="flex flex-row w-64">
            <FormGroup
              label="Pass Phrase"
              name="bulkRequirePassphrase"
              id="bulkRequirePassphrase"
            >
              <Input
                errorText={errors.passphrase && `Required field`}
                register={register}
                registerArgs={{ required: true }}
                autoFocus
              />
            </FormGroup>
          </div>
        )}
      >
        Add a secret passcode to this invite link. Share the passcode with
        trusted coworkers via a secure channel or in person. We recommend that
        you do not share the link and the passcode in the same message or in the
        same channel.
      </SecurityFeatureRow>

      <SecurityFeatureRow
        id="bulkExpiration"
        title="Set Expiration"
        contentToShowWhenChecked={() => (
          <div className="w-64">
            <Select
              name="bulkExpiration"
              defaultValue={DateIntervals.ONE_HOUR}
              register={register}
            >
              <option value={DateIntervals.ONE_HOUR}>1 Hour</option>
              <option value={DateIntervals.ONE_DAY}>24 Hours</option>
              <option value={DateIntervals.THREE_DAYS}>3 Days</option>
              <option value={DateIntervals.ONE_WEEK}>1 Week</option>
            </Select>
          </div>
        )}
      >
        Set this invite link to expire after a specified amount of time. You can
        choose 1 hour, 1 day, 3 days, or a week.
      </SecurityFeatureRow>
    </div>
  );
};

export default BulkInviteAdvancedOptions;
