import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import useCreateTopic from "../dataAccess/mutations/useCreateTopic";
import { useModals } from "../../Modals";
import { useHistory } from "react-router";

type FormParams = {
  name: string;
  description?: string;
};

const CreateTopicModal = ({
  afterSubmit,
  redirect = true,
}: {
  afterSubmit: () => any;
  redirect?: boolean;
}) => {
  const { closeModal } = useModals();
  const { register, errors, handleSubmit } = useForm<FormParams>();
  const { createTopic, loading } = useCreateTopic();
  const toaster = useToaster();
  const history = useHistory();

  const submit = React.useCallback(
    async (formValues: FormParams) => {
      try {
        const topic = await createTopic(formValues);
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: `"${formValues.name}" has been created`,
        });
        closeModal();
        if (redirect) {
          history.push(`/topics/${topic.slug}`);
        }
        afterSubmit();
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: `There was an error creating "${formValues.name}"`,
        });
      }
    },
    [afterSubmit, closeModal, createTopic, toaster, history, redirect]
  );

  const form = (
    <form onSubmit={handleSubmit(submit)} id="create-topic-form">
      <FormGroup label="Name" name="name" id="name">
        <Input
          register={register}
          errorText={errors.name && errors.name.message}
          maxLength={80}
          helpText="Some examples could be #parental-leave, #diversity, or #water-cooler."
          registerArgs={{
            required: "Your topic needs a name",
            maxLength: "Topic names have a maximum length of 80 characters",
          }}
        />
      </FormGroup>
      <FormGroup label="Description" name="description" id="description">
        <Textarea
          register={register}
          errorText={errors.description && errors.description.message}
          maxLength={500}
          registerArgs={{
            maxLength:
              "Topic descriptions have a maximum length of 500 characters",
          }}
        />
      </FormGroup>
    </form>
  );

  return (
    <>
      <ModalHeader title="Create a topic" />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button loading={loading} form="create-topic-form" buttonStyle="brand">
          Create Topic
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateTopicModal;
