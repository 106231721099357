import * as React from "react";
import { ActionBarParams } from "../table";
import * as ReactDOM from "react-dom";
import ActionBar from "../components/ActionBar";
import {
  IconButton,
  ScreenSizes,
  Sizes,
  useScreenSize,
  useTopBarContext,
  usePortal,
} from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";
import InviteButton, { MobileInviteButton } from "../../Invites/InviteButton";

const TopActionsPortal = ({ children }) => {
  const { rightButtonsRef } = useTopBarContext();

  const node = usePortal({
    parentRef: rightButtonsRef,
    classNames: "",
  });

  return ReactDOM.createPortal(children, node);
};

export const useRenderActionBar = ({ tabSetting, refetch, addCoworker }) => {
  const { setModal } = useModals();
  const screenSize = useScreenSize();

  return React.useCallback(
    ({ toggleAllRowsSelected, selectedCoworkers }: ActionBarParams) => (
      <>
        <TopActionsPortal>
          <div className="w-8" />
          <div className="flex items-center space-x-2">
            {selectedCoworkers.length > 0 ? (
              <ActionBar
                actions={tabSetting.actions}
                toggleAllRowsSelected={toggleAllRowsSelected}
                refetch={refetch}
                selectedCoworkers={selectedCoworkers}
              />
            ) : (
              <>
                <IconButton
                  onClick={() =>
                    setModal({ type: "coworkersFAQModal", props: {} })
                  }
                  buttonStyle="minimal"
                  icon="info"
                  size={Sizes.MD}
                  className={!tabSetting.allowAdd && "mr-2"}
                />

                {screenSize < ScreenSizes.MD ? (
                  <MobileInviteButton />
                ) : (
                  <InviteButton />
                )}
              </>
            )}
          </div>
        </TopActionsPortal>
      </>
    ),
    [tabSetting.actions, tabSetting.allowAdd, refetch, screenSize, setModal]
  );
};
