import * as Sentry from "@sentry/browser";

export const SentryLoggingLevel = Sentry.Severity;
class SentryClient {
  init(options: Sentry.BrowserOptions = {}) {
    Sentry.init({
      sampleRate: process.env.REACT_APP_SENTRY === "production" ? 1 : 0,
      tracesSampleRate: process.env.REACT_APP_SENTRY === "production" ? 1 : 0,
      dsn: process.env.REACT_APP_SENTRY,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      ...options,
    });
  }

  configureScope(callback: (scope: Sentry.Scope) => void) {
    Sentry.configureScope(callback);
  }

  addBreadcrumb(args: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(args);
  }
}

export default new SentryClient();
