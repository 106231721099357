import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useRecentlyAdded from "./dataAccess/useRecentlyAdded";
import useTableDataFetcher from "./dataAccess/useTableDataFetcher";
import useIsScrolled from "./hooks/useIsScrolled";
import tabSettings from "./table/tabSettings";
import { transformRawColumnData } from "./util";
import { useRenderActionBar } from "./hooks/useRenderActionBar";
import Table from "./table";
import { Button, LinkButton, Sizes } from "@get-frank-eng/design-system";
import { frankflection } from "frankflection";
import { startCase } from "lodash";
import { CoworkerTableCard } from "./components/CoworkerTableCard";
import { CoworkerEmploymentType } from "frank-types/dist/frank-backend-types";

export const UnverifiedCoworkersTable = ({
  showAll = false,
}: {
  showAll?: boolean;
}) => {
  const setting = tabSettings["workers"];
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({
    column: null,
    order: null,
  });

  const { data, loading, fetchNextPage, refetch } = useTableDataFetcher({
    filter: setting.filter,
    order: sortOrder.column ? sortOrder : undefined,
  });

  const counts = React.useMemo(() => {
    const { coworkerSummary } = data || {};
    return {
      workers:
        coworkerSummary?.lead +
        coworkerSummary?.onboarding +
        coworkerSummary?.invited +
        coworkerSummary?.inReview,
      members: coworkerSummary?.member,
    };
  }, [data]);

  const customColumns = data?.customColumns;

  const computedColumns = React.useMemo(
    () => setting.columns.concat(transformRawColumnData(customColumns)),
    [customColumns, setting.columns]
  );

  const {
    changeAndSetRecentlyAdded,
    changeLoading,
    computedCoworkers,
    addCoworker,
    coworkerErrors,
  } = useRecentlyAdded({
    prependWithEmptyRow: true,
    coworkers: data?.coworkers.objects,
    allowAdd: setting.allowAdd,
    defaultForNewRows: { employmentType: CoworkerEmploymentType.Worker },
    refetchFromBackend: refetch,
  });

  const renderActionBar = useRenderActionBar({
    tabSetting: setting,
    refetch,
    addCoworker,
  });

  return (
    <>
      <CoworkerTableCard
        rightTop={
          <Button onClick={addCoworker} size={Sizes.SM}>
            Add row
          </Button>
        }
        subtitle="Track potential group members by entering their information here. Use this list to invite coworkers your group trusts. Coworkers in the process of joining Frank will also appear here."
        title={`${counts.workers || "--"} Unverified ${startCase(
          frankflection.coworker(counts.workers)
        )}`}
        to="/coworkers/workers"
        loading={!data?.coworkers}
      >
        {data?.coworkers && (
          <Table
            key={`${JSON.stringify(setting.filter)} ${JSON.stringify(
              sortOrder
            )}`}
            data={data.coworkers}
            columns={setting.allowAdd ? computedColumns : setting.columns}
            coworkerErrors={coworkerErrors}
            allowAdd={setting.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            isScrolledX={isScrolledX}
            renderActionBar={renderActionBar}
            changeAndSetRecentlyAdded={changeAndSetRecentlyAdded}
            changeLoading={changeLoading}
            computedCoworkers={computedCoworkers}
            customColumns={customColumns}
            refetch={refetch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            infinite={showAll}
            view="workers"
            addCoworker={addCoworker}
          />
        )}
      </CoworkerTableCard>

      {data?.coworkers.hasNext && !showAll && (
        <div className="inline-block sticky left-0">
          <LinkButton
            to="/coworkers/workers"
            style={{ justifyContent: "flex-start" }}
            buttonStyle="outline"
            className="mt-4 ml-4"
          >
            Show all rows
          </LinkButton>
        </div>
      )}
    </>
  );
};
