"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileHeader = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const useBreakpoint_1 = require("../hooks/useBreakpoint");
const Avatar_1 = require("./Avatar");
const ResponsiveProvider_1 = require("./Layout/ResponsiveProvider");
const wrapperClasses = {
    desktop: "py-6 flex flex-row justify-between flex-grow items-center border-b",
    mobile: "py-6 flex flex-col space-y-3 border-b"
};
const sectionClasses = {
    desktop: "flex flex-row space-x-2",
    mobile: "flex flex-col space-y-2"
};
const ProfileHeader = ({ avatarProps, title, subtitle, right }) => {
    const { screenSize } = ResponsiveProvider_1.useResponsive();
    const wClasses = screenSize >= useBreakpoint_1.ScreenSizes.SM
        ? wrapperClasses.desktop
        : wrapperClasses.mobile;
    const sClasses = screenSize >= useBreakpoint_1.ScreenSizes.SM
        ? sectionClasses.desktop
        : sectionClasses.mobile;
    return (React.createElement("div", { className: wClasses },
        React.createElement("div", { className: sClasses },
            avatarProps && React.createElement(Avatar_1.Avatar, Object.assign({}, avatarProps, { size: constants_1.Sizes.XL })),
            React.createElement("div", null,
                React.createElement("h1", { className: "t-title-4 plus text-white" }, title),
                subtitle && (React.createElement("h2", { className: "t-regular text-canvas-400" }, subtitle)))),
        React.createElement("div", { className: sClasses }, right)));
};
exports.ProfileHeader = ProfileHeader;
