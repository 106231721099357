import * as React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  Button,
  JumboAvatar,
  Nudge,
  Tooltip,
} from "@get-frank-eng/design-system";
import SecurityShield from "../../components/icons/SecurityShield";
import { useModals } from "../../Modals";

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

const ProfileSide = ({ uploadAvatarCallback, profile }) => {
  const { setModal } = useModals();
  const openFlagModal = React.useCallback(() => {
    setModal({
      type: "flagModal",
      props: {
        toFlag: [{ id: profile.id, label: profile.shortName }],
      },
    });
  }, [setModal, profile]);

  return (
    <div className="sticky border" style={{ top: "125px" }}>
      <div className="flex flex-col items-center">
        <div
          className={classNames("py-4 relative", {
            "cursor-pointer": profile?.isYou,
          })}
        >
          <button
            type="button"
            className={classNames("focus:outline-none", {
              "pointer-events-none": !profile?.isYou,
            })}
            onClick={profile?.isYou ? uploadAvatarCallback : undefined}
            disabled={!profile?.isYou}
          >
            <JumboAvatar
              altText={profile?.shortName}
              src={profile?.profilePic?.url}
            />
          </button>
          <Nudge x={100} y={-25}>
            <SecurityShield />
          </Nudge>
        </div>
        <div className="t-title-4 plus-plus text-center">{profile?.name}</div>
        <p className="t-small">
          Joined{" "}
          {profile?.createdAt &&
            dateFormatter.format(new Date(profile.createdAt))}
        </p>
        <div className="border-b w-4/5 my-6" />
      </div>
      <div className="mt-1 mb-2 p-4">
        <p className="t-regular plus-plus py-1">Verified Worker</p>
        {profile.invite.inviter && (
          <div className="flex flex-col py-1">
            <div className="flex-row flex items-center">
              <div className="flex-grow">
                <p className="t-small leading-loose">
                  Invited by{" "}
                  <Link to={`/users/${profile.invite.inviterId}`}>
                    {profile.invite.inviter.name}
                  </Link>
                </p>
                <p className="t-micro text-canvas-400">
                  Invited{" "}
                  {profile.invite.createdAt &&
                    dateFormatter.format(new Date(profile.invite.createdAt))}
                </p>
              </div>
              <div className="flex-shrink-0">
                <SecurityShield />
              </div>
            </div>
          </div>
        )}
        {profile.emailVerificationDate && (
          <div className="flex flex-col py-1">
            <div className="flex-row flex items-center">
              <div className="flex-grow">
                <p className="t-small leading-loose">Email verified</p>
                <p className="t-micro text-canvas-400">
                  Verified{" "}
                  {dateFormatter.format(
                    new Date(profile.emailVerificationDate)
                  )}
                </p>
              </div>
              <div className="flex-shrink-0">
                <SecurityShield />
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col py-1">
          <div className="flex flex-col py-2">
            {!profile?.isYou && (
              <Tooltip
                trigger="hover"
                nudgeY={10}
                tooltip="You will be asked to share information about why you've flagged this worker. Flagging this worker will automatically freeze their account for the safety of the group."
              >
                <Button
                  className="text-brand-400"
                  full
                  onClick={openFlagModal}
                  buttonStyle="outline"
                  iconRight="help"
                >
                  Flag
                </Button>
              </Tooltip>
              // <div className="t-small plus text-brand-400">
              //   <button
              //     type="button"
              //     data-cy="flag-user-button"
              //     className="flex-row flex justify-between items-center"
              //     onClick={openFlagModal}
              //   >
              //     Report {profile?.name}
              //     <div className="ml-1">
              //       <Flag />
              //     </div>
              //   </button>
              // </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSide;
