import * as React from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { useModals } from "../Modals";
import CreatePollForm from "./CreatePoll";

const CreatePollModal = ({
  afterSubmit,
  isAnonymous = false
}: {
  afterSubmit: (pollId?: string) => any;
  isAnonymous?: boolean;
}) => {
  const { closeModal } = useModals();
  const { form, footer } = CreatePollForm({
    isAnonymous,
    afterSubmit: (pollId) => {
      afterSubmit(pollId);
      closeModal();
    },
  });

  return (
    <>
      <ModalHeader title="Create a poll" />
      {isAnonymous && <div className="px-6 pt-4 t-small">You are creating a poll in an anonymous channel. Poll votes and the creator of a poll are anonymous to other group members. </div>}
      <ModalBody>{form}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </>
  );
};

export default CreatePollModal;
