"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatherIcon = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Icon = __importStar(require("react-feather"));
function FeatherIcon({ icon, size, rotate, classNames }) {
    const IconComponent = Icon[icon];
    const pickedSize = {
        [constants_1.Sizes.XS]: 12,
        [constants_1.Sizes.SM]: 14,
        [constants_1.Sizes.MD]: 16,
        [constants_1.Sizes.LG]: 20,
        [constants_1.Sizes.XL]: 24
    }[size];
    const rotateClasses = rotate
        ? {
            25: "transform rotate-90",
            50: "transform rotate-180",
            75: "transform -rotate-90"
        }[rotate]
        : "";
    return (React.createElement(IconComponent, { className: `${rotateClasses} ${classNames}`, size: pickedSize }));
}
exports.FeatherIcon = FeatherIcon;
