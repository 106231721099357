"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkedNames = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const LinkedNames = ({ users, limit = 3 }) => {
    const endOfSelection = users.length > limit ? limit : users.length;
    const rest = users.length - limit;
    const others = rest > 1 ? "others" : "other";
    const joiner = users.length === 2 ? " and " : ", ";
    return (React.createElement(React.Fragment, null,
        users.slice(0, limit).map((user, index) => (React.createElement("span", { key: user.id },
            React.createElement(react_router_dom_1.Link, { to: `/users/${user.id}` },
                React.createElement("span", { className: "t-mini plus" }, user.isYou ? "You" : user.name),
                React.createElement("span", { className: "t-mini" }, index < endOfSelection - 1 ? joiner : " "))))),
        rest > 0 && (React.createElement("span", null,
            " ",
            "and ",
            rest,
            " ",
            others,
            " "))));
};
exports.LinkedNames = LinkedNames;
