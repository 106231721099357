/* eslint-disable dot-notation */
import * as React from "react";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import EditableTextCell from "../cellTypes/EditableTextCell";
import TrustButtons from "../cellTypes/TrustButtons";
import {
  AttachedBadge,
  Avatar,
  Icon,
  Nudge,
  Select,
  Sizes,
  useResponsive,
  ScreenSizes,
  CoworkerStatus,
} from "@get-frank-eng/design-system";
import keyBy from "lodash/keyBy";
import { FrankBackendTypes } from "frank-types";

const CoworkerEmploymentTypeSelect = ({
  change,
  cell,
}: {
  cell: Cell<FrankBackendTypes.Coworker>;
  change: (chg: FrankBackendTypes.CoworkerChangeDto, idx: number) => any;
}) => {
  const [isDirty, setIsDirty] = React.useState(false);
  const onChange = React.useCallback(
    (e) => {
      change(
        { id: cell.row.original.id, employmentType: e.currentTarget.value },
        cell.row.index
      );
      setIsDirty(true);
    },
    [change, setIsDirty, cell]
  );
  return (
    <>
      <Select
        className={`px-0 border-0 hover:text-white focus:outline-0 focus:ring-0 ${
          !isDirty && !cell.value ? "text-canvas-400" : ""
        }`}
        defaultValue={cell.value}
        onChange={onChange}
        placeholder="Employment type"
      >
        <option value={FrankBackendTypes.CoworkerEmploymentType.Worker}>
          Worker
        </option>
        <option
          value={FrankBackendTypes.CoworkerEmploymentType.DoesNotWorkHere}
        >
          Does not work here
        </option>
        <option value={FrankBackendTypes.CoworkerEmploymentType.Management}>
          Management
        </option>
      </Select>
    </>
  );
};

const InnerCellSwitch = ({
  cell,
  change,
  trustLoading,
  coworkerTrustVote,
  openCoworkerHistory,
  customColumns,
}: {
  cell: Cell<FrankBackendTypes.Coworker>;
  trustLoading: boolean;
  coworkerTrustVote: (args: {
    coworkerId: FrankBackendTypes.Coworker["id"];
    trust: number;
  }) => any;
  change: any;
  openCoworkerHistory: (coworkerId: string) => any;
  customColumns: FrankBackendTypes.CoworkerCustomColumn[];
}) => {
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.SM;
  const row = cell.row.original;

  const isEditable = row.editable && cell.column["editable"];
  const changeCell = React.useCallback(
    (newValue) => {
      if (!isEditable) {
        return;
      }
      if (row[cell.column.id] === newValue) {
        return;
      }
      if (!row[cell.column.id] && !newValue) {
        return;
      }
      change({ id: row.id, [cell.column.id]: newValue }, cell.row.index);
    },
    [change, cell, row, isEditable]
  );

  const customDataByKey = React.useMemo(() => keyBy(row.customData, "key"), [
    row,
  ]);

  const changeCustomCell = React.useCallback(
    (newValue) => {
      // @ts-ignore there is a name value on column
      if (customDataByKey[cell.column.name] === newValue) {
        return;
      }

      // @ts-ignore there is a name value on column
      if (!customDataByKey[cell.column.name] && !newValue) {
        return;
      }
      change({
        id: row.id,
        custom: { value: newValue, columnId: cell.column.id },
      });
    },
    [customDataByKey, cell, change, row.id]
  );

  const isCustomColumn = React.useMemo(
    () => customColumns.map((c) => c.id).includes(cell.column.id),
    [cell.column.id, customColumns]
  );

  if (isCustomColumn) {
    return (
      <EditableTextCell
        changeCell={changeCustomCell}
        placeholder={cell.column["placeholder"]}
        cell={cell}
      />
    );
  }

  switch (cell.column.id) {
    case "selection":
      return (
        <label className="flex items-center justify-center flex-grow h-full cursor-pointer">
          {cell.render("Cell")}
        </label>
      );
    case "yourTrust":
      // if (!row.canVoteOnTrust) {
      //   return null;
      // }
      return (
        <TrustButtons
          id={row.id}
          disabled={!row.canVoteOnTrust}
          totalTrustDownvotes={row.totalTrustDownvotes}
          totalTrustUpvotes={row.totalTrustUpvotes}
          yourTrustLevel={row.yourTrustLevel}
          status={row.status}
          coworkerTrustVote={coworkerTrustVote}
          loading={trustLoading}
        />
      );
    case "notes":
      return (
        <button
          type="button"
          disabled={row.status === FrankBackendTypes.CoworkerStatus.Member}
          onClick={
            row.status !== FrankBackendTypes.CoworkerStatus.Member
              ? () => openCoworkerHistory(row.id)
              : () => {}
          }
          className="h-full py-3 w-full cursor-pointer focus:outline-none text-left space-x-2 flex flex-row items-center"
        >
          <AttachedBadge
            nudgeY={-15}
            show={
              row.notifications ===
              FrankBackendTypes.CoworkerNotifications.Mentioned
            }
            position="upper-right"
            size={Sizes.SM}
            color="brand-400"
          >
            <Nudge y={3}>
              <Icon size={Sizes.SM} icon="notes" />
            </Nudge>
          </AttachedBadge>
          <span
            className={`${
              row.notifications === FrankBackendTypes.CoworkerNotifications.Read
                ? "text-canvas-400"
                : "text-white"
            }`}
          >
            {row.status !== FrankBackendTypes.CoworkerStatus.Member
              ? row.noteCount
              : 0}
          </span>
        </button>
      );
    case "name":
      if (row.user) {
        return (
          <Link
            to={`/users/${row.user?.id}`}
            className="flex flex-row items-center"
          >
            <Avatar
              size={Sizes.XS}
              src={row.user?.profilePic?.url}
              altText={row.user?.name}
              link={`/users/${row.user.id}`}
            />
            <div className="ml-2 flex flex-col">
              <div>{row.user?.name}</div>
              <CoworkerStatus
                status={cell.row.original.status}
                iconSize={Sizes.MD}
              />
            </div>
          </Link>
        );
      }
      return (
        <div className="flex flex-row flex-grow w-full items-center">
          <Avatar size={Sizes.XS} altText="placeholder" />
          <div className="ml-2 flex-grow font-bold flex-col">
            <EditableTextCell
              disabled={!isEditable}
              changeCell={changeCell}
              cell={cell}
              style={{ height: "auto" }}
              placeholder={cell.column["placeholder"]}
            />
            <CoworkerStatus
              status={cell.row.original.status}
              iconSize={Sizes.MD}
            />
          </div>
        </div>
      );
    case "employmentType":
      if (row.status === FrankBackendTypes.CoworkerStatus.Member) {
        return (
          <div>
            {{
              [FrankBackendTypes.CoworkerEmploymentType.Worker]: "Worker",
              [FrankBackendTypes.CoworkerEmploymentType.DoesNotWorkHere]:
                "Does not work here",
              [FrankBackendTypes.CoworkerEmploymentType.Management]:
                "Management",
            }[cell.value] || cell.value}
          </div>
        );
      }
      return <CoworkerEmploymentTypeSelect cell={cell} change={change} />;
    case "status":
      return <CoworkerStatus status={cell.value} iconSize={Sizes.MD} />;

    case "email":
      return (
        <div className="flex flex-row items-center flex-grow">
          <EditableTextCell
            /**
             * Why the fuck is there a conditional input type, you ask?
             * Well, idealy we'd have type="email" always
             * But for some odd reason, email input types
             * do not enjoy the same selection control APIs that
             * text does. On desktop, we attach event listeners
             * to this input and listen for arrow keys.
             */
            type={isMobile ? "email" : "text"}
            disabled={!isEditable}
            changeCell={changeCell}
            placeholder={cell.column["placeholder"]}
            cell={cell}
          />
        </div>
      );

    default:
      return (
        <EditableTextCell
          disabled={!isEditable}
          changeCell={changeCell}
          placeholder={cell.column["placeholder"]}
          cell={cell}
        />
      );
  }
};

export default InnerCellSwitch;
