import * as React from "react";
import CampaignAttachmentEditList from "../components/CampaignAttachmentEditList";
import CampaignLinksEditList from "../components/CampaignLinksEditList";
import ContentContainer from "../components/ContentContainer";
import BackAndContinue from "../components/BackAndContinue";
import { FrankBackendTypes } from "frank-types";
import useAttachments from "../../Channel/dataAccess/queries/useAttachments";
import Loading from "../../components/Loading";

const Attachments = ({
  campaign,
  refetch,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => any;
}) => {
  const {
    refetch: refetchAttachments,
    attachments,
    error,
    loading,
    loadMore,
  } = useAttachments({
    campaignId: campaign.id,
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Add attachments and links</h2>

      <p className="t-regular text-canvas-400 mb-6">
        Add links, images, videos, PDFs, and more to help bring your campaign to
        life. These resources will show up on your campaign page for your
        supporters to access.
      </p>

      <h3 className="t-large plus">Links</h3>
      <div className="mb-6">
        <CampaignLinksEditList
          links={campaign.links}
          campaignId={campaign.id}
          refetch={refetch}
        />
      </div>
      <h3 className="t-large plus">Attachments</h3>
      <CampaignAttachmentEditList
        attachments={attachments?.objects}
        campaignId={campaign.id}
        editable
        onUpload={refetchAttachments}
      />
      <div className="h-6" />
      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Attachments;
