import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import { activityFeedItemFragment } from "../../../Activity/dataAccess/queries/useActivityQuery";

import * as React from "react";

const LOAD_CAMPAIGN_ACTIVITY = gql`
  query CampaignActivity(
    $campaignId: ID!
    $pagination: PaginationArgumentDTO!
  ) {
    campaign(campaignId: $campaignId) {
      id
      activity(pagination: $pagination) {
        ...FeedFragment
      }
    }
  }
  ${activityFeedItemFragment}
`;

const perPage = 10;

export default function useCampaignActivity(campaignId: string) {
  const { data, loading, error, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "campaign">,
    FrankBackendTypes.QueryCampaignArgs & FrankBackendTypes.CampaignActivityArgs
  >(LOAD_CAMPAIGN_ACTIVITY, {
    variables: { campaignId, pagination: { page: 0, perPage } },
  });

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        campaignId,
        pagination: {
          page: Math.round(data?.campaign.activity.objects.length / perPage),
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        const toReturn = {
          ...prev,
          ...fetchMoreResult,
          campaign: {
            ...prev.campaign,
            ...fetchMoreResult.campaign,
            activity: {
              ...prev.campaign.activity,
              ...fetchMoreResult.campaign.activity,
              objects: [
                ...prev.campaign.activity.objects,
                ...fetchMoreResult.campaign.activity.objects,
              ],
            },
          },
        };
        return toReturn;
      },
    });
  }, [fetchMore, campaignId, data]);

  return {
    loading,
    error,
    loadMore,
    activity: data?.campaign.activity,
  };
}
