import {
  Button,
  Checkbox,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useCertify from "../dataAccess/mutations/useCertify";

export default function CertificationsModal({
  onSubmit,
}: {
  onSubmit: (certification: FrankBackendTypes.OnboardingCertification) => any;
}) {
  const [
    nonMgmtCertification,
    setNonMgmtCertification,
  ] = React.useState<FrankBackendTypes.OnboardingCertification>(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const { closeModal } = useModals();

  // const { setCertifications, loading } = useCertify({ onboardingId });

  const submit = React.useCallback(
    async (e) => {
      try {
        setLoading(true);
        e.preventDefault();
        // await setCertifications([
        //   nonMgmtCertification,
        //   FrankBackendTypes.OnboardingCertification.CurrentEmployee,
        // ]);
        await onSubmit(nonMgmtCertification);
        setLoading(false);
        closeModal();
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [closeModal, onSubmit, nonMgmtCertification, setLoading]
  );

  return (
    <>
      <ModalHeader
        title="No managers allowed."
        specialty
        icon={
          <Icon icon="warning" classNames="text-brand-400" size={Sizes.XL} />
        }
      />
      <ModalBody>
        <form id="certifications-form" onSubmit={submit} noValidate>
          <p className="t-small mb-3">
            No managers allowed. Management at any level are explicitly not
            allowed to use Frank. Frank will pursue all violations of this
            requirement to the fullest extent under applicable law. Review our{" "}
            <a
              href="https://app.getfrank.com/legal/toc"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Community Agreement
            </a>{" "}
            in detail to fully understand the potential consequences.
          </p>
          <div className="t-small">
            You will not be able to change your answer after submitting.
          </div>
          <div className="py-6">
            <label
              className="flex flex-row items-center"
              htmlFor="certifications-non-mgmt"
            >
              <Checkbox
                className="mr-3"
                dataCy="certifications-non-mgmt"
                id="certifications-non-mgmt"
                name="certifications"
                checked={
                  nonMgmtCertification ===
                  FrankBackendTypes.OnboardingCertification.NonMgmt
                }
                onChange={() =>
                  setNonMgmtCertification(
                    !nonMgmtCertification
                      ? FrankBackendTypes.OnboardingCertification.NonMgmt
                      : null
                  )
                }
                value={FrankBackendTypes.OnboardingCertification.NonMgmt}
              />
              <div className="t-small plus">
                I am not a manager at my company.
              </div>
            </label>
          </div>

          <ModalFooter>
            <div className="flex flex-row w-full justify-end">
              <Button
                onClick={closeModal}
                buttonStyle="minimal"
                className="mr-2"
              >
                Cancel
              </Button>
              <Button
                form="certifications-form"
                buttonStyle="brand"
                dataCy="submit-certifications-form"
                type="submit"
                loading={loading}
                disabled={
                  nonMgmtCertification !==
                  FrankBackendTypes.OnboardingCertification.NonMgmt
                }
                onClick={submit}
              >
                Verify
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
}
