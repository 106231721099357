import {
  IconButton,
  Sizes,
  Tab,
  Tabs,
  TopBar,
  useLayout,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import EmailPreferences from "./pages/EmailPreferences";
import { GroupSettings } from "./pages/GroupSettings";
import { ProfileSettings } from "./pages/ProfileSettings";

export default function Account() {
  const { setMobilePanel } = useLayout();
  const { url } = useRouteMatch();
  return (
    <>
      <div className="md:hidden fixed z-50 top-0 w-screen">
        <TopBar
          classNames="bg-canvas-800"
          left={
            <IconButton
              size={Sizes.XL}
              onClick={() => setMobilePanel("left")}
              buttonStyle="minimal"
              icon="menu"
            />
          }
          title="Account"
        />
      </div>
      <div className="max-w-5xl mx-auto px-4 md:mt-12 mt-24 w-full">
        <h1 className="t-title-4 plus">Settings</h1>
        <Tabs>
          <Tab
            active={window.location.href.includes("profile")}
            label="Profile"
            to={`${url}/profile`}
          />
          <Tab
            active={window.location.href.includes("notification-preferences")}
            label="Emails"
            to={`${url}/notification-preferences`}
          />
          <Tab
            active={window.location.href.includes("group")}
            label="Group"
            to={`${url}/group`}
          />
        </Tabs>
        <div className="h-6" />
        <Switch>
          <Route path={`${url}/profile`}>
            <ProfileSettings />
          </Route>
          <Route path={`${url}/notification-preferences`}>
            <EmailPreferences />
          </Route>
          <Route path={`${url}/group`}>
            <GroupSettings />
          </Route>
          <Route>
            <Redirect to={`${url}/profile`} />
          </Route>
        </Switch>
        <div className="h-48 sm:h-0" />
      </div>
    </>
  );
}
