import * as React from "react";
import { useParams, Redirect } from "react-router";
import useGetTemplate from "../dataAccess/queries/useGetTemplate";
import Loading from "../../components/Loading";
import useImportTemplate from "../dataAccess/mutations/useImportTemplate";
import {
  ScreenSizes,
  useResponsive,
  MobilePreviewCard,
  LeftSideBar,
  Sizes,
} from "@get-frank-eng/design-system";
import IntercomShouldOffsetOnMobile from "../../components/IntercomShouldOffsetOnMobile";
import FullScreenLoading from "../../components/FullScreenLoading";
import PreviewDocument from "../components/PreviewDocument";
import ViewFullDocument from "../components/ViewFullDocument";
import ViewTemplateSidebar, {
  SidebarFooter,
  SidebarHeader,
  SidebarheaderVeryTop,
  SidebarMain,
} from "../components/ViewTemplateSidebar";
import AttachmentsAndLinks from "../../CampaignDetail/components/AttachmentsAndLinks";

const PreviewTemplate = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;

  const { template, loadingTemplate, errorTemplate } = useGetTemplate(
    templateId
  );

  const {
    importTemplate,
    importTemplateCalled,
    importTemplateData,
    importTemplateLoading,
  } = useImportTemplate();

  if (importTemplateLoading) {
    return <FullScreenLoading text="Loading campaign builder..." />;
  }

  if (loadingTemplate || !template) {
    return <Loading />;
  }

  if (errorTemplate) {
    throw Error(errorTemplate.message);
  }

  if (importTemplateCalled && importTemplateData) {
    return (
      <Redirect
        to={`/campaigns/${importTemplateData.importTemplate.id}/builder/demand`}
      />
    );
  }

  const shouldShowAttachmentCards =
    template.attachments.length + template.links.length > 0;

  if (isMobile) {
    return (
      <>
        <div className="flex flex-col mx-4 md:my-0 md:mx-0 relative">
          <IntercomShouldOffsetOnMobile />
          <SidebarheaderVeryTop isMobile />
          <SidebarHeader
            authorBio={template.author.bio}
            authorName={template.author.name}
            authorUrl={template.author?.image?.url}
            title={template.title}
          />
          <section className="py-8">
            <div>
              <div className="t-large plus pb-6">Demand Letter</div>
              {template.contentJSON && (
                <PreviewDocument
                  contentJSON={template.contentJSON}
                  showFullDocument={!isMobile}
                />
              )}
            </div>
          </section>
          {shouldShowAttachmentCards && (
            <section className="pb-6">
              <div>
                <div className="t-large plus">Links and attachments</div>
                <div className="t-regular">
                  Helpful resources, case studies, and articles about this
                  issue, selected by the template author.
                </div>
              </div>
              <div className="grid gap-6 pt-8">
                {template.links.map((link) => (
                  <MobilePreviewCard
                    key={link.id}
                    {...link.preview}
                    subtitle="link"
                  />
                ))}
                {template.attachments.map((attachment) => (
                  <MobilePreviewCard
                    key={attachment.id}
                    {...attachment.preview}
                    subtitle={attachment.contentType}
                  />
                ))}
              </div>
            </section>
          )}
          <section className="mb-6">
            <SidebarMain template={template} />
          </section>
        </div>
        <SidebarFooter
          importTemplate={importTemplate}
          templateId={template.id}
          isMobile
        />
      </>
    );
  }

  return (
    <div className="flex flex-grow">
      <LeftSideBar size={Sizes.MD}>
        <ViewTemplateSidebar
          template={template}
          importTemplate={importTemplate}
        />
      </LeftSideBar>

      <div className="mx-16 lg:mx-auto lg:max-w-4xl">
        <div className="pt-24">
          <div className="t-large plus mb-1">Template demand letter</div>
          <div className="t-regular">
            Demand Letters outline the problem you are seeking to address with
            your campaign. Once demand letters obtain enough signatures, Frank
            can send your letters to decision makers at your company, protecting
            your anonymity.
          </div>
        </div>
        <div className="pt-8">
          {template.contentJSON && (
            <ViewFullDocument contentJSON={template.contentJSON} />
          )}
        </div>
        {shouldShowAttachmentCards && (
          <div className="py-24">
            <div className="mb-8">
              <div className="t-large plus">Links and attachments</div>
              <div className="t-regular">
                Helpful resources, case studies, and articles about this issue,
                selected by the template author.
              </div>
            </div>
            <AttachmentsAndLinks
              attachments={template.attachments}
              links={template.links}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewTemplate;
