/**
 * https://www.figma.com/proto/B9GE74RWzNwJ59HhZPTOJv/Campaigns-1.0?node-id=1276%3A1272&scaling=min-zoom
 *
 * * use DocumentEditor components
 * * show comments & annotations
 * * shows who's online
 */

import * as React from "react";
import Loading from "../../components/Loading";
import BuilderPetitionLayout from "../../components/layouts/BuilderPetitionLayout";
import ErrorBoundary from "../../components/ErrorBoundary";
import BackAndContinue from "../components/BackAndContinue";
import { FrankBackendTypes } from "frank-types";

const DocumentEditor = React.lazy(() => import("../../DocumentEditor"));

const Demand = ({
  campaign,
  refetch,
  myProfile,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => any;
  myProfile: FrankBackendTypes.MyProfile;
}) => {
  const contentJSON = React.useMemo(() => {
    return campaign && JSON.parse(campaign.contentJSON);
  }, [campaign]);

  return (
    <BuilderPetitionLayout
      aboveDocumentContent={
        <div className="mb-12">
          <h2 className="t-title-4 plus">Write your demand letter</h2>
          <p className="t-regular">
            Demand Letters outline the problem and demands of the campaign and
            are eventually sent to decision makers at the company when enough
            signatures are reached.
          </p>
        </div>
      }
      belowDocumentContent={
        <>
          {campaign.workflowState ===
            FrankBackendTypes.CampaignWorkflowState.Published && (
            <div className="t-small italic text-canvas-400 mb-12">
              This document is LIVE and gaining signatures. Last updated{" "}
              {campaign.updatedAtTimeAgo}.
            </div>
          )}
          <BackAndContinue workflowState={campaign.workflowState} />
        </>
      }
    >
      <div className="px-3 pt-12 no-focus-ring" style={{ minHeight: "65vh" }}>
        <ErrorBoundary>
          <React.Suspense fallback={<Loading />}>
            <DocumentEditor
              defaultValue={contentJSON}
              docId={campaign.id}
              name={myProfile.shortName}
              comments={campaign.comments}
              onComment={refetch}
              minHeight="65vh"
            />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    </BuilderPetitionLayout>
  );
};

export default Demand;
