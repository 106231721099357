import {
  Delay,
  LargePulseCard,
  PulseActivity,
  PulseCard,
  TitlePulseLine,
} from "@get-frank-eng/design-system";
import * as React from "react";
import DetailLayout from "../components/layouts/Detail";

export default function HomePageLoading() {
  return (
    <Delay delay={500}>
      <DetailLayout
        direction="right"
        side={
          <div className="space-y-6">
            <TitlePulseLine />
            {new Array(3).fill(null).map((_, i) => (
              <PulseActivity key={i} />
            ))}
          </div>
        }
        header={<div className="mt-16 pb-6 t-title-4 plus border-b">Home</div>}
      >
        <TitlePulseLine />
        <div className="space-y-6 mt-4">
          <PulseCard lines={2} />
          <PulseCard lines={2} />
          <PulseCard lines={2} />
        </div>
        <hr className="my-8" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <LargePulseCard />
          <LargePulseCard />
          <LargePulseCard />
        </div>
      </DetailLayout>
    </Delay>
  );
}
