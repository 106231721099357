import * as React from "react";
import { useModals } from "../Modals";
import { Button, ModalBody, ModalHeader } from "@get-frank-eng/design-system";

interface ConfirmationModalProps {
  action: () => any;
  title: string;
  bodyText: string | React.ReactChildren | React.ReactChild;
  actionText: string;
  actionButtonDisabled?: boolean;
}

const ConfirmationModal = ({
  action,
  title,
  bodyText,
  actionText,
  actionButtonDisabled = false,
}: ConfirmationModalProps) => {
  const { closeModal } = useModals();
  return (
    <>
      <ModalHeader title={title} />
      <ModalBody>
        <p className="t-small">{bodyText}</p>
        <div className="sm:flex sm:flex-row sm:justify-end sm:items-center pt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <Button buttonStyle="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              disabled={actionButtonDisabled}
              buttonStyle="brand"
              onClick={() => {
                action();
                closeModal();
              }}
            >
              {actionText}
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ConfirmationModal;
