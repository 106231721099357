import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";

export default function Authenticity({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const hasDangerousEmail = pendingMember.onboardingIssues.issueList.includes(
    FrankBackendTypes.OnboardingIssueType.DangerousEmail
  );

  const hasUnknownEmail = pendingMember.onboardingIssues.issueList.includes(
    FrankBackendTypes.OnboardingIssueType.UnknownEmail
  );

  return (
    <PendingMemberSection
      title="Authenticity"
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.authenticityIssues.map((i) => i.type),
        null,
        2
      )}
      complete={pendingMember.onboardingIssues.authenticityIssues.length === 0}
      showWarning={hasDangerousEmail}
    >
      <TaskRow
        complete
        title="Invited by a verified group member"
        description={`Invited via ${pendingMember.coworkerInvite.inviteType} by ${pendingMember.coworkerInvite.inviter?.name}`}
      />
      {hasDangerousEmail && (
        <TaskRow
          showWarning={hasDangerousEmail}
          complete={false}
          title="Email flagged as dangerous"
          description={`This might not really be ${pendingMember.name}. Whomever signed up used an email address (${pendingMember.email}) that was flagged by your coworkers. Review in the coworker list for more details.`}
        />
      )}
      <TaskRow
        dataCy="email-address-authenticity"
        complete={
          !pendingMember.onboardingIssues.issueList.includes(
            FrankBackendTypes.OnboardingIssueType.UnknownEmail
          )
        }
        title={
          hasUnknownEmail
            ? "Email address not pre-trusted"
            : "Email authenticity verified"
        }
        description={
          hasUnknownEmail
            ? `${pendingMember.email} was confirmed by ${pendingMember.name}, but no one in the group verified it.`
            : ""
        }
      />

      {pendingMember.coworkerInvite.requiresPassphrase && (
        <TaskRow
          complete
          title="Passcode correctly entered"
          description={`${pendingMember.name} entered the correct passcode`}
        />
      )}
    </PendingMemberSection>
  );
}
