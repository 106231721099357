import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useTableDataFetcher from "./dataAccess/useTableDataFetcher";
import useIsScrolled from "./hooks/useIsScrolled";
import tabSettings from "./table/tabSettings";
import { transformRawColumnData } from "./util";
import Table from "./table";
import { Button, LinkButton } from "@get-frank-eng/design-system";
import { frankflection } from "frankflection";
import startCase from "lodash/startCase";
import { CoworkerTableCard } from "./components/CoworkerTableCard";
import { useCurrentUserData } from "../Auth/useCurrentUserData";

export const MemberTable = ({ showAll = false }: { showAll?: boolean }) => {
  const { currentGroup } = useCurrentUserData();
  const setting = tabSettings["members"];
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({
    column: null,
    order: null,
  });

  const { data, loading, fetchNextPage, refetch } = useTableDataFetcher({
    filter: setting.filter,
    order: sortOrder.column ? sortOrder : undefined,
  });

  const counts = React.useMemo(() => {
    const { coworkerSummary } = data || {};
    return {
      workers:
        coworkerSummary?.lead +
        coworkerSummary?.onboarding +
        coworkerSummary?.invited +
        coworkerSummary?.inReview,
      members: coworkerSummary?.member,
    };
  }, [data]);

  const customColumns = data?.customColumns;

  const computedColumns = React.useMemo(
    () => setting.columns.concat(transformRawColumnData(customColumns)),
    [customColumns, setting.columns]
  );

  return (
    <>
      <CoworkerTableCard
        subtitle={`Current verified members in ${currentGroup.name}. Members have been vouched for by other members.`}
        title={`${counts.members || "--"} Verified ${startCase(
          frankflection.member(counts.members)
        )}`}
        to="/coworkers/members"
        loading={!data?.coworkers}
      >
        {data?.coworkers && (
          <Table
            key={`${JSON.stringify(setting.filter)} ${JSON.stringify(
              sortOrder
            )}`}
            data={data.coworkers}
            columns={setting.allowAdd ? computedColumns : setting.columns}
            allowAdd={setting.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            isScrolledX={isScrolledX}
            computedCoworkers={data.coworkers.objects}
            customColumns={customColumns}
            refetch={refetch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            infinite={showAll}
            view="members"
          />
        )}
      </CoworkerTableCard>

      {data?.coworkers.hasNext && !showAll && (
        <div className="inline-block sticky left-0">
          <LinkButton
            to="/coworkers/members"
            style={{ justifyContent: "flex-start" }}
            buttonStyle="outline"
            className="mt-4 ml-4"
          >
            Show all rows
          </LinkButton>
        </div>
      )}
    </>
  );
};
