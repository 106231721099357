import {
  Avatar,
  Button,
  CampaignOrganizers,
  CampaignWorkflowStateLabel,
  IconLinkButton,
  Intent,
  LinkButton,
  Nudge,
  ProgressBar,
  SectionHeader,
  Sizes,
  useLayout,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useRouteMatch } from "react-router";
import CampaignActionButton from "../components/CampaignActionButton";
import {
  CampaignUserRelationship,
  CampaignWorkflowState,
} from "frank-types/dist/frank-backend-types";
import { frankflection } from "frankflection";
import startCase from "lodash/startCase";
import useCollaboratorInviteActions from "../dataAccess/mutations/useCollaboratorActions";
import { Link } from "react-router-dom";

const CampaignIndex = ({
  campaign,
  refetchCampaign,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetchCampaign: () => void;
}) => {
  const { url } = useRouteMatch();
  const toaster = useToaster();
  const { setMobilePanel } = useLayout();

  const {
    accept,
    reject,
    loadingReject,
    loadingAccept,
  } = useCollaboratorInviteActions({ campaignId: campaign.id });

  const acceptInvite = async () => {
    try {
      await accept();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "You are an organizer on this campaign",
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong accepting this invitation",
      });
      throw e;
    }
    refetchCampaign();
  };

  const rejectInvite = async () => {
    try {
      await reject();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "You have declined this invitation",
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong declining this invitation",
      });
      throw e;
    }
    refetchCampaign();
  };

  return (
    <div className="flex-1 flex flex-col bg-canvas-700">
      <div className="sm:hidden pt-4 px-6">
        <IconLinkButton
          to={`/campaigns/${campaign.id}`}
          onClick={() => setMobilePanel(null)}
          icon="arrow_back"
          size={Sizes.XL}
          buttonStyle="minimal"
        />
      </div>
      <div className="space-y-3 p-6 flex-1">
        <Nudge x={-2}>
          <CampaignWorkflowStateLabel workflowState={campaign.workflowState} />
        </Nudge>
        <Link to={`${url}/demand`} className="t-title-4 plus block">
          {campaign.title}
        </Link>
        <Link to={`${url}/demand`} className="t-small relative block">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-canvas-700" />
          {campaign.contentPreview}
        </Link>

        <div className="relative space-y-3">
          <div className="absolute right-0 top-0 text-canvas-700">
            <Nudge y={-5}>
              <CampaignOrganizers
                size={Sizes.XS}
                showOrganizedBy={false}
                campaignOrganizers={campaign.campaignOrganizers}
              />
            </Nudge>
          </div>
          <div className="t-small plus text-brand-300">
            {`${campaign.signatureCount} ${startCase(
              frankflection.signature(campaign.signatureCount)
            )}`}
          </div>
          <ProgressBar
            numerator={campaign.signatureCount}
            denominatorColor="canvas-600"
            denominator={campaign.signatureGoal}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <CampaignActionButton
            relationshipToMe={campaign.relationshipToMe}
            campaignId={campaign.id}
            refetch={refetchCampaign}
            canUnsign={
              campaign.workflowState !== CampaignWorkflowState.Delivered
            }
          />
          <LinkButton
            to={`${url}/demand`}
            size={Sizes.LG}
            buttonStyle="outline"
          >
            Go to Demand Letter
          </LinkButton>
        </div>
        {(campaign.relationshipToMe ===
          CampaignUserRelationship.InvitedNotSupporter ||
          campaign.relationshipToMe ===
            CampaignUserRelationship.InvitedSupporter) && (
          <div className="py-6">
            <div className="border rounded p-4">
              <div className="space-y-3">
                <Avatar
                  size={Sizes.SM}
                  src={campaign.collaboratorInvite.inviter.profilePic.url}
                />
                <div className="t-small plus">
                  {campaign.collaboratorInvite.inviter.shortName} invited you to
                  be an organizer on this campaign.
                </div>
                <div className="space-x-2">
                  <Button
                    buttonStyle="brand"
                    onClick={async () => await acceptInvite()}
                    loading={loadingAccept}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={async () => await rejectInvite()}
                    loading={loadingReject}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-8" />
        <div className="space-y-4">
          <SectionHeader to={url + "/info"}>Info</SectionHeader>
          <SectionHeader to={url + "/timeline"}>Timeline</SectionHeader>
          <SectionHeader
            tooltip="Your coworkers who support this campaign"
            to={url + "/signatures"}
          >
            {`${campaign.signatureCount} ${startCase(
              frankflection.signature(campaign.signatureCount)
            )}`}
          </SectionHeader>
          <SectionHeader
            tooltip="Vote on collective actions you could take with your coworkers"
            to={url + "/actions"}
          >
            Actions
          </SectionHeader>
          <SectionHeader
            tooltip="All polls that have been shared in this campaign"
            to={url + "/polls"}
          >
            Polls
          </SectionHeader>
          <SectionHeader
            tooltip="All files that have been shared in this campaign"
            to={url + "/files"}
          >
            Files
          </SectionHeader>
          <SectionHeader to={url + "/activity"}>Activity</SectionHeader>
        </div>
      </div>
    </div>
  );
};

export default CampaignIndex;
