import {
  Button,
  Delay,
  FullCenter,
  IconLinkButton,
  Intent,
  PulseActivity,
  Sizes,
  TopBar,
  useToaster,
} from "@get-frank-eng/design-system";
import { SmallAttachmentCard } from "@get-frank-eng/design-system/dist/cards/Attachment";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import uploadAttachments from "../Chat/util/messageAttachment";
import EmptyState from "../components/EmptyState";
import Pager, { PaginationStrategy } from "../components/Pager";
import AttachmentsEmptyState from "./components/AttachmentsEmptyState";
import useAttachments from "./dataAccess/queries/useAttachments";
import useCreateAttachmentForChannel from "./dataAccess/queries/useCreateAttachmentForChannel";

const ChannelAttachments = ({
  campaignId,
  topicId,
  topicSlug,
}: {
  campaignId?: string;
  topicId?: string;
  topicSlug?: string;
}) => {
  const { attachments, loading, error, loadMore, refetch } = useAttachments({
    campaignId,
    topicId,
  });
  const { createAttachmentForChannel } = useCreateAttachmentForChannel({
    campaignId,
    topicId,
  });
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const toaster = useToaster();
  if (loading) {
    return (
      <Delay delay={500}>
        <div className="p-6 space-y-2 bg-canvas-700">
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
        </div>
      </Delay>
    );
  }

  const uploadFile = async (e: any) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    const [attachment] = await uploadAttachments([file]);
    await createAttachmentForChannel(attachment.attachmentId);
    refetch();
    toaster.addToast({
      intent: Intent.SUCCESS,
      children: "File uploaded",
    });
    setUploadLoading(false);
  };

  const openFileChooser = async () => {
    const input = document.createElement("input");
    input.onchange = uploadFile;
    input.type = "file";
    input.click();
  };

  return (
    <div className="bg-canvas-700 h-screen">
      <TopBar
        title="Files"
        left={
          <IconLinkButton
            to={campaignId ? `../${campaignId}` : `../${topicSlug}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        right={
          <Button
            size={Sizes.SM}
            loading={uploadLoading}
            buttonStyle="outline"
            onClick={openFileChooser}
          >
            Upload
          </Button>
        }
      />
      {!attachments.objects.length ? (
        <div className="pt-12">
          <FullCenter>
            <AttachmentsEmptyState openFileChooser={openFileChooser} />
          </FullCenter>
        </div>
      ) : (
        <div className="space-y-2 p-6 bg-canvas-700 flex-grow">
          <Pager<FrankBackendTypes.Attachment>
            pagination={attachments}
            paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
            onShowMore={loadMore}
          >
            <div className="space-y-2 bg-canvas-700 flex-grow">
              {attachments.objects.map((attachment) => (
                <SmallAttachmentCard
                  key={attachment.id}
                  attachment={attachment}
                />
              ))}
            </div>
          </Pager>
        </div>
      )}
    </div>
  );
};

export default ChannelAttachments;
