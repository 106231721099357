"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarFooter = void 0;
const react_1 = require("@headlessui/react");
const React = __importStar(require("react"));
const constants_1 = require("../../constants");
const useBreakpoint_1 = require("../../hooks/useBreakpoint");
const Avatar_1 = require("../Avatar");
const Icon_1 = require("../Icon");
const menus_1 = require("../menus");
const interactive_classes_1 = require("./interactive-classes");
const SidebarFooter = ({ name, avatarUrl, menu }) => {
    const screensize = useBreakpoint_1.useScreenSize();
    return (React.createElement(menus_1.MenuTrigger, { trigger: "click", nudgeY: -7, placement: "top", disabled: !menu, menu: menu || React.createElement(react_1.Menu, null) },
        React.createElement("button", { type: "button", className: "focus:outline-none text-left w-full h-16 flex items-center flex-row px-2 sm:px-4 cursor-auto", disabled: !menu },
            React.createElement("div", { className: interactive_classes_1.interactiveClasses({ disabled: !menu }) },
                React.createElement(Avatar_1.Avatar, { className: "mr-4", src: avatarUrl, altText: name, size: screensize > useBreakpoint_1.ScreenSizes.SM ? constants_1.Sizes.XS : constants_1.Sizes.SM }),
                React.createElement("div", { className: "t-small flex-grow truncate" }, "Account"),
                menu && (React.createElement("div", { className: "transition-opacity md:opacity-0 group-hover:opacity-100 flex items-center justify-center" },
                    React.createElement(Icon_1.Icon, { size: constants_1.Sizes.MD, icon: "arrow_drop_down" })))))));
};
exports.SidebarFooter = SidebarFooter;
