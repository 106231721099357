import { ModalBody } from "@get-frank-eng/design-system";
import * as React from "react";
import { Link } from "react-router-dom";

const SupporterOptionsModal = ({
  confirmUnsign,
  canUnsign
}: {
  confirmUnsign: () => void;
  canUnsign: boolean;
}) => {
  return (
    <>
      <ModalBody>
        <div className="flex flex-col justify-center items-start">
          {canUnsign && (
            <button
              type="button"
              onClick={confirmUnsign}
              className="border-b py-4 w-full text-left"
            >
              Remove signature
            </button>
          )}
          <Link className="py-4 w-full" to="/account/notifications-preferences">
            Alert preferences
          </Link>
        </div>
      </ModalBody>
    </>
  );
};

export default SupporterOptionsModal;
