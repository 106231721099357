import * as React from "react";
import LogoFull from "../LogoFull";
import useSession from "../Auth/useSession";
import { AuthStates } from "../Auth/AuthState";

export const TopNavForPublicPages = () => {
  const { authState } = useSession({});

  if (authState === AuthStates.LOGGED_IN) {
    return null;
  }

  return (
    <div className="bg-canvas-800">
      <div className="fixed top-0 md:static bg-canvas-800 border-b md:border-b-0 w-screen px-6 py-6 flex flex-row justify-between items-center">
        <a href="https://getfrank.com">
          <LogoFull />
        </a>
        <div>
          <a
            className="t-mini plus text-brand-400 mr-4"
            target="_blank"
            rel="noreferrer"
            href="https://app.getfrank.com/create-group/email"
          >
            Sign up
          </a>
          <a
            href={`${process.env.REACT_APP_FRONTEND_URL}/login`}
            className="t-mini plus text-canvas-400"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopNavForPublicPages;
