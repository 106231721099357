"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyState = void 0;
const React = __importStar(require("react"));
const Button_1 = require("./Button");
const EmptyState = ({ children, title, onClick, buttonLabel, learnLabel, learnTo }) => {
    return (React.createElement("div", { className: "border border-dashed border-canvas-600 shadow rounded p-6" },
        React.createElement("div", { className: "w-sm flex flex-col space-y-3" },
            React.createElement("h3", { className: "t-small plus text-white" }, title),
            React.createElement("p", { className: "t-small text-canvas-400" }, children),
            React.createElement("div", { className: "flex flex-col sm:flex-row sm:items-center space-y-3 sm:space-y-0 sm:space-x-3" },
                onClick && buttonLabel && (React.createElement(Button_1.Button, { onClick: onClick }, buttonLabel)),
                learnLabel && learnTo && (React.createElement(Button_1.LinkButton, { buttonStyle: "minimal", to: learnTo }, learnLabel))))));
};
exports.EmptyState = EmptyState;
