"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoworkerCard = void 0;
const React = __importStar(require("react"));
const Card_1 = require("../components/Card");
const Avatar_1 = require("../components/Avatar");
const constants_1 = require("../constants");
const CoworkerStatus_1 = require("../components/CoworkerStatus");
const Button_1 = require("../components/Button");
function CoworkerCard({ userId, profilePicUrl, name, title, status, cta = (React.createElement(Button_1.LinkButton, { size: constants_1.Sizes.SM, buttonStyle: "outline", to: `/users/${userId}` }, "Go to profile")), }) {
    return (React.createElement(Card_1.Card, null,
        React.createElement("div", { className: "flex flex-row items-center space-x-2 p-3" },
            React.createElement("div", { className: "flex-shrink-0" },
                React.createElement(Avatar_1.Avatar, { size: constants_1.Sizes.XL, src: profilePicUrl })),
            React.createElement("div", { className: "flex-grow self-start space-y-0.5" },
                React.createElement("div", { className: "t-small plus text-white" }, name),
                title && React.createElement("div", { className: "t-mini text-canvas-400" }, title),
                status && React.createElement(CoworkerStatus_1.CoworkerStatus, { status: status })),
            React.createElement("div", { className: "flex-shrink-0" }, cta))));
}
exports.CoworkerCard = CoworkerCard;
