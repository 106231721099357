"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaybeExternalLink = void 0;
/* eslint-disable react/jsx-props-no-spreading */
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const MaybeExternalLink = (_a) => {
    var { to, children } = _a, props = __rest(_a, ["to", "children"]);
    // It is a simple element with nothing to link to
    if (!to)
        return React.createElement("span", Object.assign({}, props), children);
    // It is intended to be an external link
    if (/^https?:\/\//.test(to))
        return (React.createElement("a", Object.assign({ href: to }, props), children));
    // Finally, it is an internal link
    return (React.createElement(react_router_dom_1.Link, Object.assign({ to: to }, props), children));
};
exports.MaybeExternalLink = MaybeExternalLink;
