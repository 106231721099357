import { gql } from "@apollo/client";
const attachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    url
    filename
    humanReadableSize
    fileType
    user {
      id
      name
    }
    thumbnail {
      id
      url
    }
  }
`;

export default attachmentFragment;
