import {
  InlineAlert,
  Button,
  Checkbox,
  ErrorText,
  FormGroup,
  Input,
  Intent,
  Select,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { inputClasses } from "@get-frank-eng/design-system/dist/components/forms/Input";
import uniq from "lodash/uniq";
import useCreatePoll from "./dataAccess/mutations/useCreatePoll";
import { DateIntervals } from "../utils/Date";

type FormValues = {
  options: { value: string }[];
  expiration: string;
  title: string;
  canChangeResponse: boolean;
};

const CreatePollForm = ({
  isAnonymous,
  afterSubmit,
}: {
  afterSubmit: (pollId?: string) => any;
  isAnonymous: boolean;
}) => {
  const { createPoll, loading } = useCreatePoll();
  const { register, control, handleSubmit, errors } = useForm<FormValues>({
    defaultValues: {
      options: [{ value: "" }, { value: "" }, { value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  const [outsideError, setOutsideError] = React.useState(null);

  const submit = React.useCallback(
    async (values: FormValues) => {
      if (values.options.length < 2) {
        setOutsideError({
          name: "options",
          type: "not-enough-options",
          message: "You must have at least two options",
        });
        return;
      }
      if (values.options.length >= 8) {
        setOutsideError({
          name: "options",
          type: "too-many-options",
          message: "You can only have up to 8 options",
        });
        return;
      }
      try {
        const poll = await createPoll({
          title: values.title,
          isAnonymous,
          canChangeResponse: values.canChangeResponse,
          options: uniq(values.options.map((v) => v.value).filter((v) => !!v)),
          expiration: typeof values.expiration === "number"
            ? new Date(Date.now() + Number(values.expiration))
            : null,
        });
        afterSubmit(poll.id);
      } catch (e) {
        setOutsideError({
          name: "server",
          type: "server-error",
          message: e.message || "Unexpected error",
        });
      }
    },
    [createPoll, afterSubmit, setOutsideError]
  );

  return {
    form: (
      <form onSubmit={handleSubmit(submit)} id="create-poll-form">
        {outsideError?.type === "server-error" && (
          <InlineAlert title="Error" intent={Intent.FAILURE}>
            There was an error creating this poll.
          </InlineAlert>
        )}

        <FormGroup label="Title" name="title" id="title">
          <Input
            register={register}
            errorText={errors.title && errors.title.message}
            maxLength={80}
            registerArgs={{
              required: "Your poll needs a title",
              maxLength: "Poll titles have a maximum length of 80 characters",
            }}
          />
        </FormGroup>

        <FormGroup label="Expires in" name="expiration" id="expiration">
          <Select register={register}>
            <optgroup label="Expires in">
              <option value="">Does not expire</option>
              <option value={DateIntervals.ONE_HOUR}>1 Hour</option>
              <option value={DateIntervals.ONE_DAY}>24 Hours</option>
              <option value={DateIntervals.THREE_DAYS}>3 Days</option>
              <option value={DateIntervals.ONE_WEEK}>1 Week</option>
            </optgroup>
          </Select>
        </FormGroup>
        <div className="my-8 space-y-2">
          {fields.map((field, i) => (
            <>
              <div
                key={field.id}
                className="flex flex-row space-x-3 justify-between"
              >
                <input
                  autoComplete="off"
                  type="text"
                  placeholder={`Option ${i + 1}...`}
                  className={`p-2 ${inputClasses({
                    disabled: false,
                    hasError: false,
                  })}`}
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: "Options have a maximum length of 50 characters",
                    },
                    minLength: {
                      value: 1,
                      message: "Options have a minimum length of 1 character",
                    },
                  })}
                  name={`options[${i}].value`}
                />
                <Button
                  buttonStyle="minimal"
                  tabIndex={-1}
                  type="button"
                  onClick={() => remove(i)}
                  iconLeft="delete"
                />
              </div>
              {errors.options && errors.options[i]?.value && (
                <ErrorText>{errors.options[i]?.value.message}</ErrorText>
              )}
            </>
          ))}
          <Button
            type="button"
            size={Sizes.SM}
            onClick={() => append({ value: "" })}
            buttonStyle="outline"
            iconLeft="plus_one"
          >
            Add Option
          </Button>
          {outsideError?.name === "options" && (
            <ErrorText>{outsideError?.message}</ErrorText>
          )}
        </div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor="canChangeResponse"
          className="my-3 flex flex-row space-x-2 items-center"
        >
          <Checkbox
            ref={register}
            id="canChangeResponse"
            name="canChangeResponse"
          />
          <div>Can respondents change their responses?</div>
        </label>
      </form>
    ),
    footer: (
      <>
        <Button type="submit" loading={loading} form="create-poll-form">
          Create Poll
        </Button>
      </>
    ),
  };
};

export default CreatePollForm;
