import {
  IconButton,
  IconLinkButton,
  RightSideBarOpenOnMobile,
  ScreenSizes,
  SectionHeader,
  Sizes,
  TopBar,
  useLayout,
  useScreenSize,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ChannelAttachments from "../../Channel/ChannelAttachments";
import Polls from "../../Channel/Polls";
import Channel from "../../Chat";
import { RightSideRoute } from "../../RouteComponents";
import { TopicResources } from "../pages/Resources";
import ReactMarkdown from "react-markdown";

const Index = ({ topic }: { topic: FrankBackendTypes.Topic }) => {
  const { url } = useRouteMatch();
  const { setMobilePanel } = useLayout();

  const screensize = useScreenSize();

  return (
    <>
      {screensize <= ScreenSizes.SM && (
        <div className="bg-canvas-700">
          <TopBar
            left={
              <IconButton
                icon="close"
                buttonStyle="minimal"
                onClick={() => setMobilePanel(null)}
              />
            }
            title={`#${topic.name}`}
          />
        </div>
      )}
      <div className="p-6 bg-canvas-700 flex-grow">
        {screensize > ScreenSizes.SM && (
          <h1 className="t-title-4 plus">{`#${topic.name}`}</h1>
        )}
        <p className="t-small full-formatting">
          <ReactMarkdown children={topic.description} />
        </p>
        <div className="h-8" />
        <div className="space-y-4">
          <SectionHeader to={url + "/polls"}>Polls</SectionHeader>
          {topic.supportsFiles && (
            <SectionHeader to={url + "/files"}>Files</SectionHeader>
          )}
          {!!topic.resources.length && (
            <SectionHeader to={url + "/resources"}>Resources</SectionHeader>
          )}
        </div>
      </div>
    </>
  );
};

const TopicRightSide = ({ topic }: { topic: FrankBackendTypes.Topic }) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <RightSideRoute path={`${url}/files`}>
        <ChannelAttachments topicId={topic.id} topicSlug={topic.slug} />
      </RightSideRoute>
      <RightSideRoute path={`${url}/polls`}>
        <div className="bg-canvas-700 flex-grow">
          <Polls
            channelId={topic.channelId}
            topicId={topic.id}
            topicSlug={topic.slug}
          />
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/resources`}>
        <TopBar
          classNames="bg-canvas-700"
          title="Resources"
          left={
            <IconLinkButton
              to={`../${topic.slug}`}
              icon="arrow_back"
              size={Sizes.XL}
              buttonStyle="minimal"
            />
          }
        />
        <div className="bg-canvas-700 flex-grow">
          <div className="p-6">
            <TopicResources topic={topic} />
          </div>
        </div>
      </RightSideRoute>

      <Route
        path={`${url}/thread/:threadId`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <Channel
                channelId={topic.channelId}
                parentId={match.params.threadId}
                key={match.params.threadId}
              />
            </>
          );
        }}
      />

      <Route path={`${url}/`}>
        <Index topic={topic} />
      </Route>
    </Switch>
  );
};

export default TopicRightSide;
