import * as React from "react";
import { useForm } from "react-hook-form";
import {
  ActivityFeed,
  Button,
  Card,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollToMe,
  Sizes,
} from "@get-frank-eng/design-system";
import Loading from "../../components/Loading";
import useCoworkerHistoryFetcher from "../dataAccess/useCoworkerActivityFetcher";
import last from "lodash/last";
import Crossroads from "../../components/Crossroads";
import useViewCoworkerActivity from "../dataAccess/mutations/useViewCoworkerActivity";
import { FrankBackendTypes } from "frank-types";

interface Props {
  coworkerId: string;
  change: (newValue: string) => any;
  loadingNoteSubmit: boolean;
}

const CoworkerHistory = ({
  activity,
  loadNextPage,
  hasNextPage,
}: {
  activity: FrankBackendTypes.Activity[];
  loadNextPage: () => any;
  hasNextPage: boolean;
}) => {
  const reversedArr = React.useMemo(() => activity?.reverse(), [activity]);

  if (!activity) {
    return <></>;
  }
  return (
    <>
      {hasNextPage && <Crossroads onEntering={loadNextPage} />}
      <ActivityFeed activities={reversedArr} />
      {reversedArr?.length > 0 && <ScrollToMe key={last(reversedArr).id} />}
    </>
  );
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const CoworkerActivityModal = ({
  coworkerId,
  loadingNoteSubmit,
  change,
}: Props) => {
  const {
    loadingCoworkerActivity,
    activity,
    hasNextPage,
    loadNextPage,
    name,
    refetchCoworkerActivity,
  } = useCoworkerHistoryFetcher(coworkerId);

  const { viewCoworkerActivity } = useViewCoworkerActivity({ coworkerId });

  React.useEffect(() => {
    viewCoworkerActivity();
  }, [viewCoworkerActivity]);

  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = React.useState(false);
  const submit = React.useCallback(
    async ({ notes }) => {
      setLoading(true);
      await change(notes);
      await sleep(350);
      refetchCoworkerActivity();
      await viewCoworkerActivity();

      reset();
      setLoading(false);
    },
    [change, refetchCoworkerActivity, setLoading, reset, viewCoworkerActivity]
  );

  const footer = (
    <form
      noValidate
      onSubmit={handleSubmit(submit)}
      className="items-center flex flex-row flex-grow"
    >
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className="p-2 rounded-sm flex-grow bg-transparent focus:ring-canvas-600 focus:ring-2 focus:outline-none t-small"
        placeholder="Add your note"
        name="notes"
        disabled={loadingNoteSubmit}
        ref={register({ required: true, maxLength: 1000 })}
        id="notes"
      />
      <Button
        type="submit"
        loading={loading}
        size={Sizes.MD}
        className="self-end ml-2 t-mini-plus w-32"
      >
        Add Note
      </Button>
    </form>
  );

  return (
    <>
      <ModalHeader title={name || "New Coworker"} border />
      <ModalBody>
        <>
          <Card pad>
            These notes are visible by all verified group members. You can use
            this field to track and share things like level of support, close
            alliances, or issues this person cares about. When someone on this
            list becomes a verified group member, these notes will disappear
            forever.
          </Card>
          {loadingCoworkerActivity ? (
            <Loading />
          ) : (
            <>
              <CoworkerHistory
                activity={activity}
                hasNextPage={hasNextPage}
                loadNextPage={loadNextPage}
              />
              {activity?.length === 0 && <div className="h-48" />}
            </>
          )}
          <ModalFooter additionalClasses={["pb-1", "pt-4"]}>
            {loadingCoworkerActivity ? <></> : footer}
          </ModalFooter>
        </>
      </ModalBody>
    </>
  );
};

export default CoworkerActivityModal;
