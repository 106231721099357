import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import {
  FormGroup,
  Intent,
  Select,
  useToaster
} from "@get-frank-eng/design-system";

export default ({ maxSeats, update, token, loading, idModifier = "" }) => {
  const toaster = useToaster();

  const updateSeats = async seats => {
    try {
      await update({ maxSeats: +seats }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Update successful"
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong"
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`limit-seats${idModifier}`}
      defaultChecked={!!maxSeats}
      title="Limit Number of Uses"
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updateSeats(null);
          return;
        }
        if (!isChecked && maxSeats) {
          updateSeats(null);
        }
      }}
      contentToShowWhenChecked={setDirty => (
        <div className="w-64">
          <FormGroup label="" name="maxSeats" id="maxSeats">
            <Select
              autoFocus
              name="expiration"
              disabled={loading}
              onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                setDirty(true);
                updateSeats(e.currentTarget.value);
              }}
              defaultValue={maxSeats || null}
            >
              <option value={null}>Select a value</option>
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Select>
          </FormGroup>
        </div>
      )}
    >
      By default, invite links can only be used once. If you'd like to share a
      link that can be used more than once, you can select a number of uses here
      (up to 50).
    </SecurityFeatureRow>
  );
};
