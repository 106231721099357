import { gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import client from "../gqlClient";
import AuthN from "./AuthN";
import { AuthStates, useAuthState } from "./AuthState";
import getGroupForJwt from "./getGroupsForJwt";

async function registerLoginWithOurBackend(): Promise<FrankBackendTypes.OnboardingWorkflowState> {
  const { data } = await client.mutate<{
    login: FrankBackendTypes.OnboardingWorkflowState;
  }>({
    mutation: gql`
      mutation {
        login
      }
    `,
  });
  return data.login;
}

const shouldThrow = (errors: any[]): boolean => {
  if (Array.isArray(errors)) {
    const failedCredentials = errors.filter(
      (error) => error.message === "FAILED" && error.field === "credentials"
    );
    if (failedCredentials.length) {
      return false;
    }
    const failedConnection = errors.find(
      (error) => error.message === "connection failed"
    );
    if (failedConnection) {
      return false;
    }
    return true;
  }
};

export enum LoginStatus {
  SUCCESS = "success",
  FAILED = "failed",
}

type LoginResult =
  | { status: LoginStatus.FAILED; message: "failed-credentials" }
  | {
      status: LoginStatus.SUCCESS;
      data: FrankBackendTypes.OnboardingWorkflowState;
    };

export default function useSession({
  groupId,
  afterLogin,
}: {
  groupId?: string;
  afterLogin?: () => any;
}) {
  const { setAuthState, authState, setOnboardingState } = useAuthState();

  const loginWithOneTimeToken = React.useCallback(
    async (authnToken: string): Promise<LoginResult> => {
      try {
        setAuthState({ state: AuthStates.LOADING_NEW_SESSION });
        await AuthN.submitPasswordlessLogin(authnToken);
        const groupIdToUse = await getGroupForJwt(
          window.localStorage.getItem("authn")
        );
        localStorage.setItem("groupId", `"${groupIdToUse}"`);
        setAuthState({ state: AuthStates.LOGGED_IN });
        const onboardingWorkflowState = await registerLoginWithOurBackend();
        setOnboardingState({ onboardingState: onboardingWorkflowState });
        if (afterLogin) {
          afterLogin();
        }
        return { status: LoginStatus.SUCCESS, data: onboardingWorkflowState };
      } catch (e) {
        setAuthState({ state: AuthStates.ERROR });
        if (shouldThrow(e)) {
          throw e;
        }
        return { status: LoginStatus.FAILED, message: "failed-credentials" };
      }
    },
    [afterLogin, setAuthState, setOnboardingState]
  );

  const login = React.useCallback(
    async (email: string, password: string): Promise<LoginResult> => {
      try {
        setAuthState({ state: AuthStates.LOADING_NEW_SESSION });
        await AuthN.login(email, password);
        const groupIdToUse =
          groupId ||
          (await getGroupForJwt(window.localStorage.getItem("authn")));
        localStorage.setItem("groupId", `"${groupIdToUse}"`);
        setAuthState({ state: AuthStates.LOGGED_IN });
        const onboardingWorkflowState = await registerLoginWithOurBackend();
        setOnboardingState({ onboardingState: onboardingWorkflowState });
        if (afterLogin) {
          afterLogin();
        }
        return { status: LoginStatus.SUCCESS, data: onboardingWorkflowState };
      } catch (e) {
        setAuthState({ state: AuthStates.ERROR });
        if (shouldThrow(e)) {
          throw e;
        }
        return { status: LoginStatus.FAILED, message: "failed-credentials" };
      }
    },
    [setAuthState, groupId, setOnboardingState, afterLogin]
  );

  const switchGroup = React.useCallback((groupId) => {
    localStorage.setItem("groupId", `"${groupId}"`);
    window.location.reload();
  }, []);

  return {
    login,
    authState,
    switchGroup,
    loginWithOneTimeToken,
  };
}
