import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import { nanoid } from "nanoid";
import * as React from "react";
import { generateFutureDate } from "../../../../utils/Date";

const CREATE_INVITE_LINK = gql`
  mutation CreateInviteLink($input: CreateInviteLinkDTO!) {
    createInviteLink(input: $input) {
      id
      token
      maxSeats
      passphrase
      expiration
      expiresIn
    }
  }
`;

export const useCreateInviteLink = ({
  afterCreate,
  campaignId,
}: {
  afterCreate?: () => void;
  campaignId?: string;
}) => {
  const [token] = React.useState(nanoid());
  const [invokeCreateInviteLink, { loading, error }] = useMutation<
    { createInviteLink: FrankBackendTypes.Invite },
    FrankBackendTypes.MutationCreateInviteLinkArgs
  >(CREATE_INVITE_LINK);

  const [
    inviteData,
    setInviteData,
  ] = React.useState<null | FrankBackendTypes.Invite>(null);

  const createInviteLink = React.useCallback(
    async (data: {
      maxSeats?: number;
      passphrase?: string;
      expiration?: string;
    }) => {
      const input: FrankBackendTypes.CreateInviteLinkDto = {
        token,
        campaignId,
        maxSeats: +data.maxSeats,
        passphrase: data.passphrase,
        expiration: data.expiration
          ? generateFutureDate(new Date(), +data.expiration)
          : undefined,
      };
      const { data: mutationData } = await invokeCreateInviteLink({
        variables: { input },
      });
      setInviteData(mutationData.createInviteLink);
      if (afterCreate) {
        await afterCreate();
      }
    },
    [token, campaignId, invokeCreateInviteLink, afterCreate]
  );

  return {
    inviteData,
    loading,
    createInviteLink,
    token,
  };
};
