"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentCard = exports.SmallAttachmentCard = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Card_1 = require("../components/Card");
const Icon_1 = require("../components/Icon");
const SmallAttachmentCard = ({ attachment }) => {
    return (React.createElement(Card_1.Card, { to: attachment.url, target: "_blank", pad: true, size: constants_1.Sizes.SM },
        React.createElement("div", { className: "flex flex-row items-center" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement("div", { className: "t-small plus" }, attachment.filename),
                React.createElement("div", { className: "t-mini text-canvas-400" },
                    attachment.humanReadableSize,
                    " ",
                    attachment.fileType),
                attachment.user && (React.createElement("div", { className: "t-mini text-canvas-400" },
                    "Uploaded by",
                    " ",
                    React.createElement("a", { className: "text-white t-mini plus", href: `/users/${attachment.user.id}` }, attachment.user.name)))),
            attachment.thumbnail ? (React.createElement("div", { className: "ml-2 flex-shrink-0 bg-cover bg-center bg-canvas-600 rounded-sm border", style: {
                    height: "56px",
                    width: "56px",
                    backgroundImage: attachment.thumbnail.url
                        ? `url(${attachment.thumbnail.url})`
                        : undefined
                } })) : (React.createElement("div", { className: "ml-2 flex-shrink-0 border rounded-sm w-14 h-14 flex items-center justify-center text-canvas-400" },
                React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, icon: "attach_file" }))))));
};
exports.SmallAttachmentCard = SmallAttachmentCard;
const AttachmentCard = ({ attachment }) => {
    if (attachment.thumbnail) {
        return (React.createElement("a", { href: attachment.url, target: "_blank", download: true, className: "t-mini text-canvas-400" },
            React.createElement("div", null,
                attachment.filename,
                " (",
                attachment.humanReadableSize,
                ")"),
            React.createElement("img", { className: "rounded mt-2 border shadow object-cover", src: attachment.thumbnail.url, style: { height: "200px" } })));
    }
    return (React.createElement(Card_1.Card, { to: attachment.url, target: "_blank", pad: true, size: constants_1.Sizes.SM },
        React.createElement("div", { className: "flex flex-row items-center" },
            React.createElement("div", { className: "flex-grow" },
                React.createElement("div", { className: "t-small plus" }, attachment.filename),
                React.createElement("div", { className: "t-mini text-canvas-400" },
                    attachment.humanReadableSize,
                    " ",
                    attachment.fileType),
                attachment.user && (React.createElement("div", { className: "t-mini text-canvas-400" },
                    "Uploaded by",
                    " ",
                    React.createElement("a", { className: "text-white t-mini plus", href: `/users/${attachment.user.id}` }, attachment.user.name)))),
            React.createElement("div", { className: "ml-2 flex-shrink-0 border rounded w-14 h-14 flex items-center justify-center text-canvas-400" },
                React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, icon: "attach_file" })))));
};
exports.AttachmentCard = AttachmentCard;
