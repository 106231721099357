import {
  Button,
  FormGroup,
  FullCenter,
  Input,
  Intent,
  LinkButton,
  Password,
  PasswordStrengthMeter,
  useToaster,
} from "@get-frank-eng/design-system";
import jwt from "jsonwebtoken";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router";
import { AuthStates } from "../../Auth/AuthState";
import usePasswordStrength from "../../Auth/usePasswordStrength";
import useSession from "../../Auth/useSession";
import Loading from "../../components/Loading";
import SentryClient, { SentryLoggingLevel } from "../../sentryClient";
import {
  StepContainer,
  StepDescription,
  StepLabel,
  StepTitle,
} from "../components";
import { CreateNewGroupLayout } from "../CreateNewGroupLayout";
import useRecordEmailVerified from "../dataAccess/mutations/useRecordEmailVerified";
import useSendVerifyEmail from "../dataAccess/mutations/useSendVerifyEmail";
import useSetPassword from "../dataAccess/mutations/useSetPassword";
import useWorkerOneOnboarding from "../dataAccess/queries/useWorkerOneOnboarding";

type FormParams = {
  password: string;
};

const isTokenExpired = (token) => {
  const decoded = jwt.decode(token);
  const expirationSeconds = decoded["exp"];

  if (Date.now() < expirationSeconds * 1000) {
    return false;
  }
  return true;
};

const PasswordPage = ({
  onboardingId,
  token,
}: {
  token: string;
  onboardingId: string;
}) => {
  const { recordEmailVerified } = useRecordEmailVerified();
  const { data, loading: dataFetchLoading, error } = useWorkerOneOnboarding({
    onboardingId,
  });
  const toaster = useToaster();

  const { setPassword, loading: setPasswordLoading } = useSetPassword({
    groupId: data?.groupId,
  });
  const { push } = useHistory();
  const { authState } = useSession({});
  const [resentEmail, setResentEmail] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);

  const loading = setPasswordLoading || dataFetchLoading;

  const setPasswordAndRedirect = React.useCallback(
    async (fp: FormParams) => {
      await setPassword(token, data.email, fp.password);
      push("/create-group/profile");
    },
    [push, setPassword, token, data]
  );

  const { sendVerifyEmail } = useSendVerifyEmail();

  React.useEffect(() => {
    if (authState === AuthStates.LOGGED_IN) {
      if (!emailVerified) {
        recordEmailVerified({
          onboardingId,
        });
        setEmailVerified(true);
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: "Email address verified",
        });
      }
    }
  }, [
    onboardingId,
    authState,
    emailVerified,
    setEmailVerified,
    recordEmailVerified,
    toaster,
  ]);

  React.useEffect(() => {
    if (data) {
      SentryClient.configureScope(function (scope) {
        scope.setTag("onboarding-type", "sso");
        scope.setTag("group", data.groupId);
        scope.setUser({ id: data.userId, workerType: "worker-one" });
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    const resendEmail = async () => {
      if (!resentEmail) {
        await sendVerifyEmail(data.email, data.groupId);
        push(
          `/create-group/check-email?email=${encodeURIComponent(
            data.email
          )}&groupId=${encodeURIComponent(data.groupId)}`
        );
      }
    };
    if (!token || isTokenExpired(token)) {
      setResentEmail(true);
      resendEmail();
      if (!token) {
        SentryClient.addBreadcrumb({
          category: "onboarding",
          message: "missing auth token in verify email; sent new email",
          level: SentryLoggingLevel.Error,
        });
      }
    }
  }, [data, push, sendVerifyEmail, token, resentEmail, setResentEmail]);

  const { watch, register, formState, handleSubmit } = useForm<FormParams>();

  const { password } = watch();
  const passwordStrength = usePasswordStrength(watch("password"));
  const showPasswordError =
    formState.dirtyFields.has("password") &&
    passwordStrength > -1 &&
    passwordStrength < 3;

  if (dataFetchLoading) {
    return (
      <CreateNewGroupLayout>
        <FullCenter>
          <Loading />
        </FullCenter>
      </CreateNewGroupLayout>
    );
  }
  if (error) {
    throw error;
  }

  if (data.hasSetPassword) {
    if (authState === AuthStates.LOGGED_IN) {
      push("/create-group/profile");
    } else {
      return <Redirect to="../login" />;
    }
  }

  return (
    <CreateNewGroupLayout>
      <StepContainer>
        <StepLabel>New Group • Step 1 of 2</StepLabel>

        <StepTitle>Create a password for your account</StepTitle>
        <StepDescription>
          To keep your account secure and protect your coworkers, we recommend
          you choose a unique and secure password only you will know or use a
          password manager.
        </StepDescription>
        <div className="h-8" />
        <form noValidate onSubmit={handleSubmit(setPasswordAndRedirect)}>
          <FormGroup name="password" label="Password" id="password">
            {!loading && authState === AuthStates.LOGGED_IN ? (
              <Input placeholder="Already set" disabled />
            ) : (
              <Password
                registerArgs={{ required: true, minLength: 6, maxLength: 255 }}
                register={register}
              />
            )}
          </FormGroup>
          <PasswordStrengthMeter
            passwordStrength={passwordStrength}
            password={password}
          />
          <div className="h-8" />
          <div className="flex flex-row space-x-3">
            {!loading && authState === AuthStates.LOGGED_IN ? (
              <LinkButton
                to="./profile"
                iconRight="arrow_forward"
                buttonStyle="brand"
              >
                Continue
              </LinkButton>
            ) : (
              <Button
                disabled={
                  loading ||
                  password?.length < 6 ||
                  showPasswordError ||
                  !password
                }
                loading={loading}
                buttonStyle="brand"
                iconRight="arrow_forward"
              >
                Continue
              </Button>
            )}
          </div>
        </form>
      </StepContainer>
    </CreateNewGroupLayout>
  );
};

export default PasswordPage;
