import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import ManageCollaborators from "../../Collaborators/ManageCollaborators";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";

const Organizers = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Invite Organizers</h2>
      <p className="t-regular text-canvas-400 mb-12">
        Add organizers to help you edit your petition, build support with
        coworkers, set up events, and more.
      </p>

      <ManageCollaborators campaignId={campaign.id} />
      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Organizers;
