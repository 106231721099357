import { gql } from "@apollo/client";
import client from "../../gqlClient";
import { Avatar, MentionSuggestion, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const COWORKER_QUERY = gql`
  query CoworkerSearch(
    $pagination: PaginationArgumentDTO!
    $filter: CoworkerFilterDTO!
  ) {
    coworkers(pagination: $pagination, filter: $filter) {
      hasNext
      total
      objects {
        name
        id
        user {
          id
          name
          profilePic {
            id
            url
          }
        }
      }
    }
  }
`;

export const searchCoworkersByName = async (query: string) => {
  const { data, errors } = await client.query<
    Pick<FrankBackendTypes.Query, "coworkers">,
    FrankBackendTypes.QueryCoworkersArgs
  >({
    query: COWORKER_QUERY,
    variables: {
      pagination: { page: 0, perPage: 15 },
      filter: {
        restrictions: [
          {
            column: FrankBackendTypes.CoworkerFilterableColummns.Name,
            combinator: FrankBackendTypes.Combinators.Contains,
            stringValue: query,
          },
          {
            column: FrankBackendTypes.CoworkerFilterableColummns.Status,
            combinator: FrankBackendTypes.Combinators.NotOneOf,
            in: ["admin", "deleted"],
          },
        ],
      },
    },
  });
  if (errors?.length) {
    throw errors[0];
  }
  return data.coworkers?.objects;
};

export const searchCoworkers = (
  query: string,
  callback: (suggestions: MentionSuggestion[]) => void
) => {
  if (!query) {
    return;
  }
  searchCoworkersByName(query).then((coworkers) => {
    callback(
      coworkers.map((coworker) => ({
        id: coworker.id,
        display: coworker.name,
        // this is really gross, but for reasons of Friday and this library being tricky, it is what it is
        preview: (
          <Avatar size={Sizes.XS} src={coworker.user?.profilePic?.url} />
        ),
      }))
    );
  });
};
