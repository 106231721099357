import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const LOAD_CAMPAIGN_SIGNATURES = gql`
  query CampaignUpdates($campaignId: ID!, $pagination: PaginationArgumentDTO!) {
    campaign(campaignId: $campaignId) {
      id
      signatures(pagination: $pagination) {
        hasNext
        total
        objects {
          id
          statement
          timeAgo
          createdAt
          user {
            id
            name
            profilePic {
              id
              url
            }
          }
        }
      }
    }
  }
`;

const perPage = 10;

export default function useCampaignSignatures(campaignId: string) {
  const { data, loading, error, fetchMore, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "campaign">,
    FrankBackendTypes.QueryCampaignArgs &
      FrankBackendTypes.CampaignSignaturesArgs
  >(LOAD_CAMPAIGN_SIGNATURES, {
    variables: { campaignId, pagination: { page: 0, perPage } },
  });

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        campaignId,
        pagination: {
          page: Math.round(data?.campaign.signatures.objects.length / perPage),
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        const toReturn = {
          ...prev,
          ...fetchMoreResult,
          campaign: {
            ...prev.campaign,
            ...fetchMoreResult.campaign,
            signatures: {
              ...prev.campaign.signatures,
              ...fetchMoreResult.campaign.signatures,
              objects: [
                ...prev.campaign.signatures.objects,
                ...fetchMoreResult.campaign.signatures.objects,
              ],
            },
          },
        };
        return toReturn;
      },
    });
  }, [fetchMore, campaignId, data]);

  return {
    loading,
    error,
    loadMore,
    signatures: data?.campaign.signatures,
    refetch,
  };
}
