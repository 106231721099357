import {
  Accordion,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";

const SignCampaignFAQModal = ({
  typeOfSignatures,
  recipientNames,
}: {
  typeOfSignatures: "public" | "anonymous";
  recipientNames: string[];
}) => {
  return (
    <>
      <ModalHeader title="Help" />
      <ModalBody>
        <h2 className="t-large plus">FAQ</h2>
        <Accordion defaultHidden={false} title="What happens next?">
          Your signature will be added to the Demand Letter. Once this campaign
          reaches its signature goal, the Campaign Organizer will begin the
          process of sending the letter to management. They may ask for input
          from supporters like you. You&apos;ll receive notifications when the
          campaign reaches its goal and when it&apos;s sent to management.
        </Accordion>
        <Accordion
          defaultHidden
          title="Will my name be shared with my company?"
        >
          <>
            <p className="pb-2">
              The campaign organizer has enabled signatures to be{" "}
              {typeOfSignatures}.
            </p>
            <p>
              Choosing to share signer&apos;s names generally sends a stronger
              message to management, but we know this isn&apos;t safe for all
              situations. If a campaign is set to hide signatures, management
              will receive a letter that says the total amount of employees who
              signed, but not individual names.
            </p>
          </>
        </Accordion>
        <Accordion defaultHidden title="Who receives this letter?">
          <>
            The campaign organizer has chosen to send this letter to:{" "}
            <ul>
              {recipientNames.map((name) => (
                <li key={name} className="pt-1">
                  - {name}
                </li>
              ))}
            </ul>
          </>
        </Accordion>
        <Accordion
          defaultHidden
          title="Can I remove my signature if I sign but change my mind?"
        >
          You can remove your signature up until a campaign reaches its
          signature goal. Once it reaches that goal, you cannot remove your
          signature (although the campaign can still be signed by new
          supporters).
        </Accordion>
      </ModalBody>
    </>
  );
};

export default SignCampaignFAQModal;
