import { TimelineItem, TimelineWrapper } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

export const CampaignTimeline = ({
  campaign,
}: {
  campaign: FrankBackendTypes.Campaign;
}) => {
  const demandRecipientsDescription = (
    <span>
      The Demand Letter will be sent to{" "}
      <span className="t-small plus">
        {campaign.petitionRecipients
          .map((recipient) => recipient.fullName)
          .join(", ")}
      </span>
    </span>
  );

  return (
    <div>
      <TimelineWrapper>
        <TimelineItem
          title="Campaign published"
          description="Campaign organizers published the campaign to the group on Frank"
          dateCompleted={campaign.publishedAt}
          status="complete"
        />
        <TimelineItem
          title="Reach signature goal"
          description={`This campaign must reach at least ${campaign.signatureGoal} signatures to be delivered`}
          status="in-progress"
        />
        <TimelineItem
          title="Deliver Demand Letter"
          description={demandRecipientsDescription}
          status="open"
        />
        <TimelineItem
          title="Receive a response"
          description="The recipient of your campaign will have a chance to respond to your demands"
          status="open"
        />
        <TimelineItem
          title="Reach a resolution"
          description="The Campaign Organizers will have the final say when a campaign has reached its resolution"
          status="open"
          last
        />
      </TimelineWrapper>
    </div>
  );
};
