import * as React from "react";
import useArchiveCampaign from "../CampaignBuilder/dataAccess/mutations/useArchiveCampaign";
import { useModals } from "../Modals";
import { MenuItem } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

interface Props {
  campaignId: string;
  workflowState: FrankBackendTypes.CampaignWorkflowState;
  relationshipToMe: FrankBackendTypes.CampaignUserRelationship;
  refetch?: () => any;
}

const CampaignMenu = ({
  campaignId,
  workflowState,
  relationshipToMe,
  refetch,
}: Props) => {
  const { openConfirmationModal } = useModals();
  const { archiveCampaign } = useArchiveCampaign({ campaignId, refetch });

  const handleArchive = React.useCallback(
    async (e) => {
      e.preventDefault();
      const confirmed = await openConfirmationModal({
        title: "Are you sure you want to archive this campaign",
        bodyText:
          "At the moment, there is no way for you to restore an archived campaign. Make sure you have saved any progress that you want to keep in another campaign or file.",
        actionText: "Confirm archive",
      });
      if (confirmed) {
        archiveCampaign();
      }
    },
    [openConfirmationModal, archiveCampaign]
  );

  if (workflowState === FrankBackendTypes.CampaignWorkflowState.Draft) {
    return (
      <>
        <MenuItem onClick={handleArchive}>Archive</MenuItem>
        <MenuItem to={`/campaigns/${campaignId}/inviteCollaborators`}>
          Invite Organizers
        </MenuItem>
      </>
    );
  }

  if (
    relationshipToMe === FrankBackendTypes.CampaignUserRelationship.Organizer
  ) {
    return (
      <>
        {workflowState ===
          FrankBackendTypes.CampaignWorkflowState.Published && (
          <MenuItem onClick={(e) => handleArchive(e)}>Archive</MenuItem>
        )}
        <MenuItem to={`/campaigns/${campaignId}/inviteCollaborators`}>
          Invite Organizers
        </MenuItem>
        <MenuItem to={`/campaigns/${campaignId}/builder`}>Edit</MenuItem>
      </>
    );
  }

  if (relationshipToMe === FrankBackendTypes.CampaignUserRelationship.None) {
    return (
      <>
        {/* <Menu onClose={onClose}> */}
        <MenuItem to={`/campaigns/${campaignId}/sign`}>Sign</MenuItem>
        {/* </Menu> */}
      </>
    );
  }

  if (
    relationshipToMe ===
      FrankBackendTypes.CampaignUserRelationship.InvitedNotSupporter ||
    relationshipToMe ===
      FrankBackendTypes.CampaignUserRelationship.InvitedSupporter
  ) {
    return (
      <>
        <MenuItem to={`/campaigns/${campaignId}/collaboratorInvite`}>
          View invitation
        </MenuItem>
      </>
    );
  }

  return null;
};

export default CampaignMenu;
