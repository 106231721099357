import {
  Avatar,
  Button,
  Intent,
  LinkButton,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CampaignWorkflowState } from "frank-types/dist/frank-backend-types";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useCollaboratorInviteActions } from "../CampaignDetail/dataAccess/mutations/useCollaboratorActions";
import useCollaboratorInviteQuery from "../CampaignDetail/dataAccess/queries/useCollaboratorInviteQuery";
import DetailLayout from "../components/layouts/Detail";
import Loading from "../components/Loading";

const CampaignCollaboratorInvite = () => {
  const { campaignId } = useParams<{ campaignId: string }>();

  const {
    collaboratorInvite,
    loadingCollaboratorInvite,
    errorCollaboratorInvite,
  } = useCollaboratorInviteQuery({
    campaignId,
  });

  const { push } = useHistory();
  const toaster = useToaster();

  const {
    accept,
    reject,
    loadingAccept,
    loadingReject,
  } = useCollaboratorInviteActions({
    campaignId,
  });

  const acceptInvite = async () => {
    try {
      await accept();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "You are an organizer on this campaign",
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong accepting this invitation",
      });
      throw e;
    }
    push(
      collaboratorInvite?.campaign.workflowState === CampaignWorkflowState.Draft
        ? `/campaigns/${campaignId}/builder`
        : `/campaigns/${campaignId}`
    );
  };

  const rejectInvite = async () => {
    try {
      await reject();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "You have declined this invitation",
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong declining this invitation",
      });
      throw e;
    }
    push(`/home`);
  };

  if (errorCollaboratorInvite) {
    throw new Error(errorCollaboratorInvite.message);
  }

  if (loadingCollaboratorInvite) {
    return <Loading />;
  }

  if (!collaboratorInvite) {
    return <div>You are not invited to this campaign</div>;
  }

  if (
    collaboratorInvite?.campaign.workflowState !==
    FrankBackendTypes.CampaignWorkflowState.Draft
  ) {
    push(`/campaigns/${campaignId}`);
  }

  return (
    <>
      <DetailLayout
        title={`Frank - Invite to collaborate on "${collaboratorInvite?.campaign?.title}"`}
      >
        <div className="max-w-lg mx-auto mt-3 sm:mt-16">
          <Avatar
            size={Sizes.LG}
            src={collaboratorInvite?.inviter?.profilePic.url}
            altText={collaboratorInvite?.inviter?.shortName}
            link={`/users/${collaboratorInvite?.inviter.id}`}
          />
          <h1 className="t-title-3 plus-plus mt-6">
            {collaboratorInvite?.inviter?.shortName} invited you to be an
            organizer on a campaign
          </h1>

          <p className="my-3 t-regular">
            {collaboratorInvite?.inviter?.shortName} invited you to help
            organize the campaign,{" "}
            <span className="t-regular plus-plus">
              {collaboratorInvite?.campaign?.title}
            </span>
          </p>
          {collaboratorInvite?.campaign.collaboratorWorkflowState ===
          FrankBackendTypes.CollaboratorWorkflowState.Rejected ? (
            <>
              <p className="my-3 t-regular">
                You have declined this invitation. If you have changed your
                mind, reach out to the organizer to get another invitation.
              </p>
              <div className="my-4 flex flex-row items-center">
                <div>
                  <LinkButton to="/campaigns" className="mr-3">
                    Go back to campaigns
                  </LinkButton>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="my-3 t-regular">
                As a campaign organizer, you will be able to make edits to the
                campaign, invite other organizers and will be listed as an
                organizer to your coworkers. Your status as an Organizer is
                never shared with management by Frank.
              </p>
              <div className="my-4 flex flex-row items-center">
                <div className="flex flex-row">
                  <Button
                    onClick={acceptInvite}
                    loading={loadingAccept}
                    className="mr-3"
                  >
                    Accept invitation
                  </Button>
                  <Button
                    onClick={rejectInvite}
                    loading={loadingReject}
                    buttonStyle="minimal"
                  >
                    Decline invitation
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </DetailLayout>
    </>
  );
};

export default CampaignCollaboratorInvite;
