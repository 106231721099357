import { Switch } from "@get-frank-eng/design-system";
import * as React from "react";
import { ValidationOptions } from "react-hook-form";

const SecurityFeatureRow = ({
  register,
  registerArgs,
  children,
  title,
  id,
  contentToShowWhenChecked,
  defaultChecked = false,
  onToggle,
  setValue,
}: {
  children: any;
  register?: any;
  registerArgs?: ValidationOptions;
  title: string;
  id: string;
  defaultChecked?: boolean;
  contentToShowWhenChecked?: (dirty) => React.ReactNode;
  onToggle?: (isChecked, dirty) => void;
  setValue?: (name, value) => any;
}) => {
  const [isChecked, setIsChecked] = React.useState(defaultChecked);
  const [dirty, setDirty] = React.useState(false);
  const onChange = React.useCallback(() => {
    if (onToggle) {
      onToggle(!isChecked, dirty);
    }
    if (setValue) {
      setValue(id, !isChecked);
    }
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked, dirty, onToggle, setValue, id]);

  return (
    <div>
      <p className="t-small plus mb-2">{title}</p>
      <label htmlFor={id} className="flex flex-col py-2 my-2 cursor-pointer">
        <div className="flex flex-row justify-between flex-grow mr-2">
          <span className="t-small w-4/5 mb-2 text-canvas-400">{children}</span>
          <div>
            <Switch
              onChange={onChange}
              checked={isChecked}
              label={id}
              id={id}
              disabled={false}
            />
          </div>
        </div>
        {isChecked &&
          contentToShowWhenChecked &&
          contentToShowWhenChecked(setDirty)}
      </label>
    </div>
  );
};

export default SecurityFeatureRow;
