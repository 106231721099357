import { CoworkerCard } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
  year: "numeric",
  day: "numeric",
});

const timeFormatter = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
});

const CampaignInfo = ({
  campaign,
}: {
  campaign: FrankBackendTypes.Campaign;
}) => {
  return (
    <div>
      <section className="border-b pb-6 space-y-3">
        <div className="t-small plus text-canvas-400">Organizers</div>
        {campaign.campaignOrganizers.map((organizer) => (
          <CoworkerCard
            name={organizer.name}
            userId={organizer.id}
            profilePicUrl={organizer.profilePic.url}
            status={FrankBackendTypes.CoworkerStatus.Member}
          />
        ))}
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">Published</div>
        <p className="t-small text-white">
          {dateFormatter.format(new Date(campaign.publishedAt))} •{" "}
          {timeFormatter.format(new Date(campaign.publishedAt))}
        </p>
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">Signature goal</div>
        <p className="t-small text-white">
          {campaign.signatureGoal} signatures
        </p>
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">Recipients</div>
        <div className="t-small pb-4">
          The Demand Letter will be delivered to the following email addresses
          after the signature goal is reached:
        </div>
        <p className="text-white t-small plus">
          {campaign.petitionRecipients.map((recipient) => (
            <p key={recipient.email}>{recipient.email}</p>
          ))}
        </p>
      </section>
    </div>
  );
};

export default CampaignInfo;
