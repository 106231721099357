/**
 * https://www.figma.com/proto/B9GE74RWzNwJ59HhZPTOJv/Campaigns-1.0?node-id=1284%3A173&scaling=min-zoom
 */

import * as React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import usePublish from "../dataAccess/mutations/usePublish";
import ContentContainer from "../components/ContentContainer";
import { InlineAlert, Button, Intent } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

const Publish = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  const { publish, loadingPublish } = usePublish({ campaignId: campaign.id });

  const { push } = useHistory();
  const publishAndRedirect = React.useCallback(async () => {
    await publish();
    push(`/campaigns/${campaign.id}`);
  }, [publish, push, campaign]);

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Publish your campaign</h2>

      <h3 className="t-regular plus">What happens when I publish?</h3>
      <p className="t-regular text-canvas-400 mb-8">
        Once campaigns are published, coworkers in your group are notified of
        your campaign and can sign your petition to show their support. Once
        enough signatures are obtained, you can then deliver your campaign to
        the decision maker in your company and start the bargaining process.
      </p>

      <h3 className="t-regular plus">Who can see my campaign?</h3>
      <p className="t-regular text-canvas-400 mb-8">
        Only verified coworkers in your Frank group can see your campaign.
      </p>
      {campaign.publishingValidationIssues.length > 0 && (
        // <section className="mb-8">
        //   <h3 className="t-regular plus">
        //     Before you can publish, please address the issues below
        //   </h3>
        //   <ul className="list-disc ml-4">
        //     {campaign.publishingValidationIssues.map(issue => (
        //       <li key={issue.category}>{issue.message}</li>
        //     ))}
        //   </ul>
        // </section>
        <InlineAlert
          title="Before you can publish, please address the issues below"
          intent={Intent.WARNING}
        >
          <ul className="list-disc ml-4">
            {campaign.publishingValidationIssues.map((issue) => (
              <li key={issue.category}>{issue.message}</li>
            ))}
          </ul>
        </InlineAlert>
      )}

      <div className="mt-12 flex flex-row">
        <Button
          buttonStyle="brand"
          loading={loadingPublish}
          onClick={publishAndRedirect}
          disabled={campaign.publishingValidationIssues.length > 0}
        >
          Publish to group
        </Button>
      </div>
    </ContentContainer>
  );
};

export default Publish;
