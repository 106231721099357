"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarHeader = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const constants_1 = require("../../constants");
const useBreakpoint_1 = require("../../hooks/useBreakpoint");
const Avatar_1 = require("../Avatar");
const Button_1 = require("../Button");
const Icon_1 = require("../Icon");
const Layout_1 = require("../Layout");
const menus_1 = require("../menus");
const interactive_classes_1 = require("./interactive-classes");
const SidebarHeader = ({ groupName, otherGroups, switchGroups, groupLogoUrl, linkTo }) => {
    const { screenSize } = Layout_1.useResponsive();
    const { setMobilePanel } = Layout_1.useLayout();
    const groupAvatar = groupLogoUrl ? (React.createElement("div", { className: "flex-shrink-0 flex items-center justify-center" },
        React.createElement(Avatar_1.Avatar, { size: constants_1.Sizes.XS, src: groupLogoUrl }))) : (React.createElement("div", { className: "w-5 h-5 flex-shrink-0 flex items-center justify-center t-small rounded border border-canvas-500" }, groupName.slice(0, 1)));
    const disabled = !linkTo && otherGroups.length === 0;
    const groupsMenu = (React.createElement(menus_1.Menu, null, otherGroups.map(g => (React.createElement(menus_1.MenuItem, { onClick: () => switchGroups(g.id), key: g.id }, g.name)))));
    const guts = screenSize > useBreakpoint_1.ScreenSizes.SM ? (React.createElement("button", { type: "button", disabled: disabled, className: `${interactive_classes_1.interactiveClasses({ disabled })} text-left` },
        groupAvatar,
        React.createElement("div", { className: "t-small ml-4 flex-1 flex-grow truncate -translate-x-2" }, groupName),
        !disabled && !linkTo && (React.createElement("div", { className: "transition-opacity md:opacity-0 group-hover:opacity-100 flex items-center justify-center" },
            React.createElement(Icon_1.Icon, { size: constants_1.Sizes.MD, icon: "keyboard_arrow_down" }))))) : (React.createElement("div", { className: "flex flex-1 flex-row items-center justify-between" },
        React.createElement(Button_1.IconButton, { onClick: () => setMobilePanel(null), buttonStyle: "minimal", size: constants_1.Sizes.XL, icon: "menu" }),
        React.createElement("div", { className: "space-x-2 truncate transform -translate-x-2 text-center t-small plus items-center flex flex-row" },
            groupName,
            !disabled && React.createElement(Icon_1.Icon, { size: constants_1.Sizes.MD, icon: "keyboard_arrow_down" })),
        groupAvatar));
    if (linkTo) {
        return (React.createElement("div", { className: "h-16 flex items-center flex-row mx-2 sm:mx-4" },
            React.createElement(react_router_dom_1.Link, { className: "w-full", to: linkTo }, guts)));
    }
    return (React.createElement("div", { className: "h-16 flex items-center flex-row mx-2 sm:mx-4" },
        React.createElement(menus_1.MenuTrigger, { disabled: disabled, trigger: "click", menu: groupsMenu }, guts)));
};
exports.SidebarHeader = SidebarHeader;
