import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useModals } from "../../Modals";

interface FormData {
  reason: string;
  reasonDetail: string;
}

export const DenyPendingMemberModal = ({
  pendingMember,
  downvote,
  company,
}) => {
  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const { closeModal } = useModals();

  const deny = (data: FormData) => {
    downvote({ reason: data.reason, rejectionDetail: data.reasonDetail });
    closeModal();
  };

  const { reason } = watch();

  return (
    <>
      <ModalHeader title={`Vote to deny ${pendingMember.name}`} />
      <ModalBody>
        <form
          id="deny-pending-coworker"
          onSubmit={handleSubmit(deny)}
          className="t-small space-y-2"
        >
          <label
            htmlFor="unfriendly-to-organizing"
            className="flex flex-row justify-start items-center"
          >
            <input
              ref={register({ required: "This field is required" })}
              id="unfriendly-to-organizing"
              name="reason"
              type="radio"
              className="mr-2 bg-transparent h-4 w-4 text-canvas-400 cursor-pointer border-canvas-400 border-2"
              value={
                FrankBackendTypes.CoworkerRejectionReason.UnfriendlyToOrganizing
              }
            />
            <div>
              I don't believe {pendingMember.name} will be an ally to our
              organizing efforts
            </div>
          </label>
          <label
            htmlFor="not-who-they-say-they-are"
            className="flex flex-row justify-start items-center"
          >
            <input
              ref={register({ required: "This field is required" })}
              id="not-who-they-say-they-are"
              name="reason"
              type="radio"
              className="mr-2 bg-transparent h-4 w-4 text-canvas-400 cursor-pointer border-canvas-400 border-2"
              value={
                FrankBackendTypes.CoworkerRejectionReason.NotWhoTheySayTheyAre
              }
            />
            <div>I don't believe this is actually {pendingMember.name}</div>
          </label>
          <label
            htmlFor="not-current-employee"
            className="flex flex-row justify-start items-center"
          >
            <input
              ref={register({ required: "This field is required" })}
              id="not-current-employee"
              name="reason"
              type="radio"
              className="mr-2 bg-transparent h-4 w-4 text-canvas-400 cursor-pointer border-canvas-400 border-2"
              value={
                FrankBackendTypes.CoworkerRejectionReason.NotCurrentEmployee
              }
            />
            <div>
              {pendingMember.name} doesn't currently work at this company
            </div>
          </label>
          <label
            htmlFor="manager"
            className="flex flex-row justify-start items-center"
          >
            <input
              data-cy="deny-reason-manager"
              ref={register({ required: "This field is required" })}
              id="manager"
              name="reason"
              type="radio"
              className="mr-2 bg-transparent h-4 w-4 text-canvas-400 cursor-pointer border-canvas-400 border-2"
              value={FrankBackendTypes.CoworkerRejectionReason.Manager}
            />
            <div>{pendingMember.name} is a manager at this company</div>
          </label>
          <label
            htmlFor="other"
            className="flex flex-row justify-start items-center"
          >
            <input
              data-cy="deny-reason-other"
              ref={register({ required: "This field is required" })}
              id="other"
              name="reason"
              type="radio"
              className="mr-2 bg-transparent h-4 w-4 text-canvas-400 cursor-pointer border-canvas-400 border-2"
              value={FrankBackendTypes.CoworkerRejectionReason.Other}
            />
            <div>Other</div>
          </label>
          {errors.reason && (
            <div className="t-mini text-brand-300 mt-1">
              {errors.reason?.message}
            </div>
          )}
          {reason === FrankBackendTypes.CoworkerRejectionReason.Other && (
            <div className="pt-4">
              <Textarea
                maxLength={200}
                name="reasonDetail"
                register={register}
                registerArgs={{ required: "This field is required" }}
              />
            </div>
          )}
        </form>
        <div className="t-small py-4">
          {pendingMember.name} can still be verified and join the group if
          enough other group members vote to admit. You can view Trust levels in
          <Link to="/coworkers" className="t-small plus">
            {" "}
            Unverified Coworker
          </Link>{" "}
          and discuss in the <span className="t-small plus">
            #Membership
          </span>{" "}
          channel.
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="space-x-2">
          <Button buttonStyle="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            dataCy="deny-coworker"
            form="deny-pending-coworker"
            type="submit"
            buttonStyle="brand"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            Vote to deny
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
