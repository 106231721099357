import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../Modals";
import {
  InlineAlert,
  Button,
  FormGroup,
  Intent,
  Select,
  Textarea,
  useToaster,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

interface Props {
  toFlag: Array<{ label: string; id: string }>;
  onSubmit?: () => any;
}

interface FormProps {
  category: string;
  notes: string;
}

const FLAG = gql`
  mutation Flag($input: FlagUserDTO!) {
    flagUser(input: $input) {
      nothing
    }
  }
`;

const FlagModal = ({ toFlag, onSubmit }: Props) => {
  const { addToast } = useToaster();
  const { closeModal } = useModals();

  const { register, handleSubmit } = useForm<FormProps>();

  const [invokeFlag, { called, loading, error: saveError }] = useMutation<
    any,
    { input: FrankBackendTypes.FlagUserDto }
  >(FLAG);

  const namePart =
    toFlag.length === 1 ? toFlag[0].label : `${toFlag.length} workers`;

  const submit = React.useCallback(
    async ({ category, notes }) => {
      await invokeFlag({
        variables: {
          input: {
            userIds: toFlag.map((f) => f.id),
            category,
            notes,
          },
        },
      });

      addToast({
        intent: Intent.SUCCESS,
        children: `${namePart} ${
          toFlag.length === 1 ? "has" : "have"
        } been reported.`,
      });

      if (onSubmit) {
        onSubmit();
      }
    },
    [invokeFlag, toFlag, addToast, namePart, onSubmit]
  );

  if (called && !loading && !saveError) {
    closeModal();
  }

  return (
    <>
      <ModalHeader title={`Flag ${namePart}`} />
      <ModalBody>
        {saveError && (
          <InlineAlert title="Server Error" intent={Intent.FAILURE}>
            There was a problem flagging this user
          </InlineAlert>
        )}
        <form id="flag-form" onSubmit={handleSubmit(submit)} noValidate>
          <div className="my-2 py-2">
            <FormGroup id="category" name="category" label="Reason">
              <Select placeholder="..." register={register}>
                <option value="manager">
                  They are a manager at our company
                </option>
                <option value="harassment">
                  They harassed me and/or another worker in the group
                </option>
                <option value="no-longer-employed">
                  They are no longer employed at our company
                </option>
                <option value="other">Other</option>
              </Select>
            </FormGroup>
          </div>
          <FormGroup
            label="Give us some context on the situation"
            name="notes"
            id="notes"
          >
            <Textarea
              maxLength={1200}
              data-cy="description-of-flag"
              register={register}
              registerArgs={{ required: true, maxLength: 1200 }}
            />
          </FormGroup>
          <ModalFooter>
            <>
              <Button buttonStyle="minimal" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                form="flag-form"
                onClick={handleSubmit(submit)}
                loading={loading}
                data-cy="send-report"
                type="submit"
              >
                Report
              </Button>
            </>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default FlagModal;
