import {
  Button,
  InlineAlert,
  LinkButton,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useHistory } from "react-router";
import Frank from "../../components/icons/Frank";
import useQuery from "../../hooks/useQuery";
import useSendVerifyEmail from "../dataAccess/mutations/useSendVerifyEmail";

const EmailConfirmation = ({ resent }: { resent?: boolean }) => {
  const { sendVerifyEmail, loading, error } = useSendVerifyEmail();
  const { go } = useHistory();

  const qs = useQuery();

  const email = qs.get("email");
  const groupId = qs.get("groupId");

  return (
    <div className="flex bg-canvas-800 h-screen w-screen">
      <div className="relative w-full flex flex-col justify-center items-center">
        <div className="hidden sm:block absolute top-0 left-0 mt-4 ml-6">
          <Frank />
        </div>
        <div className="px-6 sm:px-0 sm:max-w-xl sm:mx-auto w-full flex flex-col justify-center items-center space-y-2">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
            <rect y="8" width="48" height="48" rx="8" fill="#333230" />
            <path
              d="M34 26C34 24.9 33.1 24 32 24H16C14.9 24 14 24.9 14 26V38C14 39.1 14.9 40 16 40H32C33.1 40 34 39.1 34 38V26ZM32 26L24 31L16 26H32ZM32 38H16V28L24 33L32 28V38Z"
              fill="white"
            />
            <circle
              cx="48"
              cy="8"
              r="6"
              fill="#D6996E"
              stroke="#191918"
              strokeWidth="4"
            />
          </svg>

          <div className="t-title-4 plus">
            {resent
              ? "Check your email inbox one more time"
              : "Check your email inbox"}
          </div>
          <div className="text-center">
            {resent ? (
              <div className="text-canvas-400">
                The link in the original email we sent you is expired or
                invalid. Go ahead and check your email again for a new one
                that's current.
              </div>
            ) : (
              <div className="text-canvas-400 t-small">
                We sent a temporary login link to{" "}
                <span className="t-small plus">{email}</span>
              </div>
            )}
            <div className="text-canvas-400 pt-6">
              Didn’t get the email?{" "}
              <Button
                buttonStyle="minimal"
                size={Sizes.SM}
                onClick={() => sendVerifyEmail(email, groupId)}
                disabled={loading}
              >
                <span className="text-brand-300 t-regular plus">
                  Send it again
                </span>
              </Button>
            </div>
          </div>
          {error && (
            <div className="pt-16">
              <InlineAlert
                title="Error sending email. Please try again"
                actions={
                  <div className="flex flex-row space-x-2">
                    <Button
                      size={Sizes.SM}
                      buttonStyle="outline"
                      onClick={() => go(0)}
                    >
                      Refresh
                    </Button>
                    <LinkButton
                      to="../contact"
                      size={Sizes.SM}
                      buttonStyle="outline"
                    >
                      Help
                    </LinkButton>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
