import * as React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import {
  Button,
  FullCenter,
  Sizes,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import Frank from "./components/icons/Frank";

const FullPageError = () => {
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.SM;

  const history = useHistory();

  return (
    <div className="mx-4 sm:mx-0">
      <a
        href="https://getfrank.com/"
        target="_blank"
        rel="noreferrer"
        className="hidden sm:block absolute ml-12 mt-6"
      >
        <Frank />
      </a>
      <div className="flex w-screen flex-row items-center justify-center pt-32 sm:pt-0 sm:h-screen md:pb-32 bg-canvas-800">
        <div className="w-100 flex-1 flex flex-col self-center container mx-auto max-w-xl">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="text-brand-600">
              <i
                className="material-icons-outlined transform rotate-45"
                style={{ fontSize: "75px" }}
              >
                add_circle_outline
              </i>
            </div>
            <h2
              className={classNames([
                "pb-4",
                {
                  "t-title-4 plus-plus": isMobile,
                  "t-title-1 plus-plus": !isMobile,
                },
              ])}
            >
              Hmmm...
            </h2>
            <p
              className={classNames([
                "pb-6",
                {
                  "t-large": !isMobile,
                  "t-regular": isMobile,
                },
              ])}
            >
              To be frank, we have no idea what happened.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
              <FullCenter>
                <Button
                  iconLeft="home"
                  onClick={() => {
                    history.push("/home");
                    history.go(0);
                  }}
                  buttonStyle="minimal"
                  size={Sizes.XL}
                >
                  Go home
                </Button>
              </FullCenter>
              <FullCenter>
                <Button
                  iconLeft="refresh"
                  onClick={() => history.go(0)}
                  buttonStyle="minimal"
                  size={Sizes.XL}
                >
                  Refresh page
                </Button>
              </FullCenter>
              <FullCenter>
                <Button
                  iconLeft="help_outline"
                  onClick={() => {
                    history.push("/contact");
                    history.go(0);
                  }}
                  buttonStyle="minimal"
                  size={Sizes.XL}
                >
                  Get help
                </Button>
              </FullCenter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageError;
