"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SegmentedControl = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../../constants");
const Icon_1 = require("../Icon");
const SegmentedControl = ({ name, options, legendTitle, register, registerArgs, fill = false, onChange, direction = "horizontal", onFocus, disabled, labelStyles, size }) => {
    const sizeToUse = size || constants_1.Sizes.MD;
    const textSize = {
        [constants_1.Sizes.SM]: "t-mini plus",
        [constants_1.Sizes.MD]: "t-small plus"
    }[sizeToUse];
    return (React.createElement("div", { className: fill ? "w-full min-w-0" : "" },
        React.createElement("fieldset", { className: "min-w-0", disabled: disabled },
            React.createElement("legend", { className: "sr-only" }, legendTitle),
            React.createElement("div", { className: `segmented-control segmented-control-${direction} ${fill ? `segmented-control-fill` : ""} relative z-0` }, options.map(opt => (React.createElement(React.Fragment, { key: opt.id },
                React.createElement("input", { disabled: disabled, ref: register && register(registerArgs), name: name, value: opt.value, type: "radio", id: opt.id, defaultChecked: opt.defaultChecked, onChange: onChange || undefined, onFocus: onFocus || undefined }),
                React.createElement("label", { className: `${labelStyles} ${textSize}`, htmlFor: opt.id },
                    opt.icon && (React.createElement(Icon_1.Icon, { size: sizeToUse === constants_1.Sizes.MD ? constants_1.Sizes.SM : constants_1.Sizes.XS, icon: opt.icon })),
                    React.createElement("div", null, opt.label)))))))));
};
exports.SegmentedControl = SegmentedControl;
exports.default = exports.SegmentedControl;
