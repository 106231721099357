import { Button, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";

export const InviteLinkInput = ({
  loading,
  type,
  onClickHandler,
  showCopyButton,
  customRightContent,
  url,
}: {
  loading: boolean;
  type: "button" | "submit" | "reset";
  onClickHandler: () => any;
  showCopyButton?: boolean;
  customRightContent?: React.ReactNode;
  url: string;
}) => {
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="p-3 t-small text-canvas-400 bg-canvas-700 w-full border justify-between rounded flex flex-row items-center text-left"
    >
      <div className={`truncate`}>{url}</div>
      <div className="flex-shrink-0 ml-2">
        {showCopyButton ? (
          <Button
            buttonStyle="outline"
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              onClickHandler();
            }}
            type={type}
            size={Sizes.SM}
          >
            Copy link
          </Button>
        ) : (
          <>{customRightContent}</>
        )}
      </div>
    </button>
  );
};

export default InviteLinkInput;
