import { ExtendedStep } from ".";
import { FrankBackendTypes } from "frank-types";

export const coworkersTourSteps = (isMobile): ExtendedStep[] => [
  {
    target: `.coworkers-page #name-column-workers`,
    title: "Start tracking coworkers",
    content:
      "Build your list of coworkers and mark who you trust to invite to your group. The status below each name tells you who's been invited and their onboarding status.",
    disableBeacon: true,
  },
  {
    target: `.coworkers-page #employmentType-column-workers`,
    title: "Sort your list",
    content:
      "Mark coworkers as current workers, former workers, or management. People marked as managers will move to a separate section and their associated email address will be banned from joining your group.",
    disableBeacon: true,
  },
  {
    target: `.coworkers-page #yourTrust-column-workers`,
    title: "Add trustworthiness",
    content:
      "Mark whether or not you trust this coworker to be supportive of your efforts in this group. New members must have a net trust score of at least two in order to join.",
    disableBeacon: true,
    placement: isMobile ? "left-end" : undefined,
  },
  {
    target: `.coworkers-page #notes-column-workers`,
    title: "Take notes",
    content:
      "You can add notes about trustworthiness that will be visible to your fellow group members. After a member joins, notes on that member disappear and are not viewable to anyone.",
    disableBeacon: true,
  },
  {
    target: `#${FrankBackendTypes.NavigationSubjectTypes.FromFrank}, .mobile-menu`,
    title: "Finish up tour",
    content:
      "You're ready to head back to #from-frank. You can return to this page any time by clicking Coworkers.",
    disableBeacon: true,
  },
];
