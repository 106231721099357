import { FrankBackendTypes } from "frank-types";

export const welcomeTourSteps = (isMobile) =>
  [
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.Group}`,
      title: "Build your group",
      content:
        "Use the Coworkers page to organize your fellow coworkers. Here, you can send and track invitations, block managers, and share notes with your coworkers.",
      disableBeacon: true,
    },
    {
      target: `.${FrankBackendTypes.NavigationSubjectTypes.Topic}`,
      title: "Talk to your coworkers",
      content:
        "Topics help you start conversations about anything and help keep you organized. You can also create polls and share files.",
      disableBeacon: true,
    },
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.CreateCampaign}`,
      title: "Be heard by management",
      content:
        "When you're ready, start a campaign to create a demand letter, gather signatures, and send to management. You can collaborate on campaigns with coworkers, use a template or draft your own, and preview some suggested Actions to ensure you're heard.",
      disableBeacon: true,
    },
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.Invite}`,
      title: "Invite coworkers",
      content:
        "Invite one or two of your most trusted coworkers via private link or email to start getting the most out of Frank. Once they're invited, you can vouch for potential group members by marking them as trustworthy. You can also mark a coworker as untrustworthy if you think they won't be helpful to your efforts.",
      disableBeacon: true,
    },
    isMobile
      ? {
          target: `#${FrankBackendTypes.NavigationSubjectTypes.FromFrank}`,
          title: "Get started",
          content:
            "Now that you know where everything is, chat with FrankBot to start using Frank. FrankBot will guide you through a few actions to get started. You can always return to from-frank when you have questions or want to see new activity.",
          disableBeacon: true,
        }
      : null,
  ].filter((step) => !!step);
