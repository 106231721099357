import * as React from "react";
type CreateNewGroupState = {
  groupLogoUrl: string | null;
  groupName: string;
  companyName: string | null;
  user: {
    name: string;
    profilePictureThumbnailUrl: string;
    currentEmployee: boolean;
    nonMgmt: boolean;
  } | null;
  topics: {
    name: string;
  }[];
};

type CreateNewGroupAction =
  | {
      type: "set-group-name";
      groupName: string;
    }
  | { type: "set-company-name"; companyName: string }
  | {
      type: "set-user";
      name?: string;
      profilePictureThumbnailUrl?: string;
      currentEmployee?: boolean;
      nonMgmt?: boolean;
    }
  | { type: "set-topics"; topics: { name: string }[] }
  | {
      type: "set-group-logo";
      url: string;
    };

function reducer(
  prev: CreateNewGroupState,
  action: CreateNewGroupAction
): CreateNewGroupState {
  if (action.type === "set-group-name") {
    return { ...prev, groupName: action.groupName };
  }
  if (action.type === "set-group-logo") {
    return { ...prev, groupLogoUrl: action.url };
  }
  if (action.type === "set-company-name") {
    return { ...prev, companyName: action.companyName };
  }
  if (action.type === "set-topics") {
    return { ...prev, topics: action.topics.filter((t) => t.name.length > 0) };
  }
  if (action.type === "set-user") {
    return {
      ...prev,
      user: {
        name: action.name || prev.user.name,
        profilePictureThumbnailUrl:
          action.profilePictureThumbnailUrl ||
          prev.user.profilePictureThumbnailUrl,
        currentEmployee: action.currentEmployee || prev.user.currentEmployee,
        nonMgmt: action.nonMgmt || prev.user.nonMgmt,
      },
    };
  }
  return prev;
}

type CreateNewGroupLayoutContext = {
  dispatch: React.Dispatch<CreateNewGroupAction>;
  state: CreateNewGroupState;
};

const defaultState = {
  groupName: "",
  companyName: null,
  user: {
    name: null,
    profilePictureId: null,
    profilePictureThumbnailUrl: null,
    currentEmployee: false,
    nonMgmt: false,
  },
  topics: [],
  groupLogoUrl: null
};

const createNewGroupLayoutContext = React.createContext<CreateNewGroupLayoutContext>(
  {
    dispatch(action) {},
    state: defaultState,
  }
);

export function useNewGroupLayout() {
  return React.useContext(createNewGroupLayoutContext);
}
export const CreateNewGroupStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <createNewGroupLayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </createNewGroupLayoutContext.Provider>
  );
};
