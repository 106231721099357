import {
  Layout,
  LeftSideBar,
  Sidebar,
  SidebarDivider,
  SidebarFooter,
  SidebarItem,
  SidebarSection,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useNewGroupLayout } from "./state";

export const CreateNewGroupLayout: React.FC = ({ children }) => {
  const { state } = useNewGroupLayout();
  return (
    <Layout>
      <LeftSideBar>
        <Sidebar
          groupLogoUrl={state.groupLogoUrl}
          switchGroups={() => {}}
          otherGroups={[]}
          groupName={state.groupName || "New group"}
          footer={
            <SidebarFooter
              name={state.user.name}
              avatarUrl={state.user.profilePictureThumbnailUrl}
            />
          }
        >
          <SidebarSection disabled isOpen>
            <SidebarItem to="" icon="Home">
              Home
            </SidebarItem>
            <SidebarItem to="" icon="Briefcase">
              Coworkers
            </SidebarItem>
          </SidebarSection>
          <SidebarDivider />
          <SidebarSection disabled isOpen header="Topics">
            {[
              <SidebarItem icon="Hash" to="" key="general">
                anonymous
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="general">
                general
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="pay">
                pay
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="benefits">
                benefits
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="work-conditions">
                work-conditions
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="membership">
                membership
              </SidebarItem>,
            ]
              .concat(
                ...state.topics.map((topic, i) => (
                  <SidebarItem icon="Hash" to="" key={topic.name + "-" + i}>
                    {topic.name}
                  </SidebarItem>
                ))
              )
              .concat(
                <SidebarItem to="" key="new-topic" icon="Plus">
                  New Topic
                </SidebarItem>
              )}
          </SidebarSection>
          <SidebarDivider />
          <SidebarSection disabled isOpen header="Campaigns">
            <SidebarItem to="" icon="File" key="drafts">
              Drafts
            </SidebarItem>
            <SidebarItem to="" key="new-campaign" icon="Plus">
              New Campaign
            </SidebarItem>
          </SidebarSection>
        </Sidebar>
      </LeftSideBar>
      {children}
    </Layout>
  );
};
