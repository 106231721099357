"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignCard = void 0;
const frank_types_1 = require("frank-types");
const React = __importStar(require("react"));
const Card_1 = require("../../components/Card");
const Nudge_1 = require("../../components/Nudge");
const ProgressBar_1 = require("../../components/ProgressBar");
const constants_1 = require("../../constants");
const CampaignOrganizers_1 = require("./CampaignOrganizers");
const CampaignWorkflowStateLabel_1 = require("./CampaignWorkflowStateLabel");
const findCampaignUrl = (id, myRelationshipToCampaign, campaignWorkflowState, currentPath) => {
    if (campaignWorkflowState === frank_types_1.FrankBackendTypes.CampaignWorkflowState.Draft) {
        return myRelationshipToCampaign ===
            frank_types_1.FrankBackendTypes.CampaignUserRelationship.Organizer
            ? `/campaigns/${id}/builder`
            : `/campaigns/${id}/collaboratorInvite`;
    }
    let ret = `/campaigns/${id}`;
    if (ret === currentPath) {
        ret += "/demand";
    }
    return ret;
};
const CampaignCard = ({ id, title, campaignOrganizers, signatureCount, signatureGoal, workflowState, relationshipToMe, campaignMenu, stepsToPublish }) => {
    const url = new URL(window.location.href);
    const link = findCampaignUrl(id, relationshipToMe, workflowState, url.pathname);
    return (React.createElement(Card_1.Card, { pad: true, size: constants_1.Sizes.SM, minHeight: 260, to: link, menuItems: relationshipToMe !==
            frank_types_1.FrankBackendTypes.CampaignUserRelationship.Supporter && campaignMenu
            ? campaignMenu
            : undefined },
        React.createElement("div", { className: "pb-2" },
            React.createElement(Nudge_1.Nudge, { x: -2 },
                React.createElement(CampaignWorkflowStateLabel_1.CampaignWorkflowStateLabel, { workflowState: workflowState }))),
        React.createElement("div", { className: "t-small plus" }, title),
        React.createElement("div", { className: "text-canvas-700 pt-4" },
            React.createElement(CampaignOrganizers_1.CampaignOrganizers, { showOrganizedBy: false, campaignOrganizers: campaignOrganizers })),
        React.createElement("div", { className: "flex-grow" }),
        React.createElement("div", null,
            workflowState ===
                frank_types_1.FrankBackendTypes.CampaignWorkflowState.Published && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "t-mini plus mb-2 text-brand-300" },
                    signatureCount,
                    " ",
                    signatureCount === 1 ? "Signature" : "Signatures"),
                React.createElement(ProgressBar_1.ProgressBar, { numerator: signatureCount, denominator: signatureGoal }))),
            workflowState === frank_types_1.FrankBackendTypes.CampaignWorkflowState.Draft && (React.createElement("div", { className: "text-brand-400 t-mini plus" }, stepsToPublish && stepsToPublish > 0 ? (React.createElement(React.Fragment, null,
                stepsToPublish,
                " ",
                stepsToPublish === 1 ? "step" : "steps",
                " to publish")) : (React.createElement(React.Fragment, null, "Ready to publish")))))));
};
exports.CampaignCard = CampaignCard;
