import {
  LinkButton,
  Sizes,
  CampaignWorkflowStateLabel,
  Nudge,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import CampaignBuilderNav from "./Nav";

const CampaignBuilderSidebar = ({
  campaign,
  showSaveAndExit = true,
}: {
  campaign: FrankBackendTypes.Campaign;
  showSaveAndExit?: boolean;
}) => {
  return (
    <div className="flex flex-col flex-grow">
      {showSaveAndExit && (
        <Nudge x={-9} className="pb-4">
          <LinkButton
            to={
              campaign.workflowState ===
              FrankBackendTypes.CampaignWorkflowState.Published
                ? `/campaigns/${campaign.id}`
                : "/campaigns"
            }
            buttonStyle="minimal"
            size={Sizes.LG}
          >
            Save and exit
          </LinkButton>
        </Nudge>
      )}
      <div>
        <CampaignWorkflowStateLabel workflowState={campaign.workflowState} />
        <h1 className="t-title-4 plus mb-8">
          {campaign.title ? campaign.title : "New campaign"}
        </h1>
      </div>
      <CampaignBuilderNav campaign={campaign} />
    </div>
  );
};

export default CampaignBuilderSidebar;
