import { useMutation, gql } from "@apollo/client";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../../Modals";

const createMutation = gql`
  mutation AddColumn($input: AddCoworkerColumnDTO!) {
    addColumn(input: $input) {
      id
      name
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteColumn($columnId: ID!) {
    deleteColumn(columnId: $columnId) {
      nothing
    }
  }
`;

export default function useColumns() {
  const toaster = useToaster();
  const { openConfirmationModal } = useModals();
  const [
    invokeMutation,
    { loading: addLoading, error: addError, called: addCalled },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "addColumn">,
    FrankBackendTypes.MutationAddColumnArgs
  >(createMutation);

  const [
    invokeDeleteMutation,
    { loading: deleteLoading, error: deleteError, called: deleteCalled },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "deleteColumn">,
    FrankBackendTypes.MutationDeleteColumnArgs
  >(deleteMutation);

  const addColumn = React.useCallback(
    async (input: FrankBackendTypes.AddCoworkerColumnDto) => {
      try {
        const result = await invokeMutation({
          variables: { input },
        });
        return result;
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: "Something went wrong with creating this column",
        });
        throw e;
      }
    },
    [invokeMutation, toaster]
  );

  const deleteColumn = React.useCallback(
    async (columnId: string) => {
      const confirm = await openConfirmationModal({
        title: "Are you sure you want to delete this column?",
        bodyText: `This cannot be undone and will permanently delete all of the data in this column.`,
        actionText: "Confirm",
      });
      if (confirm) {
        await invokeDeleteMutation({
          variables: { columnId },
        });
        if (deleteError) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: "Something went wrong with deleting this column",
          });
          throw deleteError;
        }
      }
    },
    [deleteError, invokeDeleteMutation, openConfirmationModal, toaster]
  );

  return {
    deleteColumn,
    addColumn,
    addLoading,
    addError,
    addCalled,
    deleteLoading,
    deleteError,
    deleteCalled,
  };
}
