"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalBackground = void 0;
const react_1 = require("@headlessui/react");
const React = __importStar(require("react"));
const ModalOverlay_1 = require("./ModalOverlay");
const ModalBackground = ({ children, isOpen, onClose, overlay = true }) => {
    const closeOnEscape = React.useCallback(function closeOnEscape(event) {
        if (event.key === "Escape") {
            onClose();
        }
    }, [onClose]);
    React.useEffect(() => {
        document.addEventListener("keydown", closeOnEscape, false);
        return () => document.removeEventListener("keydown", closeOnEscape);
    }, [closeOnEscape]);
    return (React.createElement(react_1.Transition, { enter: "ease-out duration-300", leave: "ease-in duration-200", show: isOpen, className: "fixed inset-0 overflow-y-hidden z-50", onClick: !overlay ? onClose : undefined },
        React.createElement("div", { className: "flex items-center justify-center min-h-screen pt-4 px-1 sm:px-4 pb-20 text-center" },
            React.createElement(ModalOverlay_1.ModalOverlay, { onClick: onClose }),
            React.createElement("span", { className: "hidden inline-block align-middle h-screen", "aria-hidden": "true" }, "\u200B"),
            children)));
};
exports.ModalBackground = ModalBackground;
