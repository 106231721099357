import { ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import CreateInvite from "./CreateLinkInvite";

const CreateLinkInviteModal = ({
  campaignId,
}: {
  campaignId?: FrankBackendTypes.Campaign["id"];
}) => {
  return (
    <>
      <ModalHeader title="Invite via private link" />
      <ModalBody pad>
        <CreateInvite campaignId={campaignId} />
      </ModalBody>
    </>
  );
};

export default CreateLinkInviteModal;
