import { useMutation, useQuery, gql } from "@apollo/client";
import * as React from "react";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import { generateFutureDate } from "../../utils/Date";
import BulkInviteAdvancedOptions from "./BulkInviteAdvancedOptions";
import {
  Button,
  FormGroup,
  Input,
  Textarea,
  Intent,
  useToaster,
  ModalBody,
  InlineAlert,
  ModalFooter,
  ModalHeader,
  doesEmailHaveFreeDomain,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useBulkInviteByEmail } from "../dataAccess/queries/mutations/useBulkInviteByEmail";
import { useConfirmEmailModal } from "../../SSO/ConfirmEmailModal";

const INVITE_BY_COWORKER = gql`
  mutation BulkInviteByCoworker($input: BulkInviteByCoworkerDTO!) {
    bulkInviteByCoworker(input: $input) {
      nothing
    }
  }
`;

const GROUP_QUERY = gql`
  query Groups {
    myProfile {
      id
      shortName
      profilePic {
        url
        id
      }
    }
    group {
      id
      name
      company
    }
  }
`;

function triggerChangeOfInput(el: HTMLInputElement, value: string) {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(el, value);

  const ev2 = new Event("input", { bubbles: true });
  el.dispatchEvent(ev2);
}

const EmailInvite = ({
  onSubmit,
  emails,
  coworkerIds,
  warnings,
  view = "modal",
  refetchStats,
}: {
  onSubmit?: (coworkerIds?: FrankBackendTypes.Coworker["id"][]) => any;
  emails?: string[];
  coworkerIds?: string[];
  warnings?: any;
  view?: "default" | "modal";
  refetchStats?: () => any;
}) => {
  const { setModal } = useModals();

  const openAddCoworkerDataModal = React.useCallback(
    (followUpCoworkerIds) =>
      setModal({
        type: "coworkersModal",
        props: {
          message: (
            <>
              Your invites have been sent. Now, to make the account registration
              process smoother for those you just invited, tell your coworkers a
              little bit more about them.
            </>
          ),
          coworkerIds: followUpCoworkerIds,
        },
      }),
    [setModal]
  );

  const { loading: groupLoading, data: groupData } = useQuery<{
    group: FrankBackendTypes.Group;
    myProfile: FrankBackendTypes.MyProfile;
  }>(GROUP_QUERY);

  const toaster = useToaster();

  const {
    bulkInviteByEmail,
    loadingInviteByEmail,
    saveErrorByEmail,
  } = useBulkInviteByEmail();

  const [
    invokeInviteByCoworker,
    { loading: loadingInviteByCoworker, error: saveErrorByCoworker },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "bulkInviteByCoworker">,
    FrankBackendTypes.MutationBulkInviteByCoworkerArgs
  >(INVITE_BY_COWORKER);

  const openConfirmEmailModal = useConfirmEmailModal();

  const saveError = saveErrorByCoworker || saveErrorByEmail;

  const loading = loadingInviteByCoworker || loadingInviteByEmail;
  const [inputtedEmails, setInputtedEmails] = React.useState<{
    emails?: string;
  }>({
    emails: "",
  });

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<{
    emails: string;
    message: string;
  }>();

  const submit = React.useCallback(
    async (data) => {
      if (coworkerIds) {
        await invokeInviteByCoworker({
          variables: {
            input: {
              coworkerIds,
              message: data.message,
              passphrase: data.bulkRequirePassphrase,
              expiration: data.bulkExpiration
                ? generateFutureDate(new Date(), +data.bulkExpiration)
                : undefined,
            },
          },
        });

        if (onSubmit) {
          await onSubmit();
        }
      } else {
        const emails = data.emails
          .split(",")
          .map((email: string) => email.trim());
        const suspiciousEmails = emails.filter(
          (email) => !doesEmailHaveFreeDomain(email)
        );
        if (suspiciousEmails.length) {
          const confirm = await openConfirmEmailModal({ invite: true });
          if (!confirm) {
            return;
          }
        }
        const returnedCoworkerIds = await bulkInviteByEmail({
          emails,
          message: data.message,
          passphrase: data.bulkRequirePassphrase,
          expiration: data.bulkExpiration,
        });
        if (onSubmit) {
          await onSubmit(returnedCoworkerIds.bulkInviteByEmail);
        }
        await openAddCoworkerDataModal(returnedCoworkerIds.bulkInviteByEmail);
        if (refetchStats) {
          await refetchStats();
        }
      }

      const numberOfEmails = inputtedEmails.emails?.split(", ").length;
      reset();

      toaster.addToast({
        intent: Intent.SUCCESS,
        children:
          numberOfEmails > 1
            ? `${numberOfEmails} email invitations sent`
            : "Invite sent!",
      });
    },
    [
      invokeInviteByCoworker,
      toaster,
      onSubmit,
      coworkerIds,
      reset,
      openAddCoworkerDataModal,
      refetchStats,
      inputtedEmails,
      bulkInviteByEmail,
      openConfirmEmailModal,
    ]
  );

  const openModal = React.useCallback(() => {
    const { message } = getValues();
    setModal({
      type: "previewEmailModal",
      props: {
        groupName: groupData?.group.name,
        inviterName: groupData.myProfile.shortName,
        inviterProfilePic:
          groupData.myProfile.profilePic?.url ||
          `${process.env.REACT_APP_FRONTEND_URL}/avatar.png`,
        personalMessage: message,
      },
    });
  }, [setModal, groupData, getValues]);

  const onPasteEmails = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement, ClipboardEvent>) => {
      e.preventDefault();
      // eslint-disable-next-line dot-notation
      const clipboard = e["clipboardData"].getData("text");
      const newEmails = clipboard.split("\n");
      const existingEmails = e.currentTarget.value.split(",");
      const arr = [...existingEmails, ...newEmails]
        .map((str) => str.trim().toLowerCase())
        .filter((str) => !!str);

      // e.currentTarget.value = arr.join(", ");
      triggerChangeOfInput(e.currentTarget, arr.join(", "));
    },
    []
  );

  const disableEmailsField = emails && emails.length > 0;
  const defaultEmails = emails ? emails.join(", ") : "";

  if (groupLoading) {
    return (
      <div className="h-72">
        <Loading />
      </div>
    );
  }

  const footer = (
    <div className="flex flex-row">
      <Button buttonStyle="minimal" onClick={openModal} type="button">
        Preview email
      </Button>
      <Button
        className="ml-2"
        buttonStyle="brand"
        form="email-invite-form"
        type="submit"
        loading={loading}
        disabled={!coworkerIds && !inputtedEmails.emails?.length}
      >
        Send Invite
      </Button>
    </div>
  );

  const guts = (
    <>
      <div>
        {warnings}
        {saveError && (
          <InlineAlert title="Server Error" intent={Intent.FAILURE}>
            There was an error inviting this user
          </InlineAlert>
        )}
      </div>
      <FormGroup
        label="Email Addresses (Comma separated)"
        name="emails"
        id="emails"
      >
        <Input
          errorText={
            errors.emails && (
              <>
                {errors.emails.type === "required" &&
                  `Email is required and must be valid`}
                {errors.emails.type === "pattern" &&
                  `One or more of the email addresses you entered is not formatted correctly`}
              </>
            )
          }
          onChange={() => {
            setInputtedEmails(watch(["emails"]));
          }}
          register={register}
          onPaste={onPasteEmails}
          type="email"
          disabled={disableEmailsField}
          defaultValue={defaultEmails}
          registerArgs={{
            required: true,
            pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/gi,
          }}
        />
      </FormGroup>
      <FormGroup name="message" id="message" label="Personal Note">
        <Textarea
          maxLength={1600}
          errorText={
            errors.message && (
              <>
                {errors.message.type === "required" && "Message is required"}
                {errors.message.type === "maxLength" && "Message is too long"}
              </>
            )
          }
          defaultValue={`I'm using Frank to make things better at ${
            groupData?.group.company || "work"
          } and I could use your support. Join Frank and help me improve our workplace.`}
          register={register}
          registerArgs={{ required: true, maxLength: 1600 }}
        />
      </FormGroup>
      <div className="mb-4">
        <BulkInviteAdvancedOptions
          register={register}
          errors={errors}
          setValue={setValue}
        />
      </div>
    </>
  );

  if (view === "modal") {
    return (
      <>
        <ModalHeader title="Invite via email" />
        <ModalBody>
          <form
            id="email-invite-form"
            onSubmit={handleSubmit(submit)}
            noValidate
          >
            {guts}
            <ModalFooter border additionalClasses={["pt-3"]}>
              {footer}
            </ModalFooter>
          </form>
        </ModalBody>
      </>
    );
  }

  return (
    <>
      <form id="email-invite-form" onSubmit={handleSubmit(submit)} noValidate>
        {guts}
        <div className="my-3 py-4">{footer}</div>
      </form>
    </>
  );
};

export default EmailInvite;
