import { FullCenter } from "@get-frank-eng/design-system";
import * as React from "react";
import * as spinner from "react-spinners";

const Loading = () => (
  <FullCenter>
    <spinner.BeatLoader />
  </FullCenter>
);

export default Loading;
