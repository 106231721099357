import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";

import useCustomAction from "../dataAccess/mutations/useCustomActionMutation";

interface FormData {
  title: string;
  description: string;
}

const CreateCustomActionModal = ({
  campaignId,
  refetchCampaign,
}: {
  campaignId: string;
  refetchCampaign: () => void;
}) => {
  const { loading, createCustomAction } = useCustomAction({
    campaignId,
  });
  const { register, errors, handleSubmit } = useForm<FormData>();

  const toaster = useToaster();
  const { closeModal } = useModals();

  const submit = React.useCallback(
    async (fd: FormData) => {
      await createCustomAction({ ...fd });
      toaster.addToast({
        children: "Custom action created",
        intent: Intent.SUCCESS,
      });
      await refetchCampaign();
      await closeModal();
    },
    [createCustomAction, toaster, refetchCampaign, closeModal]
  );

  return (
    <>
      <ModalHeader title="Create custom action" />
      <ModalBody>
        <form
          className="flex-grow"
          onSubmit={handleSubmit(submit)}
          id="create-custom-action"
        >
          <FormGroup label="Title" name="title" id="title">
            <Input
              errorText={errors.title && "Title is required"}
              type="text"
              register={register}
              registerArgs={{
                required: true,
                maxLength: 200,
              }}
            />
          </FormGroup>
          <FormGroup label="Description" name="description" id="description">
            <Textarea
              errorText={errors.title && "Description is required"}
              maxLength={2000}
              register={register}
              registerArgs={{
                required: true,
                maxLength: 2000,
              }}
            />
          </FormGroup>
          <ModalFooter>
            <Button
              buttonStyle="brand"
              loading={loading}
              form="create-custom-action"
            >
              Publish action
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default CreateCustomActionModal;
