"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobilePreviewCard = exports.PreviewCard = void 0;
const constants_1 = require("../constants");
const Card_1 = require("./Card");
const Icon_1 = require("./Icon");
const React = __importStar(require("react"));
const PreviewCard = ({ url, imageUrl, title, subtitle, icon }) => {
    return (React.createElement(Card_1.Card, { target: "_blank", to: url },
        React.createElement("div", { className: "bg-cover bg-center h-32 bg-canvas-300", style: { backgroundImage: `url(${imageUrl})` } }),
        React.createElement("div", { className: "flex flex-row justify-between py-2 px-3" },
            React.createElement("div", { className: "w-4/5" },
                React.createElement("div", { className: "t-mini plus truncate" }, title),
                React.createElement("div", { className: "t-micro text-canvas-400 truncate block" }, subtitle)),
            React.createElement("div", { className: "text-canvas-400 self-center" }, icon && React.createElement(Icon_1.Icon, { icon: icon, size: constants_1.Sizes.MD })))));
};
exports.PreviewCard = PreviewCard;
const MobilePreviewCard = ({ url, imageUrl, title, subtitle }) => {
    return (React.createElement("a", { href: url, rel: "noreferrer", target: "_blank", className: "flex flex-row justify-between pb-6 border-b last:border-b-0" },
        React.createElement("div", { className: "pr-12" },
            React.createElement("div", { className: "t-small plus text-clamping-2 mb-1" }, title),
            React.createElement("div", { className: "t-mini text-canvas-400 uppercase" }, subtitle)),
        React.createElement("div", { className: "flex-shrink-0 bg-cover bg-center bg-canvas-300 rounded-sm", style: {
                height: "56px",
                width: "56px",
                backgroundImage: imageUrl ? `url(${imageUrl})` : undefined
            } })));
};
exports.MobilePreviewCard = MobilePreviewCard;
