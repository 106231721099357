"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useArrowKeyFocus = void 0;
const React = __importStar(require("react"));
const SPACEBAR_KEY_CODE = [0, 32];
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;
function useArrowKeyFocus({ ref, onEscape }) {
    const [activeIdx, setActiveIdx] = React.useState(-1);
    const incrementActive = React.useCallback(() => {
        var _a;
        const numOptions = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.children.length;
        if (numOptions) {
            setActiveIdx((activeIdx + 1) % numOptions);
        }
    }, [setActiveIdx, ref, activeIdx]);
    const decrementActive = React.useCallback(() => {
        var _a;
        const numOptions = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.children.length;
        if (!numOptions) {
            return;
        }
        if (activeIdx === 0) {
            setActiveIdx(numOptions - 1);
        }
        else {
            setActiveIdx((activeIdx - 1) % numOptions);
        }
    }, [setActiveIdx, ref, activeIdx]);
    React.useEffect(() => {
        if (!ref.current) {
            return;
        }
        const items = ref.current.querySelectorAll(".hoverable");
        const item = items[activeIdx];
        if (!item) {
            return;
        }
        item.focus();
    }, [activeIdx, ref]);
    const onEnter = React.useCallback(() => {
        if (!ref.current) {
            return;
        }
        const items = ref.current.children;
        const item = items[activeIdx];
        if (!item) {
            return;
        }
        item.click();
    }, [activeIdx, ref]);
    const onKeyDown = React.useCallback(e => {
        switch (e.keyCode) {
            case ENTER_KEY_CODE:
                onEnter();
                return;
            case SPACEBAR_KEY_CODE:
                onEnter();
                return;
            case DOWN_ARROW_KEY_CODE:
                e.preventDefault();
                incrementActive();
                return;
            case UP_ARROW_KEY_CODE:
                e.preventDefault();
                decrementActive();
                return;
            case ESCAPE_KEY_CODE:
                if (onEscape) {
                    onEscape();
                }
                break;
            default:
                break;
        }
    }, [incrementActive, decrementActive, onEscape, onEnter]);
    React.useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [ref, onKeyDown]);
}
exports.useArrowKeyFocus = useArrowKeyFocus;
