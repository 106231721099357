"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JumboAvatar = exports.AvatarList = exports.Avatar = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const sizes = {
    [constants_1.Sizes.XS]: "5",
    [constants_1.Sizes.SM]: "6",
    [constants_1.Sizes.MD]: "8",
    [constants_1.Sizes.LG]: "12",
    [constants_1.Sizes.XL]: "14"
};
const FacelessAvatar = ({ size, className, border, link = "" }) => (React.createElement(utils_1.ConditionalWrapper, { condition: !!link, wrapper: children => React.createElement(react_router_dom_1.Link, { to: link }, children) },
    React.createElement("span", { className: `inline-block h-${sizes[size]} w-${sizes[size]} ${size > constants_1.Sizes.XS ? "rounded" : "rounded-sm"}  overflow-hidden bg-canvas-600 border ${className || ""} ${border ? `ring-2 ring-${border}` : ""}` },
        React.createElement("svg", { className: "h-full w-full text-canvas-700", fill: "currentColor", viewBox: "0 0 24 24" },
            React.createElement("path", { d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" })))));
const Avatar = ({ size, src, altText, className, border, textPreview, link = "", index }) => {
    const [imgSrc, setImgSrc] = React.useState(src);
    const [text, setText] = React.useState(textPreview);
    if (!imgSrc && !text) {
        return (React.createElement(FacelessAvatar, { border: border, className: className, size: size, link: link }));
    }
    const classes = ` ${index ? index + " ring-current" : ""} inline-block h-${sizes[size]} w-${sizes[size]} ${size > constants_1.Sizes.XS ? "rounded" : "rounded-sm"} ${className || ""}  ${border ? `ring-2 ring-${border}` : ""}`;
    const onError = () => {
        setImgSrc(undefined);
        setText(altText || "???");
    };
    return (React.createElement(utils_1.ConditionalWrapper, { condition: !!link, wrapper: children => React.createElement(react_router_dom_1.Link, { to: link }, children) },
        React.createElement(React.Fragment, null,
            imgSrc && (React.createElement("img", { className: classes, src: imgSrc, alt: altText, onError: onError })),
            text && (React.createElement("div", { style: { fontSize: "31px" }, className: " flex items-center justify-center border bg-canvas-600 " +
                    classes }, text.slice(0, 1))))));
};
exports.Avatar = Avatar;
const JumboAvatar = ({ src, altText }) => (React.createElement("img", { className: `inline-block rounded-lg`, style: { width: "156px", height: "156px" }, src: src, alt: altText }));
exports.JumboAvatar = JumboAvatar;
const AvatarList = ({ avatarData, size, limit = 3 }) => {
    const space = size < constants_1.Sizes.SM ? 1 : 2;
    const maxZ = 4;
    return (React.createElement("div", { className: `flex -space-x-${space} overflow-hidden` }, avatarData.slice(0, limit).map((item, i) => (React.createElement(Avatar, { key: i, altText: item.alt, size: size, src: item.url, border: "current", link: item.link, index: `z-${maxZ - i + 1}0` })))));
};
exports.AvatarList = AvatarList;
