/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import keyBy from "lodash/keyBy";
import * as React from "react";
import { Node } from "slate";
import { Editable, ReactEditor, RenderLeafProps, Slate } from "slate-react";
import classNames from "classnames";
import * as ReactDOM from "react-dom";
import Caret from "./Caret";
import { ClientFrame } from "./Components";
import HoveringToolbar from "./HoveringToolbar";
import SlateComment from "./SlateComment";
import {
  ModalBackground,
  ModalBody,
  ModalContainer,
  Sizes,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

export function useOneTimeComments({
  comments,
}: {
  comments: FrankBackendTypes.DocumentComment[];
}) {
  const commentsById = React.useMemo(() => keyBy(comments, "id"), [comments]);
  function getComment(id: string) {
    return commentsById[id];
  }
  return { getComment };
}

export interface EditorFrame {
  editor: ReactEditor;
  comments: FrankBackendTypes.DocumentComment[];
  value: Node[];
  decorate: any;
  onComment: () => void;
  docId: string;
  onChange: (value: Node[]) => void;
  minHeight?: string;
}

const EditorFrame: React.FC<EditorFrame> = ({
  editor,
  value,
  onComment,
  comments,
  docId,
  decorate,
  onChange,
  minHeight = "",
}) => {
  const { getComment } = useOneTimeComments({ comments });
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;

  const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, [
    decorate,
  ]);

  const [focusedCommentId, setFocusedCommentId] = React.useState(null);

  const [focusedReplyToCommentId, setFocusedReplyToCommentId] = React.useState(
    null
  );

  const findCursorFocus = React.useCallback(() => {
    // assumes there will only be one text editor on the screen
    const replyForm = document.querySelector("textarea");
    if (replyForm) {
      setFocusedReplyToCommentId(
        replyForm.getAttribute("data-comment-reply-form")
      );
    }
    const { focusNode } = window.getSelection();
    // const { parentNode } = focusNode ?
    const commentHighlights = Array.from(
      document.querySelectorAll<HTMLElement>(".document-comment")
    );
    for (const commentHighlight of commentHighlights) {
      if (focusNode && commentHighlight.contains(focusNode.parentNode)) {
        // we are inside this thing
        setFocusedCommentId(commentHighlight.getAttribute("data-comment-id"));
        return;
      }
    }
    setFocusedCommentId(null);
  }, [setFocusedCommentId]);

  React.useEffect(() => {
    document.addEventListener("keydown", findCursorFocus);
    document.addEventListener("click", findCursorFocus);
    return () => {
      document.removeEventListener("keydown", findCursorFocus);
      document.removeEventListener("click", findCursorFocus);
    };
  }, [findCursorFocus]);

  const renderElement = React.useCallback(
    (props: any) => (
      <Element
        focusedCommentId={focusedCommentId}
        getComment={getComment}
        onComment={onComment}
        setFocusedCommentId={setFocusedCommentId}
        isMobile={isMobile}
        focusedReplyToCommentId={focusedReplyToCommentId}
        setFocusedReplyToCommentId={setFocusedReplyToCommentId}
        {...props}
      />
    ),
    [
      getComment,
      focusedCommentId,
      setFocusedCommentId,
      onComment,
      isMobile,
      focusedReplyToCommentId,
    ]
  );

  return (
    <>
      <ClientFrame minHeight={minHeight}>
        <Slate editor={editor} value={value} onChange={onChange}>
          <HoveringToolbar docId={docId} onComment={onComment} />

          <Editable
            renderElement={renderElement}
            autoFocus
            placeholder="Type your demand letter here..."
            renderLeaf={renderLeaf}
            // onKeyDown={() => {
            //   let selected;
            //   if (editor.selection !== null && editor.selection.anchor !== null) {
            //     selected = editor.children[editor.selection.anchor.path[0]];
            //   } else {
            //     selected = null;
            //   }

            //   console.log(selected);
            // }}
            decorate={decorate}
            style={{ minHeight }}
          />
        </Slate>
      </ClientFrame>
    </>
  );
};

export default EditorFrame;

const CommentHighlight = ({
  element,
  onComment,
  children,
  comment,
  focusedCommentId,
  setFocusedCommentId,
  isMobile,
  focusedReplyToCommentId,
  setFocusedReplyToCommentId,
}) => {
  if (!comment) {
    return children;
  }
  const cursorIsInComment = focusedCommentId === comment.id;
  const isReplyingToComment = focusedReplyToCommentId === comment.id;

  const root = document.getElementById("root");
  return (
    <>
      <span
        style={{
          boxDecorationBreak: "clone",
        }}
        className={classNames([
          "document-comment",
          "rounded",
          "bg-frank-blue-600",
          // "text-canvas-700",
          "border",
          "px-1",
          "transition-colors duration-200",
        ])}
        data-comment-id={element.commentId}
      >
        {children}
      </span>
      {comment && !isMobile && (
        <SlateComment
          onClick={() => setFocusedCommentId(comment.id)}
          emphasized={cursorIsInComment}
          onReply={onComment}
          onResolve={onComment}
          key={comment.id}
          comment={comment}
        />
      )}
      {comment &&
        isMobile &&
        ReactDOM.createPortal(
          <ModalBackground
            isOpen={cursorIsInComment || isReplyingToComment}
            onClose={() => setFocusedReplyToCommentId(null)}
          >
            <ModalContainer
              onModalBack={() => {}}
              stackSize={1}
              onClose={() => setFocusedReplyToCommentId(null)}
              isVisible={cursorIsInComment || isReplyingToComment}
              animateIn
              size={Sizes.SM}
            >
              <ModalBody>
                <SlateComment
                  comment={comment}
                  onReply={onComment}
                  onResolve={onComment}
                  emphasized={false}
                  onClick={null}
                  key={comment.id}
                />
              </ModalBody>
            </ModalContainer>
          </ModalBackground>,
          root
        )}
    </>
  );
};

export const Element: React.FC<any> = ({
  attributes,
  children,
  readonly,
  getComment,
  onComment,
  setFocusedCommentId,
  focusedCommentId,
  element,
  isMobile,
  focusedReplyToCommentId,
  setFocusedReplyToCommentId,
}) => {
  switch (element.type) {
    case "link":
      return (
        <a {...attributes} href={element.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    case "block-quote":
      return (
        <blockquote
          className="t-serif border-l italic p-4 my-2"
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case "comment":
      if (readonly) {
        return children;
      }
      const comment = getComment(element.commentId);
      return (
        <CommentHighlight
          onComment={onComment}
          focusedCommentId={focusedCommentId}
          setFocusedCommentId={setFocusedCommentId}
          focusedReplyToCommentId={focusedReplyToCommentId}
          setFocusedReplyToCommentId={setFocusedReplyToCommentId}
          element={element}
          comment={comment}
          isMobile={isMobile}
        >
          {children}
        </CommentHighlight>
      );
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "text":
      return children;
    case "editor":
      return children;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf: React.FC<RenderLeafProps> = ({
  attributes,
  children,
  leaf,
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return (
    <span
      {...attributes}
      style={
        {
          position: "relative",
          backgroundColor: leaf.alphaColor,
        } as any
      }
    >
      {leaf.isCaret ? <Caret {...(leaf as any)} /> : null}
      {children}
    </span>
  );
};
