export enum OnboardingStep {
  CREATE_ACCOUNT = "createAccount",
  TELL_US_ABOUT_YOURSELF = "tellUsAboutYourself",
  VERIFY = "verify",
}

export type ServerErrorTypes =
  | "createAccount"
  | "tellUsAboutYourself"
  | "verifyId"
  | "creditCard";

export interface State {
  loading: OnboardingStep | null;
  submitted: Set<OnboardingStep>;
  stepInFocus: OnboardingStep | null | undefined;
  serverError: null | {
    type: ServerErrorTypes;
    message: string;
  };
}

interface SetSubmittedAction {
  type: "setSubmitted";
  step: OnboardingStep;
}

interface SetStepInFocusAction {
  type: "setStepInFocus";
  step: OnboardingStep;
}

interface SetLoadingAction {
  type: "setLoading";
  step: OnboardingStep;
}

interface SetServerErrorAction {
  type: "setServerError";
  errorType: ServerErrorTypes;
  message: string;
}

export type Action =
  | SetStepInFocusAction
  | SetSubmittedAction
  | SetLoadingAction
  | SetServerErrorAction;

export function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "setSubmitted":
      return {
        ...prevState,
        loading: null,
        submitted: new Set<OnboardingStep>(prevState.submitted).add(
          action.step
        ),
      };
    case "setStepInFocus":
      return { ...prevState, stepInFocus: action.step };
    case "setLoading":
      return { ...prevState, loading: action.step, serverError: null };
    case "setServerError":
      return {
        ...prevState,
        loading: null,
        serverError: {
          type: action.errorType,
          message: action.message,
        },
      };
    default:
      return prevState;
  }
}
