import { Button, LinkButton, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useSignCampaign from "../dataAccess/mutations/useSignCampaign";
import useConfirmUnsignCampaignModal from "../modals/useConfirmUnsignCampaignModal";

const CampaignActionButton = ({
  relationshipToMe,
  campaignId,
  refetch,
  canUnsign,
  size = Sizes.LG,
}: {
  relationshipToMe: FrankBackendTypes.CampaignUserRelationship;
  campaignId: FrankBackendTypes.Campaign["id"];
  refetch: () => any;
  canUnsign: boolean;
  size?: Sizes;
}) => {
  const { unsign } = useSignCampaign({
    campaignId,
  });

  const { setModal } = useModals();

  const confirmUnsign = useConfirmUnsignCampaignModal({
    refetch,
    unsign,
  });

  switch (relationshipToMe) {
    case FrankBackendTypes.CampaignUserRelationship.Organizer:
      return (
        <LinkButton
          size={size}
          buttonStyle="brand"
          to={`/campaigns/${campaignId}/builder/category`}
        >
          Edit Campaign
        </LinkButton>
      );
    case FrankBackendTypes.CampaignUserRelationship.Supporter:
      return canUnsign ? (
        <Button
          size={size}
          type="button"
          buttonStyle="outline"
          onClick={confirmUnsign}
        >
          Remove signature
        </Button>
      ) : (
        <></>
      );
    case FrankBackendTypes.CampaignUserRelationship.None:
      return (
        <Button
          size={size}
          buttonStyle="brand"
          onClick={() => {
            setModal({
              type: "signDemandLetterModal",
              props: {
                campaignId,
              },
              afterClose: refetch,
            });
          }}
        >
          Sign Demand Letter
        </Button>
      );
    case FrankBackendTypes.CampaignUserRelationship.InvitedNotSupporter:
      return (
        <Button
          size={size}
          buttonStyle="brand"
          onClick={() => {
            setModal({
              type: "signDemandLetterModal",
              props: {
                campaignId,
              },
              afterClose: refetch,
            });
          }}
        >
          Sign Demand Letter
        </Button>
      );
    case FrankBackendTypes.CampaignUserRelationship.InvitedSupporter:
      return canUnsign ? (
        <Button
          size={size}
          type="button"
          buttonStyle="outline"
          onClick={confirmUnsign}
        >
          Remove signature
        </Button>
      ) : (
        <></>
      );
    default:
      throw new Error("needs a campaign relationship");
  }
};

export default CampaignActionButton;
