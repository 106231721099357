const columnDefs = {
  name: {
    Header: "Worker",
    accessor: "name",
    info: `Use the name most people will know this person by.`,
    sticky: "left",
    editable: true,
    placeholder: "Worker Name",
    isAlwaysInteractable: true,
  },

  employmentType: {
    Header: "Employment Type",
    accessor: "employmentType",
    width: 180,
    editable: true,
    info:
      "Frank is only for non-managers. Any email labeled as a manager will be blocked from joining Frank.",
    placeholder: "Employment Type",
    isAlwaysInteractable: true,
  },

  email: {
    Header: "Email Address",
    accessor: "email",
    info: `Pre-populating the email address of your coworkers makes verifying their identity faster and safer.`,
    editable: true,
    placeholder: "Email Address",
  },

  trust: {
    Header: "Trustworthiness",
    accessor: "yourTrust",
    info: `New workers require the trust of at least two existing members to join. Workers who receive a "Don't Trust" vote must earn two additional "Trust" votes to be admitted.`,
    minWidth: 215,
    editable: false,
  },

  status: {
    Header: "Status",
    info: `Follow up with coworkers who were invited by haven't yet joined the conversation.`,
    accessor: "status",
    minWidth: 150,
    editable: false,
  },

  joinedAt: {
    Header: "Date Joined",
    accessor: "joinedAtFormatted",
    minWidth: 150,
    editable: false,
  },

  notes: {
    Header: "Notes",
    accessor: "notes",
    info: `Share information about this worker — what they care about, who they're close with in the workplace. This helps you map your workplace more effectively.`,
    editable: true,
    placeholder: "Notes",
    maxWidth: 150,
    isAlwaysInteractable: true,
  },
};
export default columnDefs;
