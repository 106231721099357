import {
  Button,
  doesEmailHaveFreeDomain,
  FormGroup,
  InlineAlert,
  Input,
  Intent,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Frank from "../../components/icons/Frank";
import ShortFrank from "../../components/icons/ShortFrank";
import { useConfirmEmailModal } from "../ConfirmEmailModal";
import useSignUp from "../dataAccess/mutations/useSignUp";
import * as analytics from "../../analytics";
import useQuery from "../../hooks/useQuery";
import {EventTypes} from "frank-types"

type FormData = { email: string };
const Email = () => {
  const [serverErr, setServerErr] = React.useState<Error | null>();
  const { signUp, loading } = useSignUp();
  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const history = useHistory();
  const openConfirmEmailModal = useConfirmEmailModal();

  const qs = useQuery();

  const templateId = React.useMemo(() => qs.get("templateId"), [qs]);

  const signUpHandler = React.useCallback(
    async (params) => {
      const hasFreeDomain = doesEmailHaveFreeDomain(params.email);
      analytics.capture(EventTypes.AnalyticsEvents.SCREEN_SIGNUP_EMAIL, {
        email: params.email,
        hasFreeDomain,
        properties: {
          acValue: hasFreeDomain
            ? "Has free domain"
            : "Does not have free domain",
        },
      });
      if (!hasFreeDomain) {
        const confirm = await openConfirmEmailModal({
          workerTwo: false,
          email: params.email,
        });
        if (!confirm) {
          return;
        }
      }
      try {
        const { data } = await signUp(params.email, templateId);
        history.push(
          `/create-group/email-confirmation?email=${encodeURIComponent(
            params.email
          )}&groupId=${encodeURIComponent(data.signUp)}`
        );
      } catch (e) {
        setServerErr(e);
      }
    },
    [signUp, history, setServerErr, templateId, openConfirmEmailModal]
  );

  if (serverErr) {
    throw serverErr;
  }
  const { email } = watch();
  return (
    <div className="flex bg-canvas-800 h-screen w-screen">
      <div className="relative w-full flex flex-col justify-center items-center">
        <a
          href="https://getfrank.com"
          className="hidden sm:block absolute top-0 left-0 mt-4 ml-6"
        >
          <Frank />
        </a>
        <div className="px-6 sm:px-0 sm:max-w-xs sm:mx-auto w-full flex flex-col justify-center items-center">
          {serverErr && (
            <div className="py-4 w-full">
              <InlineAlert intent={Intent.FAILURE} title="Authentication Error">
                There was an error creating your account
              </InlineAlert>
            </div>
          )}
          <div className="h-12 w-12 rounded-lg bg-canvas-700 flex flex-row justify-center items-center mb-4">
            <ShortFrank />
          </div>
          <div className="t-title-4 plus">Sign up for Frank</div>
          <div>
            <span className="text-canvas-400">Have an account? </span>{" "}
            <Link to="/login" className="text-brand-300 t-regular plus">
              Log in
            </Link>
          </div>

          <form
            noValidate
            onSubmit={handleSubmit(signUpHandler)}
            className="pt-1 pb-3 w-full"
          >
            <div className="mb-6">
              <FormGroup id="email" label="" name="email" className="-mb-2">
                <Input
                  dataCy="email"
                  register={register}
                  errorText={
                    errors.email && (
                      <>
                        {errors.email.type === "required" &&
                          "Email is required"}
                        {errors.email.type === "pattern" && "Not a valid email"}
                      </>
                    )
                  }
                  registerArgs={{
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  }}
                  type="email"
                  disabled={loading}
                  placeholder="Personal email address"
                />
              </FormGroup>
            </div>

            <Button
              dataCy="sign-up"
              loading={loading}
              buttonStyle="brand"
              className="w-full"
              size={Sizes.LG}
              disabled={!email?.length}
            >
              Sign up for a free account
            </Button>
          </form>
        </div>
        <div className="hidden sm:block absolute bottom-0 w-full">
          <div className="flex flex-row justify-between pb-6 mx-6">
            <div className="py-0 text-white t-mini flex justify-center items-center">
              GetFrank, PBC
            </div>
            <div className="flex flex-row justify-center space-x-6">
              <a
                rel="noreferrer"
                href="https://twitter.com/GetFrank_com"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                Twitter
              </a>
              <a
                rel="noreferrer"
                href="https://medium.com/@getfrank"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                Blog
              </a>
              <a
                rel="noreferrer"
                href="https://app.getfrank.com/legal/privacy"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                Privacy
              </a>
              <a
                rel="noreferrer"
                href="https://app.getfrank.com/contact"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
