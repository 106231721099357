import {
  Button,
  Sizes,
  Intent,
  useToaster,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../Modals";
import AuthN from "./AuthN";

interface FormData {
  email: string;
}

const ForgotPasswordModal = ({ defaultEmail }: { defaultEmail?: string }) => {
  const { closeModal } = useModals();
  const { register, handleSubmit, errors } = useForm<FormData>();
  const toaster = useToaster();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const submit = React.useCallback(
    async ({ email }: FormData) => {
      setLoading(true);
      await AuthN.forgotPassword(email);
      setLoading(false);
      closeModal();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: `An email has been sent to ${email} with a link to rest your password`,
      });
    },
    [toaster, setLoading, closeModal]
  );

  return (
    <>
      <ModalHeader title="Reset password" />
      <ModalBody>
        <form
          id="forgot-password-form"
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <FormGroup name="email" label="Email" id="forgot-password-email">
            <Input
              dataCy="forgot-password-email"
              errorText={errors.email && "You must provide an email"}
              type="email"
              defaultValue={defaultEmail}
              register={register}
              registerArgs={{
                required: true,
                pattern: /^\S+@\S+$/i,
              }}
            />
          </FormGroup>
          <ModalFooter>
            <Button
              dataCy="submit-forgot-password"
              className="w-full md:w-auto"
              form="forgot-password-form"
              type="submit"
              size={Sizes.LG}
              loading={isLoading}
            >
              Reset password
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default ForgotPasswordModal;
