import * as React from "react";
import {
  useChannels,
  useCurrentChatUser,
  useMessages,
} from "@get-frank-eng/chat-client";
import { ChannelType, ChatTypes } from "frank-types";
import {
  FullCenter,
  ScrollToMe,
  MessageCard,
  useResponsive,
  HorizontalRule,
  TopBar,
  Sizes,
  useLayout,
  ScreenSizes,
  InlineAlert,
  Intent,
  Button,
  LinkButton,
  IconLinkButton,
  IconButton,
  useScrollToBottom,
  Delay,
} from "@get-frank-eng/design-system";
import truncate from "lodash/truncate";
import Crossroads from "../components/Crossroads";
import MessagesLoading from "./components/MessagesLoading";
import FrankCards from "./components/FrankCards";
import MessageTextarea from "./components/WrappedMessageBar";
import { useHistory, useRouteMatch } from "react-router";
import { useConfirmAndDeleteMessage } from "./hooks/useConfirmAndDelete";
import { getMentionHref } from "./util/getMentionHref";
import { useProcessAttachmentsLinksAndSendMessage } from "./hooks/useProcessAttachmentLinks";
import last from "lodash/last";
import useEmphasizedMessage from "./hooks/useEmphasizedMessage";
import ChatEmptyState from "./components/EmptyState";
import { useCursorToDisplay } from "./hooks/useCursorToDisplay";
import { mentionTriggerGen } from "./util/mentionTriggerGen";

function Channel({
  channelId,
  parentId,
  title,
}: {
  channelId: string;
  parentId?: string;
  title?: string;
}) {
  const [
    messageEditing,
    setMessageEditing,
  ] = React.useState<ChatTypes.Message | null>(null);

  const { screenSize } = useResponsive();

  const { channels, loading: channelsLoading } = useChannels();
  const thisChannel = React.useMemo(
    () => channels.find((ch) => ch.id === channelId),
    [channels, channelId]
  );

  const { startOfPage, emphasizedMessageId } = useEmphasizedMessage();

  const {
    errors,
    messages,
    messagesInFlight,
    sendMessage,
    loading,
    loadNewerMessages,
    loadOlderMessages,
    hasOlderMessages,
    hasNewerMessages,
    reactToMessage,
    enterChannel,
    typingUsers,
    leaveChannel,
    setDraft,
    draft,
    setViewCursor,
    editMessage,
    canSendMessage,
    deleteMessage,
    parent,
  } = useMessages({ channelId, parentId, startOfPage });

  const { cursorToDisplay } = useCursorToDisplay({
    channelId,
    messagesInFlight,
  });

  const { currentChatUser, currentAnonUser } = useCurrentChatUser();

  const isAnonymous = thisChannel?.type === ChannelType.ANONYMOUS;
  const currentOrAnonUser = isAnonymous ? currentAnonUser : currentChatUser;

  const lastMessage = React.useMemo(() => last(messages), [messages]);

  const outerContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);
  const { scrollToBottom } = useScrollToBottom({
    scrollKey: messagesInFlight.length
      ? last(messagesInFlight).sentAt.toString()
      : lastMessage?.id + JSON.stringify(lastMessage?.reactions),
    containerRef: scrollableContainerRef,
  });

  React.useEffect(() => {
    if (emphasizedMessageId) {
      return;
    }
    scrollToBottom();
  }, []);

  const confirmAndDeleteMessage = useConfirmAndDeleteMessage({
    screenSize,
    getMentionHref,
    currentUser: currentChatUser,
    deleteMessage,
  });
  const {
    processAttachmentsLinksAndSendMessage,
    loading: attachmentsLoading,
  } = useProcessAttachmentsLinksAndSendMessage({
    sendMessage,
    editMessage,
    setDraft,
    editingMessage: messageEditing,
    setMessageEditing,
  });

  const { setMobilePanel } = useLayout();
  const { go } = useHistory();

  React.useEffect(() => {
    const node = outerContainerRef.current;
    if (node) {
      node.addEventListener("focusin", setViewCursor);
    }
    return () => {
      if (node) {
        node.removeEventListener("focusin", setViewCursor);
      }
    };
  }, [outerContainerRef, setViewCursor]);

  React.useEffect(() => {
    enterChannel();

    return () => leaveChannel();
    // eslint-disable-next-line
  }, []);

  const { url } = useRouteMatch();

  if (!thisChannel) {
    return null;
  }

  if (errors.getMessages) {
    return (
      <FullCenter>
        <InlineAlert
          title="There was an error retrieving messages"
          intent={Intent.FAILURE}
          actions={
            <div className="flex flex-row space-x-2 justify-end mt-8">
              <Button
                size={Sizes.SM}
                buttonStyle="outline"
                onClick={() => go(0)}
              >
                Refresh
              </Button>
              <LinkButton to="../contact" size={Sizes.SM} buttonStyle="outline">
                Help
              </LinkButton>
            </div>
          }
        />
      </FullCenter>
    );
  }

  return (
    <div
      ref={outerContainerRef}
      className="flex-1 flex flex-col flex-shrink-0 relative bg-canvas-800"
    >
      {parent ? (
        <div>
          <TopBar
            title="Thread"
            classNames="mb-2"
            left={
              <IconLinkButton
                to={`../${parent.channelId}`}
                icon="arrow_back"
                size={Sizes.XL}
                buttonStyle="minimal"
              />
            }
          />
          <MessageCard
            showReplies={false}
            screenSize={screenSize}
            getMentionHref={getMentionHref}
            emphasizedMessageId={emphasizedMessageId || messageEditing?.id}
            showTimestamps={!isAnonymous}
            baseUrl={url}
            deleteMessage={() => confirmAndDeleteMessage(parent)}
            setMessageEditing={setMessageEditing}
            message={parent}
            loadingReaction={loading.reactToMessage}
            reactToMessage={reactToMessage}
            renderRichObjects={() => (
              <>
                {parent.richObjects.map((richObject) => (
                  <FrankCards
                    sendMessage={(text: string) =>
                      processAttachmentsLinksAndSendMessage({
                        text,
                        files: [],
                        links: [],
                      })
                    }
                    richObject={richObject}
                    key={richObject.id}
                    isLastMessage={parent.id === lastMessage?.id}
                  />
                ))}
              </>
            )}
            currentUser={currentChatUser}
          />
          <HorizontalRule>{parent.replySummary.count} replies</HorizontalRule>
        </div>
      ) : (
        screenSize < ScreenSizes.MD && (
          <TopBar
            classNames="bg-canvas-700"
            left={
              screenSize <= ScreenSizes.MD && (
                <IconButton
                  onClick={() => setMobilePanel("left")}
                  buttonStyle="minimal"
                  icon="menu"
                  size={Sizes.XL}
                />
              )
            }
            right={
              screenSize <= ScreenSizes.MD && (
                <IconButton
                  onClick={() => setMobilePanel("right")}
                  buttonStyle="minimal"
                  icon="info"
                  size={Sizes.XL}
                />
              )
            }
            title={title}
          />
        )
      )}

      <div
        ref={scrollableContainerRef}
        className="space-y-1 flex pb-72 sm:pb-3 flex-col flex-1 overflow-y-auto relative items-center"
      >
        {loading.getMessages || channelsLoading.getChannels ? (
          <>
            <div className="flex-grow flex-shrink-0" />
            <MessagesLoading />
          </>
        ) : (
          <>
            {messages.length === 0 && !parentId ? (
              <ChatEmptyState
                areMessagesHiddenBeforeMembership={
                  thisChannel?.type === ChannelType.ANONYMOUS ||
                  thisChannel?.type === ChannelType.MEMBERSHIP
                }
                isAnonymous={isAnonymous}
              />
            ) : (
              <div className="flex-grow" />
            )}

            {hasOlderMessages && (
              <Delay delay={1000}>
                <div className="flex-shrink-0">
                  <Crossroads onEntering={loadOlderMessages} />
                </div>
              </Delay>
            )}
            {messages?.map((message, i) => {
              const previousMessage = messages[i - 1];
              const directlyBelowViewCursor =
                cursorToDisplay > previousMessage?.createdAt &&
                cursorToDisplay < message.createdAt;

              return (
                <React.Fragment key={message.id}>
                  {directlyBelowViewCursor && (
                    <HorizontalRule
                      borderColor="border-brand-300"
                      textColor="text-brand-300"
                    >
                      New
                    </HorizontalRule>
                  )}
                  {emphasizedMessageId === message.id && (
                    <ScrollToMe key={message.id} />
                  )}
                  <MessageCard
                    screenSize={screenSize}
                    getMentionHref={getMentionHref}
                    baseUrl={url}
                    allowReplies={thisChannel.allowReplies}
                    deleteMessage={() => confirmAndDeleteMessage(message)}
                    lastMessage={previousMessage}
                    directlyBelowViewCursor={directlyBelowViewCursor}
                    emphasizedMessageId={
                      emphasizedMessageId || messageEditing?.id
                    }
                    setMessageEditing={setMessageEditing}
                    message={message}
                    loadingReaction={loading.reactToMessage}
                    showTimestamps={!isAnonymous}
                    reactToMessage={reactToMessage}
                    renderRichObjects={() => (
                      <>
                        {message.richObjects.map((richObject) => (
                          <FrankCards
                            sendMessage={(text: string) =>
                              processAttachmentsLinksAndSendMessage({
                                text,
                                files: [],
                                links: [],
                              })
                            }
                            richObject={richObject}
                            key={richObject.id}
                            isLastMessage={message.id === lastMessage?.id}
                          />
                        ))}
                      </>
                    )}
                    currentUser={currentOrAnonUser}
                  />
                </React.Fragment>
              );
            })}
            {messagesInFlight.map((messageInFlight, i) => (
              <MessageCard
                currentUser={currentOrAnonUser}
                screenSize={screenSize}
                // @ts-ignore
                message={{
                  author: currentOrAnonUser,
                  reactions: [],
                  replySummary: { authors: [], count: 0 },
                  type: ChatTypes.MessageType.Message,
                  createdAt: messageInFlight.sentAt,

                  body: messageInFlight.body,
                  sentAt: messageInFlight.sentAt,
                }}
                getMentionHref={getMentionHref}
                baseUrl={url}
                // @ts-ignore
                deleteMessage={() => {}}
                showTimestamps={!isAnonymous}
                // @ts-ignore
                lastMessage={{
                  author:
                    i === 0 && messages.length > 0
                      ? last(messages).author
                      : currentOrAnonUser,
                }}
                key={messageInFlight.sentAt}
              />
            ))}
            {!loading.getMessages && hasNewerMessages && (
              <div className="flex-shrink-0">
                <Crossroads onEntering={loadNewerMessages} />
              </div>
            )}
          </>
        )}
      </div>

      <div
        key={`${parentId || channelId}-editing-${messageEditing?.id}`}
        className="fixed bottom-0 sm:bottom-auto w-full sm:static flex-shrink-0 chat"
        style={{ zIndex: 100 }}
      >
        <div className="px-5 bg-canvas-800 pt-1">
          <MessageTextarea
            disabled={!canSendMessage}
            disableFileUpload={isAnonymous}
            disableMentions={isAnonymous}
            isAnonymous={isAnonymous}
            key={`${parentId || channelId}-editing-${messageEditing?.id}`}
            loading={attachmentsLoading}
            editingBody={messageEditing?.body}
            onCancel={() => setMessageEditing(null)}
            defaultValue={draft}
            onChange={!messageEditing ? setDraft : undefined}
            error={errors.sendMessage}
            placeholder={`Compose a message in ${truncate(thisChannel?.name, {
              length: 20,
            })}`}
            onSend={processAttachmentsLinksAndSendMessage}
            mentionTriggers={mentionTriggerGen(channels)}
          />
        </div>
        <div className="h-5 flex items-center bg-canvas-800">
          {typingUsers.length === 1 && (
            <div className="t-micro py-1 px-5">
              {typingUsers.map((u) => u.displayName).join(", ")} is typing
            </div>
          )}
          {typingUsers.length === 2 && (
            <div className="t-micro py-1 px-5">
              {typingUsers.map((u) => u.displayName).join(" and ")} are typing
            </div>
          )}
          {typingUsers.length > 2 && (
            <div className="t-micro py-1 px-5">Multiple people are typing</div>
          )}
        </div>
        {errors.sendMessage && (
          <div className="text-brand-600 t-mini">
            Something went wrong sending this message
          </div>
        )}
      </div>
    </div>
  );
}

export default Channel;
