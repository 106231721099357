/* eslint-disable no-restricted-syntax */
import * as React from "react";
import DocumentTitle from "react-document-title";
import {
  Redirect,
  Route as RawRoute,
  Switch,
  useLocation,
} from "react-router-dom";
import { AuthStates, useAuthState } from "./Auth/AuthState";
import Logout from "./Auth/Logout";
import PasswordReset from "./Auth/PasswordReset";
import CampaignBuilder from "./CampaignBuilder";
import CampaignDraftListPage from "./CampaignList";
import InviteCoworkersToCollaborate from "./Collaborators/InviteCoworkersToCollaborate";
import Contact from "./Contact";
import Home from "./Home";
import usePageTracker from "./hooks/usePageTracker";
import Login from "./Login";
import VerifyEmail from "./Onboarding/VerifyEmail";
import Profile from "./Profile";
import Route from "./RouteComponents";
import Templates from "./Templates";
import PreviewTemplate from "./Templates/pages/PreviewTemplate";
import CampaignCollaboratorInvite from "./Campaigns/CampaignCollaboratorInvite";
import CampaignDetail from "./CampaignDetail";
import FinishOnboardingLater from "./Onboarding/FinishOnboardingLater";
import RedirectToProfileFromCoworkerId from "./Profile/RedirectToProfileFromCoworkerId";
import { InitialOnboarding } from "./Onboarding";
import TopicDetail from "./Topics/pages/TopicDetail";
import CreateNewGroup from "./SSO";
import Account from "./Account";
import RouteToMessage from "./Chat/RouteToMessage";
import { FrankBackendTypes } from "frank-types";
import RedirectToTopicOrCampaignFromChannelId from "./Channel/RedirectToTopicOrCampaignFromChannelId";
import { CoworkerRoutes } from "./Coworkers/CoworkerRoutes";
import RedirectOutsideOfRouter from "./components/RedirectOutsideOfRouter";
import JoinGroup from "./JoinGroup";
import FromFrankDetail from "./FromFrank/pages/FromFrankDetail";

function useScrollToTopOnPageChange() {
  const { pathname } = useLocation();
  React.useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
}

const Routes = () => {
  useScrollToTopOnPageChange();
  usePageTracker();
  const { authState, onboardingState } = useAuthState();

  return (
    <DocumentTitle title="Frank">
      <Switch>
        <RawRoute path="/create-group">
          <CreateNewGroup />
        </RawRoute>

        <RawRoute path="/join-group">
          <JoinGroup />
        </RawRoute>
        <Route path="/logout">
          <Logout />
        </Route>

        <Route auth path="/coworkerProfiles/:coworkerId">
          <RedirectToProfileFromCoworkerId />
        </Route>

        <Route auth path="/channel/:channelId">
          <RedirectToTopicOrCampaignFromChannelId />
        </Route>

        <Route sidebar auth path="/coworkers">
          <CoworkerRoutes />
        </Route>

        <Route auth path="/messages/:messageId">
          <RouteToMessage />
        </Route>

        <Route onboarding path="/verifyEmail">
          <VerifyEmail />
        </Route>

        <Route path="/onboarding">
          <InitialOnboarding />
        </Route>

        <Route auth onboarding path="/finish-onboarding">
          <FinishOnboardingLater />
        </Route>

        <Route
          onboarding={
            onboardingState !==
            FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          sidebar={
            authState === AuthStates.LOGGED_IN &&
            onboardingState ===
              FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          auth={
            authState === AuthStates.LOGGED_IN &&
            onboardingState ===
              FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          path="/contact"
        >
          <Contact />
        </Route>
        <RawRoute path="/legal">
          <RedirectOutsideOfRouter to="https://getfrank.notion.site/Legal-Documents-c4008b6c92a24f0482df71ccf6060120" />
        </RawRoute>
        <Route auth sidebar path="/home">
          <Home />
        </Route>
        <Route auth sidebar path="/campaigns/:campaignId/collaboratorInvite">
          <CampaignCollaboratorInvite />
        </Route>
        <Route auth path="/campaigns/:campaignId/inviteCollaborators">
          <InviteCoworkersToCollaborate />
        </Route>
        <Route auth path="/campaigns/:campaignId/builder">
          <CampaignBuilder />
        </Route>
        <Route auth sidebar path="/topics/:slug">
          <TopicDetail />
        </Route>
        <Route auth sidebar path="/from-frank">
          <FromFrankDetail />
        </Route>
        <Route auth sidebar path="/campaigns/drafts">
          <CampaignDraftListPage />
        </Route>
        <Route auth sidebar path="/campaigns/:campaignId">
          <CampaignDetail />
        </Route>
        <Route auth sidebar path="/users/:userId">
          <Profile />
        </Route>
        <Route auth path="/templates/:templateId/preview">
          <PreviewTemplate />
        </Route>
        <Route auth path="/templates">
          <Templates />
        </Route>
        <Route auth sidebar path="/account">
          <Account />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/passwordReset">
          <PasswordReset />
        </Route>
        <Route>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </DocumentTitle>
  );
};

export default Routes;
