import {
  ActivityFeed,
  EmptyState,
  IconButton,
  IconLinkButton,
  RightSideBarOpenOnMobile,
  ScreenSizes,
  SectionHeader,
  Sizes,
  TopBar,
  useLayout,
  useScreenSize,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ChannelAttachments from "../../Channel/ChannelAttachments";
import Polls from "../../Channel/Polls";
import Channel from "../../Chat";
import { RightSideRoute } from "../../RouteComponents";
import ReactMarkdown from "react-markdown";
import useHomePageData from "../../Home/dataAccess/useHomePageData";
import PendingMemberList from "../../PendingMembers/pages/PendingMemberList";
import { useModals } from "../../Modals";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import useHomeFeedData from "../../Home/dataAccess/useHomeFeedData";

const Index = ({ channelId }: { channelId: string }) => {
  const { url } = useRouteMatch();
  const { setMobilePanel } = useLayout();

  const screensize = useScreenSize();

  return (
    <>
      {screensize <= ScreenSizes.SM && (
        <div className="bg-canvas-700">
          <TopBar
            left={
              <IconButton
                icon="close"
                buttonStyle="minimal"
                onClick={() => setMobilePanel(null)}
              />
            }
            title={`#from-frank`}
          />
        </div>
      )}
      <div className="p-6 bg-canvas-700 flex-grow">
        {screensize > ScreenSizes.SM && (
          <h1 className="t-title-4 plus">{`#from-frank`}</h1>
        )}
        <p className="t-small full-formatting">
          <ReactMarkdown children="This is your home page. Come here for updates and to learn more about using Frank." />
        </p>
        <div className="h-8" />
        <div className="space-y-8">
          <div>
            <h2 className="t-regular plus mb-2">Membership requests</h2>
            <MembershipRequests channelId={channelId} />
          </div>
          <div>
            <h2 className="t-regular plus mb-2">Group Activity</h2>
            <GroupActivity />
          </div>
          {/* <SectionHeader to={url + "/membership-requests"}>
            Membership Requests
          </SectionHeader>
          <SectionHeader to={url + "/group-activity"}>
            Group Activity
          </SectionHeader> */}
        </div>
      </div>
    </>
  );
};

function MembershipRequests({ channelId }: { channelId: string }) {
  const { data, loading, error } = useHomePageData();
  const { setModal } = useModals();
  if (loading) {
    return null;
  }
  return (
    <>
      {data?.coworkers.total > 0 ? (
        <PendingMemberList />
      ) : (
        <>
          <EmptyState
            onClick={() => setModal({ type: "defaultInviteModal", props: {} })}
            title="You have no membership requests"
            buttonLabel="Invite Coworkers"
          >
            Groups on Frank are invite-only. Once invited, verified members in
            the group must review applications for new members before they are
            verified.
          </EmptyState>
        </>
      )}
    </>
  );
}

function GroupActivity() {
  const { data, loading, error, fetchMoreHomeFeed } = useHomeFeedData({
    perPage: 10,
  });
  const { setModal } = useModals();
  if (loading) {
    return null;
  }
  return (
    <Pager<FrankBackendTypes.Activity>
      pagination={data}
      onShowMore={fetchMoreHomeFeed}
      paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
    >
      <ActivityFeed activities={data?.objects} />
    </Pager>
  );
}

const FromFrankRightSide = ({ channelId }: { channelId: string }) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      {/* <RightSideRoute path={`${url}/files`}>
        <ChannelAttachments topicId={topic.id} />
      </RightSideRoute>
      <RightSideRoute path={`${url}/polls`}>
        <div className="bg-canvas-700 flex-grow">
          <Polls channelId={topic.channelId} topicId={topic.id} />
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/resources`}>
        <TopBar
          classNames="bg-canvas-700"
          title="Resources"
          left={
            <IconLinkButton
              to={`../${topic.id}`}
              icon="arrow_back"
              size={Sizes.XL}
              buttonStyle="minimal"
            />
          }
        />
      </RightSideRoute>

       */}
      {/* <Route
        path={`${url}/membership-requests`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <MembershipRequests channelId={channelId} />
            </>
          );
        }}
      />

      <Route
        path={`${url}/group-activity`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <GroupActivity channelId={channelId} />
            </>
          );
        }}
      /> */}
      <Route
        path={`${url}/thread/:threadId`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <Channel
                channelId={channelId}
                parentId={match.params.threadId}
                key={match.params.threadId}
              />
            </>
          );
        }}
      />
      <Route path={`${url}/`}>
        <Index channelId={channelId} />
      </Route>
    </Switch>
  );
};

export default FromFrankRightSide;
