import { FormGroup, Icon, Input, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";
import LittleMinus from "../components/icons/LittleMinus";
import LittlePlus from "../components/icons/LittlePlus";
import useUpdateUpdateCampaign from "../dataAccess/mutations/useUpdateCampaign";

const Tip = ({ leftIcon, children }) => {
  return (
    <div className="mb-2 flex flex-row items-center">
      {leftIcon && <div className="mr-1">{leftIcon}</div>}
      {children}
    </div>
  );
};

const Title = ({
  campaign,
  refetch,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => void;
}) => {
  const { register, handleSubmit, errors } = useForm<{ title: string }>();
  const { updateCampaign, loadingUpdateCampaign } = useUpdateUpdateCampaign({
    campaignId: campaign.id,
  });
  const changeTitle = React.useCallback(
    async ({ title }: { title: string }) => {
      await updateCampaign({
        title,
      });
      await refetch();
    },
    [updateCampaign, refetch]
  );

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">Give your campaign a title</h2>
      <p className="t-regular text-canvas-400 mb-8">
        Titles will be the first thing that attracts attention from potential
        supporters of your campaign.
      </p>
      <form onSubmit={handleSubmit(changeTitle)} className="mb-4">
        <FormGroup label="Title" name="title" id="title">
          <Input
            errorText={
              (errors?.title?.type === "required" && `Title is required`) ||
              (errors?.title?.type === "maxLength" &&
                "Title must be less than 65 characters")
            }
            type="text"
            onBlur={handleSubmit(changeTitle)}
            register={register}
            defaultValue={campaign.title}
            disabled={loadingUpdateCampaign}
            registerArgs={{ required: true, minLength: 0, maxLength: 65 }}
          />
        </FormGroup>
      </form>
      <section className="mt-8">
        <h3 className="t-small plus flex flex-row items-center mb-2">
          <div className=" mr-2">
            <Icon rotate="50" icon="wb_incandescent" size={Sizes.LG} />
          </div>
          Tips for a strong title
        </h3>
        <div className="grid grid-cols-2 gap-2 t-mini">
          <div>
            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              Keep it short and to the point
            </Tip>

            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              Make it a call to action
            </Tip>
            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              Keep it simple
            </Tip>
          </div>
          <div>
            <Tip
              leftIcon={
                <div className="text-brand-600">
                  <LittleMinus />
                </div>
              }
            >
              Avoid being vague and indirect
            </Tip>
            <Tip
              leftIcon={
                <div className="text-brand-600">
                  <LittleMinus />
                </div>
              }
            >
              Keep out the names of organizers
            </Tip>
          </div>
        </div>
      </section>
      <section className="mt-8 mb-16">
        <h3 className="t-small plus flex flex-row items-center">
          <div className="mr-2">
            <Icon rotate="50" icon="wb_incandescent" size={Sizes.LG} />
          </div>
          Examples
        </h3>
        <div className="t-regular py-6 border-b">
          End Forced Arbitration for Sexual Misconduct Cases
        </div>
        <div className="t-regular py-6 border-b">
          401k Matching for All Workers at [Organization]
        </div>
        <div className="t-regular py-6 border-b">
          Release Worker Schedules 2 Weeks in Advance
        </div>
      </section>
      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Title;
