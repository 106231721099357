import { useMutation, gql } from "@apollo/client";
import {
  Button,
  doesEmailHaveFreeDomain,
  FormGroup,
  Input,
  Password,
  PasswordStrengthMeter,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as analytics from "../analytics";
import usePasswordStrength from "../Auth/usePasswordStrength";
import useSession from "../Auth/useSession";
import FieldError from "../components/FieldError";
import { useConfirmEmailModal } from "../SSO/ConfirmEmailModal";
import { DispatcherAPI } from "./state/dispatcher";
import { OnboardingStep } from "./state/state";

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountDTO!) {
    createAccount(input: $input) {
      userId
      email
    }
  }
`;

interface FormData {
  email: string;
  password: string;
}

function useSubmit({
  onSubmit,
  onboardingData,
  setLoading,
  setServerError,
  token,
  emailProvidedWithInvite,
}: {
  onSubmit: any;
  onboardingData: FrankBackendTypes.Onboarding;
  setServerError: DispatcherAPI["setServerError"];
  setLoading: DispatcherAPI["setLoading"];
  token: string;
  emailProvidedWithInvite: boolean;
}) {
  const { login } = useSession({ groupId: onboardingData.groupId });
  const openConfirmEmailModal = useConfirmEmailModal();
  const [invokeCreateAccount, { error: saveError }] = useMutation<
    {
      createAccount: FrankBackendTypes.CreateAccountResponseDto;
    },
    FrankBackendTypes.MutationCreateAccountArgs
  >(CREATE_ACCOUNT);

  const submit = React.useCallback(
    async (formdata: FormData) => {
      try {
        if (
          !emailProvidedWithInvite &&
          !doesEmailHaveFreeDomain(formdata.email)
        ) {
          const confirm = await openConfirmEmailModal({
            workerTwo: onboardingData.inviteType === "email",
            email: formdata.email,
          });
          if (!confirm) {
            return;
          }
        }
        setLoading(OnboardingStep.CREATE_ACCOUNT);
        const { data } = await invokeCreateAccount({
          variables: {
            input: {
              password: formdata.password,
              email:
                onboardingData.inviteType === "email"
                  ? undefined
                  : formdata.email,
              token,
              onboardingId: onboardingData.id,
            },
          },
        });
        await login(data.createAccount.email, formdata.password);
        analytics.identify(data.createAccount.userId);
        onSubmit(formdata);
      } catch (e) {
        setServerError("createAccount", "createAccount error");
        throw e;
      }
    },
    [
      setLoading,
      login,
      onSubmit,
      onboardingData,
      setServerError,
      invokeCreateAccount,
      token,
      openConfirmEmailModal,
    ]
  );

  return {
    submit,
    saveError,
  };
}

const CreateAccountForm = ({
  onSubmit,
  setLoading,
  isLoading,
  setServerError,
  onboardingData,
  token,
  setDangerousEmail,
}: {
  onSubmit: any;
  isLoading: boolean;
  setLoading: DispatcherAPI["setLoading"];
  setServerError: DispatcherAPI["setServerError"];
  onboardingData: FrankBackendTypes.Onboarding;
  token: string;
  setDangerousEmail: (arg) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
  } = useForm<FormData>();

  const watchingFields = watch();

  const fieldsComplete =
    Object.values(watchingFields).filter((field) => !!field.length).length ===
    2;
  const errorsPresent = !!Object.keys(errors).length;

  const password = watchingFields.password;

  const passwordStrength = usePasswordStrength(password);

  const showPasswordError =
    formState.dirtyFields.has("password") &&
    passwordStrength > -1 &&
    passwordStrength < 3;

  const fieldsDisabled = isLoading;
  const emailProvidedWithInvite = !!onboardingData.email;

  const { submit, saveError } = useSubmit({
    onSubmit,
    onboardingData,
    setServerError,
    setLoading,
    emailProvidedWithInvite,
    token,
  });

  if (saveError?.message.includes("email is dangerous")) {
    setDangerousEmail(true);
  }

  return (
    <>
      <form
        noValidate
        className="text-left mt-6"
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup name="email" id="email" label="Personal email address">
          <Input
            dataCy="email"
            errorText={errors.email && `Email is required and must be valid`}
            type="email"
            disabled={emailProvidedWithInvite}
            defaultValue={onboardingData.email}
            register={register}
            registerArgs={{
              required: true,
              pattern: emailProvidedWithInvite ? undefined : /^\S+@\S+$/i,
            }}
            helpText={
              emailProvidedWithInvite
                ? "This is the email your coworker invited you with. If you want to use a different email address, please ask your coworker for a new invite with that email."
                : undefined
            }
          />
        </FormGroup>
        <FormGroup name="password" id="password" label="Create a password">
          <Password
            disabled={fieldsDisabled}
            register={register}
            registerArgs={{ required: true, minLength: 6, maxLength: 255 }}
          />
        </FormGroup>
        {!!watchingFields?.password?.length && (
          <PasswordStrengthMeter
            password={password}
            passwordStrength={passwordStrength}
          />
        )}
        {saveError && (
          <div className="ml-2 mb-2">
            <FieldError>There was an error processing your request.</FieldError>
          </div>
        )}
        <div className="py-4">
          <Button
            size={Sizes.LG}
            full
            buttonStyle="brand"
            loading={isLoading}
            disabled={
              !fieldsComplete ||
              errorsPresent ||
              showPasswordError ||
              password?.length < 6 ||
              !password
            }
            className="mt-1"
          >
            Create Account
          </Button>
        </div>
        <div className="t-small text-canvas-400 text-center">
          By signing up for Frank, you acknowledge you have read our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_FRONTEND_URL}/legal/tos`}
            className="underline"
          >
            Policies
          </a>
          .
        </div>
      </form>
    </>
  );
};

export default CreateAccountForm;
