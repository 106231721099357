import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Redirect } from "react-router";
import useIntercom from "../useIntercom";
import { useAuthState } from "./AuthState";
import SentryClient from "../sentryClient";
import { identify } from "../analytics";

interface CurrentUserData {
  myProfile: FrankBackendTypes.MyProfile;
  refetchCurrentUserData: () => void;
}

const currentUserContext = React.createContext<CurrentUserData | null>(null);

const getMyProfile = gql`
  query Profile {
    myProfile {
      id
      name
      intercomHash
      email
      shortName
      firstName
      groups {
        id
        isCurrentGroup
        name
        company
        size
        logo {
          url
        }
      }
      profilePic {
        id
        url
      }
      workerType
      onboardingWorkflowState
    }
  }
`;

export const CurrentUserProvider = ({ children }) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "myProfile">
  >(getMyProfile);

  const { setOnboardingState } = useAuthState();

  const currentGroup: FrankBackendTypes.Group = React.useMemo(() => {
    if (data?.myProfile) {
      return data?.myProfile.groups.find((group) => group.isCurrentGroup);
    }
    return {} as FrankBackendTypes.Group;
  }, [data]);

  useIntercom({
    userId: data?.myProfile.id,
    intercomHash: data?.myProfile.intercomHash,
    email: data?.myProfile.email,
    groupId: currentGroup?.id,
    groupName: currentGroup?.name,
  });

  React.useEffect(() => {
    if (data) {
      try {
        SentryClient.configureScope((scope) => {
          scope.setTag("groupId", currentGroup?.id);
          scope.setUser({ id: data?.myProfile.id });
        });
        setOnboardingState({
          onboardingState: data.myProfile.onboardingWorkflowState,
        });
        identify(data.myProfile.id);
      } catch (e) {}
    }
  }, [data, currentGroup, setOnboardingState]);

  if (loading) {
    return null;
  }

  if (error) {
    return <Redirect to="/logout" />;
  }

  return (
    <currentUserContext.Provider
      value={{ myProfile: data?.myProfile, refetchCurrentUserData: refetch }}
    >
      {children}
    </currentUserContext.Provider>
  );
};

export function useCurrentUserData() {
  const { myProfile, refetchCurrentUserData } = React.useContext(
    currentUserContext
  );
  const currentGroup: FrankBackendTypes.Group = React.useMemo(() => {
    if (myProfile) {
      return myProfile.groups.find((group) => group.isCurrentGroup);
    }
    return {} as FrankBackendTypes.Group;
  }, [myProfile]);

  const notCurrentGroups: FrankBackendTypes.Group[] = React.useMemo(() => {
    if (myProfile) {
      return myProfile.groups.filter((group) => !group.isCurrentGroup);
    }
    return [] as FrankBackendTypes.Group[];
  }, [myProfile]);
  return { myProfile, currentGroup, notCurrentGroups, refetchCurrentUserData };
}
