import isNumber from "lodash/isNumber";
import * as React from "react";
import { Cell } from "react-table";
import { ScreenSizes, useResponsive } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

type CellDirection = "left" | "right" | "up" | "down";

const offsetsForDirection = (
  numCells: number,
  numRows: number,
  direction: CellDirection
) =>
  ({
    left: -1,
    right: 1,
    up: (-1 * numCells) / numRows,
    down: numCells / numRows,
  }[direction]);

function focusElemFromElemSet(
  nodes: NodeListOf<HTMLInputElement>,
  idx: number,
  direction: CellDirection
) {
  if (nodes[idx]) {
    nodes[idx].focus();
    if (direction === "right") {
      nodes[idx].selectionStart = 0;
      nodes[idx].selectionEnd = 0;
    } else {
      const { length } = nodes[idx].value;
      nodes[idx].selectionStart = length - 1;
      nodes[idx].selectionEnd = length - 1;
    }
  }
}

function handleSelectEvent(direction: CellDirection) {
  const el = document.querySelector<HTMLInputElement>(
    `.cell [role="gridcell"]:focus`
  );
  if (!el) {
    return;
  }

  const rows = document.querySelectorAll<HTMLDivElement>(".row.editable");

  const cells = document.querySelectorAll<HTMLInputElement>(
    `.cell [role="gridcell"]:not(:disabled)`
  );
  const currentIdx = Array.from(cells).indexOf(el);
  const offset = offsetsForDirection(cells.length, rows.length, direction);

  focusElemFromElemSet(cells, currentIdx + offset, direction);
}

function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
  const node = event.currentTarget;

  const { selectionStart, selectionEnd } = node;

  const regularCursorWithNoSelection = selectionStart === selectionEnd;

  const atEnd =
    regularCursorWithNoSelection &&
    isNumber(selectionStart) &&
    selectionStart === node.value.length - 1;

  const atStart =
    regularCursorWithNoSelection &&
    isNumber(selectionStart) &&
    selectionStart <= 1;

  switch (event.key) {
    case "Enter": {
      event.preventDefault();
      handleSelectEvent("down");
      break;
    }
    case "ArrowUp": {
      event.preventDefault();
      handleSelectEvent("up");
      break;
    }
    case "ArrowDown": {
      event.preventDefault();
      handleSelectEvent("down");
      break;
    }
    case "ArrowLeft": {
      if (atStart) {
        handleSelectEvent("left");
      }
      break;
    }
    case "ArrowRight": {
      if (atEnd) {
        handleSelectEvent("right");
      }
      break;
    }
    default: {
    }
  }
}

const EditableTextCell = ({
  disabled = false,
  cell,
  changeCell,
  placeholder,
  type = "text",
  style = {},
  classNames,
}: {
  disabled?: boolean;
  cell: Cell<FrankBackendTypes.Coworker> | { value: string };
  changeCell: (str: string) => void;
  placeholder?: string;
  type?: string;
  style?: React.CSSProperties;
  classNames?: string;
}) => {
  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.SM;
  return (
    <input
      role="gridcell"
      type={type}
      placeholder={placeholder}
      autoComplete="none"
      style={{ ...style, boxShadow: "none" }}
      onKeyDown={!isMobile ? onKeyDown : undefined}
      disabled={disabled}
      tabIndex={0}
      onBlur={(e: React.SyntheticEvent<HTMLInputElement>) =>
        changeCell(e.currentTarget.value)
      }
      className={`w-full outline-none no-focus-ring truncate ${classNames}`}
      defaultValue={cell.value}
    />
  );
};

export default EditableTextCell;
