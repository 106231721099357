import {
  Button,
  FormGroup,
  Sizes,
  Textarea
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Range } from "slate";
import { useEditor } from "slate-react";
import { v4 as uuid } from "uuid";
import FloatAroundSelection from "./FloatAroundSelection";
import {
  insertComment,
  isCommentActive,
  unwrapComment
} from "./plugins/comment";
import usePostComment from "./usePostComment";

const CommentForm = ({
  lastDomSelection,
  setFollowUp,
  commentRange,
  onComment,
  docId
}: {
  commentRange: Range;
  lastDomSelection: Selection;
  setFollowUp: any;
  onComment: () => void;
  docId: string;
}) => {
  const { handleSubmit, register, errors } = useForm<{ comment: string }>();

  const { invokePostComment, loading } = usePostComment();
  const editor = useEditor();
  const submit = React.useCallback(
    async ({ comment }: { comment: string }) => {
      const isActive = isCommentActive(editor);

      const commentId = uuid();

      await invokePostComment({
        variables: { input: { comment, commentId, documentId: docId } }
      });

      if (isActive) {
        return unwrapComment(editor);
      }
      insertComment(editor, commentId, commentRange);
      setFollowUp(null);
    },
    [editor, commentRange, setFollowUp, invokePostComment, docId]
  );

  return (
    <FloatAroundSelection
      onClickOutside={() => {
        setFollowUp(null);
      }}
      selection={lastDomSelection}
    >
      <form onSubmit={handleSubmit(submit)}>
        <FormGroup name="comment" id="comment" label="Comment">
          <Textarea
            errorText={errors.comment && "Comment Required"}
            rows={2}
            register={register}
            registerArgs={{ required: true }}
          />
        </FormGroup>
        <Button loading={loading} size={Sizes.SM} type="submit">
          Comment
        </Button>
      </form>
    </FloatAroundSelection>
  );
};

export default CommentForm;
