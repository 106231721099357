import {
  ActivityFeed,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import Loading from "../components/Loading";
import Pager, { PaginationStrategy } from "../components/Pager";
import useHomeFeedData from "./dataAccess/useHomeFeedData";

export default function HomeFeedModal() {
  const { data, loading, error, fetchMoreHomeFeed } = useHomeFeedData({
    perPage: 10,
  });
  return (
    <>
      <ModalHeader title="Feed" />
      <ModalBody>
        {loading && <Loading />}
        {!loading && (
          <Pager<FrankBackendTypes.Activity>
            pagination={data}
            onShowMore={fetchMoreHomeFeed}
            paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
          >
            <ActivityFeed activities={data.objects} />
          </Pager>
        )}
      </ModalBody>
    </>
  );
}
