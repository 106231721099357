import {
  Button,
  FormGroup,
  Input,
  Intent,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import useUpdateProfile from "../dataAccess/mutations/useUpdateProfile";

type FormParams = {
  name: string;
};

export const ProfileSettings = () => {
  const { myProfile } = useCurrentUserData();
  const toaster = useToaster();
  const { updateProfile } = useUpdateProfile();
  const { register, handleSubmit } = useForm<FormParams>();
  const [isDirty, setIsDirty] = React.useState(false);

  const saveUpdate = async (formData: FormParams) => {
    try {
      await updateProfile({ name: formData.name });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: "Successfully updated profile",
      });
      setIsDirty(false);
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: "Something went wrong updating profile",
      });
    }
  };

  return (
    <div className="max-w-md">
      <form
        onSubmit={handleSubmit(saveUpdate)}
        noValidate
        className="space-y-8"
      >
        <section>
          <div className="t-small plus">Profile photo</div>
          <AddPhoto
            defaultUrl={myProfile.profilePic?.url || null}
            onUpload={(err, url) => {
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: "Successfully updated profile picture",
              });
              setIsDirty(true);
              if (err) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children:
                    "Something went wrong updating your profile picture",
                });
              }
            }}
          />
        </section>
        <FormGroup name="name" label="Full name" id="name">
          <Input
            register={register}
            id="name"
            maxLength={120}
            placeholder="Enter your full name"
            helpText="What name do your coworkers know you by?"
            defaultValue={myProfile.name || null}
            onChange={() => setIsDirty(true)}
          />
        </FormGroup>
        <Button type="submit" buttonStyle="brand" disabled={!isDirty}>
          Save
        </Button>
      </form>
    </div>
  );
};
