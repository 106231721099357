import * as React from "react";
import ErrorBoundary from "../../components/ErrorBoundary";
import Loading from "../../components/Loading";
import ReadOnlyDocument from "../../DocumentEditor/ReadOnlyDocument";
import { DocumentHeader, DocumentFooter } from "./PreviewDocument";

const ViewFullDocument = ({
  documentHeaderContent,
  documentFooterContent,
  contentJSON,
}: {
  documentHeaderContent?: any;
  documentFooterContent?: any;
  contentJSON: string;
}) => (
  <div className="flex pb-8">
    <div className="flex-grow bg-canvas-700 p-8 shadow-sm border overflow-hidden h-full">
      {documentHeaderContent && (
        <DocumentHeader>{documentHeaderContent}</DocumentHeader>
      )}
      <div className="full-formatting">
        <React.Suspense fallback={<Loading />}>
          <ErrorBoundary>
            <ReadOnlyDocument doc={JSON.parse(contentJSON)} />
          </ErrorBoundary>
        </React.Suspense>
      </div>
      {documentFooterContent && (
        <DocumentFooter>{documentFooterContent}</DocumentFooter>
      )}
    </div>
  </div>
);

export default ViewFullDocument;
